import { Box, Chip, Typography } from '@material-ui/core'

import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const PortDetailComponent = (props) => {
  const { title, portCode, portName, date } = props

  const portDetailsTemplate = function () {
    return (
      <div className={`cng-summary-card--country-value`}>
        <Typography className={`field-label`}>{title}</Typography>
        <div className={`field-country-details`}>
          {portCode != null && (
            <img
              className={`field-country-flag`}
              src={`${
                process.env.PUBLIC_URL
              }/static/images/countries/${portCode.substring(0, 2)}.svg`}
            ></img>
          )}
          <label className={`field-country-name field-value dark`}>
            {portName}
          </label>
          <Chip
            label={<b style={{ fontSize: 10 }}>{portCode}</b>}
            size='small'
            sizeSmall
            style={{ marginLeft: 5 }}
            className={`field-country-code`}
          />
        </div>
        <Typography className={'field-value light mt-15'}>
          {Utils.formatDate(date, Utils.FORMAT_DATE_TIME, Utils.DASH)}
        </Typography>
      </div>
    )
  }

  const noPortDetailsTemplate = function () {
    return (
      <div className={'cng-summary-card--country-value'}>
        <Typography className={'field-label'}>{title}</Typography>
        <div className={'field-country-details'}>-</div>
        <Typography className={'field-value light mt-15'}>
          {Utils.formatDate(date, Utils.FORMAT_DATE_TIME, Utils.DASH)}
        </Typography>
      </div>
    )
  }

  if (portCode != null) {
    return portDetailsTemplate()
  } else {
    return noPortDetailsTemplate()
  }
}

export default PortDetailComponent
