import { Box, Card, Grid, Divider } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants } from 'cng-web-lib'

import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

import FooterAddComponent from '../../common/ui/FooterAddComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import DGCargoDetailsDialog from './DGCargoDetailsDialog'
import DGCargoDetailsTable from './DGCargoDetailsTable'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const { CngPackageUomAutocompleteField } = components.form.field

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  /*
        seqNo
    */

  const [currentDGCargoIdx, setCurrentDGCargoIdx] = useState()
  const [, ,] = useField('packageType')
  const { errors, touched, setFieldValue } = useFormikContext()
  const [dgCargoList, setDGCargoList] = useState([])
  // const [dgCargoField, , { setValue: setDGCargoField }] =
  //   useField('bookingDGCargoes')
  const { fields } = useFieldArray({name: "bookingDGCargoes"});
  const draftCargoId = useRef(-1)
  const translatedTextsObject = FreightBookingTranslationText()
  const [currentDGDetailsId, setCurrentDGDetailsId] = useState()
  const [isDGDetailsDialogOpen, setDGDetailsDialogOpen] = useState(false)
  const DG_DETAILS_MAX_COUNT = 9

  const cargoItem = {
    cargoId: -1,
    id: uuid(),
    cargoDesc: '',
    hsCode: '',
    packageType: ''
  }

  const getFieldError = (itemIndex, fieldPath) => {
    //fieldPath : containers.quantity
    if (errors) {
      let pathArr = fieldPath.split('.')

      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  const removeRowClick = (id) => (e) => {
    dgCargoList.splice(id, 1)
    const newlist = [].concat([], dgCargoList)
    setDGCargoList(newlist)
    e.stopPropagation()
  }

  const removeDGDetailsRow = (cargoIdx, detailsIdx) => (e) => {
    var newDGCargoList = [...dgCargoList]
    var newDGCargo = dgCargoList[cargoIdx]

    var newDGCargoDetails = newDGCargo.dgDetails
    newDGCargoDetails.splice(detailsIdx, 1)
    newDGCargo.dgDetails = newDGCargoDetails
    newDGCargoList[cargoIdx] = newDGCargo

    console.log(newDGCargoList)
    // setDGCargoField(newDGCargoList)
    setFieldValue('bookingDGCargoes', newDGCargoList)
    setDGCargoList(newDGCargoList)

    e.stopPropagation()
  }

  const copyDGDetailsRow = (cargoIdx, detailsIdx) => (e) => {
    if (dgCargoDetailReachMax(cargoIdx)) {
      alert('you cannot add dg cargo details any more')
    } else {
      var newDGCargoList = [...dgCargoList]
      var newDGCargo = dgCargoList[cargoIdx]

      var newDGCargoDetails = newDGCargo.dgDetails

      var copyDGCargoDetails = newDGCargoDetails.slice(
        detailsIdx,
        detailsIdx + 1
      )

      console.log(copyDGCargoDetails)
      //if a dg cargo details exist in db is copied, its unique dg cargo details id will be copied also, which is wrong
      //hence need to reset it to the copied dg cargo
      const copy = copyDGCargoDetails.map((object) => ({
        ...object,
        dgCargoDetailsId: null
      }))

      newDGCargoDetails = newDGCargoDetails.concat(copy)
      console.log(newDGCargoDetails)
      newDGCargo.dgDetails = newDGCargoDetails
      newDGCargoList[cargoIdx] = newDGCargo
      console.log(newDGCargoList)
      // setDGCargoField(newDGCargoList)
      setFieldValue('bookingDGCargoes',newDGCargoList)
      setDGCargoList(newDGCargoList)
    }

    e.stopPropagation()
  }

  const setDGCargoField =(newDGCargoList) =>{
    setFieldValue('bookingDGCargoes',newDGCargoList)
  }

  const handleDGDeailsRowClick = (dgCargoIdx, cargoDetailsIdx) => () => {
    console.log('handleCellClick')
    console.log(dgCargoList)
    console.log(dgCargoIdx)
    console.log(cargoDetailsIdx)
    setDGDetailsDialogOpen(true)
    setCurrentDGDetailsId(cargoDetailsIdx)
    setCurrentDGCargoIdx(dgCargoIdx)
  }

  const copyRowClick = (index) => () => {
    const list = [...dgCargoList]

    const copyCargo = list.slice(index, index + 1)[0]
    //need to copy dg cargo without reference
    //console.log(copyCargo)
    const copyCargoItem = {
      cargoId: draftCargoId.current,
      id: uuid(),
      cargoDesc: copyCargo.cargoDesc,
      hsCode: copyCargo.hsCode,
      packageType: copyCargo.packageType
    }
    if (copyCargo.dgDetails != null && copyCargo.dgDetails.length > 0) {
      var copyCargoDetails = copyCargo.dgDetails
      // console.log(copyCargoDetails)
      const copy = copyCargoDetails.map((object) => ({
        ...object,
        dgCargoDetailsId: null
      }))
      // console.log(copy)
      copyCargoItem.dgDetails = copy
    }

    // copyCargoItem.cargoId = draftCargoId.current
    draftCargoId.current = draftCargoId.current - 1

    setDGCargoList([...dgCargoList, copyCargoItem])

    // setDGCargoField([...dgCargoList, copyCargoItem])
    setFieldValue('bookingDGCargoes', [...dgCargoList, copyCargoItem])
  }

  const handleAddCargo = () => {
    setDGCargoList([...dgCargoList, cargoItem])
  }

  function dgCargoDetailReachMax(cargoIdx) {
    var dgDetailsSize =
      dgCargoList[cargoIdx].dgDetails == null
        ? 0
        : dgCargoList[cargoIdx].dgDetails.length
    console.log('dgDetailsSize' + dgDetailsSize)
    return dgDetailsSize >= DG_DETAILS_MAX_COUNT
  }

  const handleAddDGCargoDetails = (idx) => () => {
    console.log('handleAddDGCargoDetails ' + idx)

    //check the dg details of this cargo

    if (dgCargoDetailReachMax(idx)) {
      alert('you cannot add dg cargo details any more')
    } else {
      //open a popup window for dg details
      setCurrentDGCargoIdx(idx)
      setCurrentDGDetailsId(null)
      setDGDetailsDialogOpen(true)
    }
  }

  const handleInputChange = (e, index) => {
    console.log(e.target)
    const { name, value } = e.target
    const list = [...dgCargoList]
    list[index][name.split('.')[1]] = value

    setDGCargoList(list)
    // setDGCargoField(list)
    setFieldValue('bookingDGCargoes', list)
  }

  const handleDropdownChange = (val, index, name) => {
    const list = [...dgCargoList]

    list[index][name] = val

    setDGCargoList(list)
  }

  function closeDialog() {
    setDGDetailsDialogOpen(false)
  }

  useEffect(() => {
    const dgCargo = { ...cargoItem }
    dgCargo.cargoId = draftCargoId.current
    draftCargoId.current = draftCargoId.current - 1

    if (props.isEdit) {
      console.log(fields)

      // if cargoList is populated from review booking then set back to ContainerList to display
      // data population done in NewBookingFormProperties
      if (fields == null) {
        //when user switch from dg cargo, the cargo field could be empty
        createNewDGCargoList(dgCargo)
      } else {
        let cargoListWithExpanded = fields

        setDGCargoList(cargoListWithExpanded)
      }
    } else {
      // for create new booking
      createNewDGCargoList(dgCargo)
    }
  }, [props.isEdit, props.cargoKeyRef])

  const createNewDGCargoList = (dgCargo) => {
    setDGCargoList([dgCargo])
    // setDGCargoField([dgCargo])
    setFieldValue('bookingDGCargoes', [dgCargo])
  }

  return (
    <Card>
      <Box px={2} my={1.5}>
        <Grid container>
          <AccordionHeaderComponent title={translatedTextsObject.dgCargo} />
        </Grid>

        {dgCargoList.map((m, idx) => {
          return (
            <Grid container xs={12} sm={12} spacing={2} key={m.id}>
              <CngGridItem xs={12} sm={12}>
                <Card>
                  <Box>
                    <Grid container className='accordian-header'>
                      <CngGridItem xs={6} sm={6}>
                        <Box p={1} fontSize='large' fontWeight='bolder'>
                          {translatedTextsObject.cargo + ' ' + (idx + 1)}
                        </Box>
                      </CngGridItem>
                      <Grid container xs={6} sm={6} justify='flex-end'>
                        {/* <Box> */}
                        <IconButton aria-label='copy'>
                          <FileCopyIcon onClick={copyRowClick(idx)} />
                        </IconButton>
                        <IconButton
                          aria-label='remove'
                          onClick={removeRowClick(idx)}
                          style={{
                            display: dgCargoList.length > 1 ? 'inline' : 'none'
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {/* </Box> */}

                        <Divider orientation='vertical' flexItem />
                        <Box p={2} fontSize='small' fontWeight='bold'>
                          {translatedTextsObject.cargoId +
                            ': ' +
                            (m.cargoId < 0 ? 'N/A' : m.dgCargoId)}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Card className='inner-card-group'>
                    <Grid container xs={12} sm={12} spacing={2}>
                      <CngGridItem xs={4} sm={4}>
                        <CngTextField
                          type='text'
                          name={`bookingDGCargoes[${idx}].cargoDesc`}
                          label={translatedTextsObject.cargoDesc}
                          value={m.cargoDesc}
                          multiline
                          rows={4}
                          inputProps={{ maxLength: 512 }}
                          onChange={(e) => handleInputChange(e, idx)}
                          isRequired
                          // error={getFieldError(
                          //   idx,
                          //   'bookingDGCargoes.cargoDesc'
                          // )}
                          // helperText={getFieldError(
                          //   idx,
                          //   'bookingDGCargoes.cargoDesc'
                          // )}
                        />
                      </CngGridItem>
                      <CngGridItem xs={4} sm={4}>
                        <CngTextField
                          name={`bookingDGCargoes[${idx}].hsCode`}
                          label={translatedTextsObject.hsCode}
                          type='text'
                          value={m.hsCode}
                          inputProps={{ maxLength: 16 }}
                          onChange={(e) => handleInputChange(e, idx)}
                          // error={getFieldError(idx, 'bookingDGCargoes.hsCode')}
                          // helperText={getFieldError(
                          //   idx,
                          //   'bookingDGCargoes.hsCode'
                          // )}
                        />
                      </CngGridItem>
                      <CngGridItem xs={4} sm={4}>
                        <CngPackageUomAutocompleteField
                          name={`bookingDGCargoes[${idx}].packageType`}
                          label={translatedTextsObject.packageType}
                          onChange={(val) =>
                            handleDropdownChange(val, idx, 'packageType')
                          }
                        />
                      </CngGridItem>
                    </Grid>
                  </Card>

                  <DGCargoDetailsTable
                    cargoIdx={idx}
                    detailsList={m.dgDetails}
                    removeRowClick={removeDGDetailsRow}
                    copyRowClick={copyDGDetailsRow}
                    handleRowClick={handleDGDeailsRowClick}
                    editMode
                  />

                  <FooterAddComponent
                    handleAddChild={handleAddDGCargoDetails(idx)}
                    footerText={translatedTextsObject.addDGDetails}
                  />
                </Card>
              </CngGridItem>
            </Grid>
          )
        })}
      </Box>

      <FooterAddComponent
        handleAddChild={handleAddCargo}
        footerText={translatedTextsObject.addCargo}
      />

      <DGCargoDetailsDialog
        isDialogOpen={isDGDetailsDialogOpen}
        closeDialog={closeDialog}
        currentDGCargoIdx={currentDGCargoIdx}
        dgCargoField={dgCargoList}
        setDGCargoField={setDGCargoField}
        showNotification={props.showNotification}
        currentDGDetailsId={currentDGDetailsId}
        showLocalCategory={props.showLocalCategory}
      />
    </Card>
  )
}

const initialValues = {
  bookingDGCargoes: [
    {
      cargoId: '',
      id: uuid(),
      cargoDesc: '',
      hsCode: '',
      packageType: '',
      dgDetails: []
    }
  ]
}

const DGCargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default DGCargoComponent
