import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'

import BookingDetailBottom from './BookingDetailBottom'
import BookingDetailTop from './BookingDetailTop'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import HtmlTooltip from './HtmlTooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LabelValueVerticalSimple from './LabelValueVerticalSimple'
import LogoWithTitleVertical from './LogoWithTitleVertical'
import PortDetailComponent from './PortDetailComponent'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import helpdeskPathMap from 'src/paths/PathMap_Helpdesk'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const { booking } = props
  const history = useHistory()

  let carrierShortName = booking.carrierPartyIdName
  let isCarrierNameCut = false
  if (booking.carrierPartyIdName && booking.carrierPartyIdName.length > 5) {
    carrierShortName = booking.carrierPartyIdName.substring(0, 5).trim() + '...'
    isCarrierNameCut = true
  }
  let multipleCntr = false
  let contIsoType = ''
  if (booking.containerISOCounts != null) {
    contIsoType =
      booking.containerISOCounts[0].count +
      ' x ' +
      booking.containerISOCounts[0].contIsoType
    if (booking.containerISOCounts.length > 1) {
      contIsoType = 'Multiple CNTS'
      multipleCntr = true
    }
  }
  const gotoReviewBooking = () => {
    if (props.helpdesk) {
      history.push({
        pathname: helpdeskPathMap.FRB_DETAILS,
        state: {
          bookingId: booking.bookingId,
          dockey: booking.dockey,
          bookStatus: booking.bookStatus,
          bookStatusDesc: booking.bookStatusDesc,
          helpdesk: true
        }
      })
    } else if (props.isViewBk) {
      history.push({
        pathname: pathMap.VIEW_ONLY_BOOKING_REVIEW,
        state: {
          bookingId: booking.bookingId,
          dockey: booking.dockey,
          bookStatus: booking.bookStatus,
          bookStatusDesc: booking.bookStatusDesc
        }
      })
    } else {
      history.push({
        pathname: pathMap.MY_BOOKING_REVIEW_DRAFT,
        state: {
          bookingId: booking.bookingId,
          dockey: booking.dockey,
          bookStatus: booking.bookStatus,
          bookStatusDesc: booking.bookStatusDesc
        }
      })
    }
  }
  return (
    <Card className={`summary-list-item--card`}>
      <BookingDetailTop booking={booking} {...props} />
      <CardActionArea
        onClick={gotoReviewBooking}
        className={`cng-summary-card--body-box`}
      >
        <Grid container className={`cng-summary-card--body-grid`}>
            <CngGridItem xs={12} sm={1}>
              <LogoWithTitleVertical
                logoSrc={`${process.env.PUBLIC_URL}/static/images/carriers/${booking.carrierPartyIdNameOth}.svg`}
                defaultImg={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                withTooltip={isCarrierNameCut}
                longName={booking.carrierPartyIdName}
                shortName={carrierShortName}
              />

              {props.helpdesk && (
                <Box pt={0.5}>
                  <HtmlTooltip title={booking.partyIdName}>
                    <Box>
                      <Grid container justify='space-between'>
                        <Grid item xs={12} className={'frb-card-label'}>
                          {translatedTextsObject.shipper}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={'frb-card-content'}
                          style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line'
                          }}
                        >
                          <Box>{Utils.trimString(booking.partyIdName, 10)}</Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* <LabelValueVerticalSimple
                      label={translatedTextsObject.shipper}
                      value={Utils.trimString(booking.partyIdName, 10)}
                      defaultValue='N/A'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    /> */}
                  </HtmlTooltip>
                </Box>
              )}
            </CngGridItem>

            <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
              <Box pt={0.5}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.vesselName}
                  value={booking.vesselName}
                  defaultValue='N/A'
                  labelStyle={'frb-card-label'}
                  valueStyle={'frb-card-content'}
                />
              </Box>
              <Box pt={0.5}>
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                >
                  <CngGridItem sm={4}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.containersType}
                      value={contIsoType}
                      defaultValue='N/A'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>

                  {multipleCntr && (
                    <HtmlTooltip
                      title={
                        <Box>
                          CONTAINER DETAILS
                          <br />
                          {booking.containerISOCounts != null &&
                            booking.containerISOCounts.map((m, idx) => (
                              <Typography>
                                {m.count} x {m.contIsoType}
                              </Typography>
                            ))}
                        </Box>
                      }
                    >
                      <InfoIconButton>
                        <InfoOutlinedIcon fontSize='small' />
                      </InfoIconButton>
                    </HtmlTooltip>
                  )}
                </Grid>
              </Box>

              <Box pt={0.5}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.shipperRefNo}
                  value={booking.shpRefNo}
                  defaultValue='N/A'
                  labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content font-mediumBold'}
                />
              </Box>
            </CngGridItem>

            <CngGridItem xs={12} sm={6}>
              <Grid container>
                <CngGridItem xs={12} sm={12}>
                  <Grid container>
                    <CngGridItem xs={12} sm={6}>
                      <PortDetailComponent
                        title={translatedTextsObject.placeOfReceipt}
                        portCode={booking.placeOfReceiptCode}
                        portName={booking.placeOfReceipt}
                        date={booking.placeOfReceiptEtd}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <PortDetailComponent
                        title={translatedTextsObject.placeOfDelivery}
                        portCode={booking.placeOfDeliveryCode}
                        portName={booking.placeOfDelivery}
                        date={booking.placeOfDeliveryEta}
                      />
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                  <Grid container>
                    <CngGridItem xs={12} sm={6}>
                      <LabelValueVerticalSimple
                        label={translatedTextsObject.departingOn}
                        value={
                          booking.polEtd == null
                            ? '-'
                            : moment(booking.polEtd).format('DD MMM YYYY')
                        }
                        defaultValue='N/A'
                        labelStyle={'frb-card-label'}
                        valueStyle={'frb-card-content'}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6}>
                      <LabelValueVerticalSimple
                        label={translatedTextsObject.cutoff}
                        value={booking.cyCutoffTime}
                        defaultValue='N/A'
                        labelStyle={'frb-card-label'}
                        valueStyle={'frb-card-content'}
                      />
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Grid>
      </CardActionArea>
      <BookingDetailBottom
        dockey={booking.dockey}
        updatedDate={booking.updatedDate}
        hazardous={booking.hazardous}
      />
    </Card>
  )
}

const BookingDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default BookingDetailComponent
