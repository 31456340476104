import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Box
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import ComponentWrapper from './ComponentWrapper'
import Utils from 'src/views/common/utils/Utils'
// import useFetchCountryCodes from 'src/views/vesselschedule/searchschedule/useFetchCountryCodes'

const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const AuditTrailCargo = (props) => {
    const { cargos } = props
    const tranObj = FreightBookingTranslationText()
    const [scheduleExpanded, setScheduleExpanded] = useState(false)

    const handleScheduleExpand = () => {
        setScheduleExpanded(!scheduleExpanded);
    };
    //const containers = {"added":[{"baseFilterDTO":null,"errorMessages":null,"id":2576,"version":0,"createdBy":90,"contHeight":"HICU","contSize":"20","contType":"GP","rfHumidity":null,"rfMonFreq":null,"rfOffDuration":null,"rfTemperature":null,"rfVent":null,"shpOwned":true,"contQty":111,"seqNo":1,"partyId":59,"contStatus":"EMPTY","contIsoType":"25G0","bookingId":null,"containerAmendment":null,"amendStatus":null,"customizedContainerTypeKey":"GP-HICU-20"}],"removed":[{"baseFilterDTO":null,"errorMessages":null,"id":2577,"version":0,"createdBy":90,"contHeight":"HICU","contSize":"40","contType":"GP","rfHumidity":null,"rfMonFreq":null,"rfOffDuration":null,"rfTemperature":null,"rfVent":null,"shpOwned":false,"contQty":222,"seqNo":2,"partyId":59,"contStatus":"EMPTY","contIsoType":"45G0","bookingId":null,"containerAmendment":null,"amendStatus":null,"customizedContainerTypeKey":"GP-HICU-40"}],"updated":[{"baseFilterDTO":null,"errorMessages":null,"id":2579,"version":0,"createdBy":90,"contHeight":"HICU","contSize":"40","contType":"GP","rfHumidity":null,"rfMonFreq":null,"rfOffDuration":null,"rfTemperature":null,"rfVent":null,"shpOwned":false,"contQty":{old_value:222,new_value:123},"seqNo":2,"partyId":59,"contStatus":{old_value:"EMPTY",new_value:"FULL"},"contIsoType":"45G0","bookingId":null,"containerAmendment":null,"amendStatus":null,"customizedContainerTypeKey":"GP-HICU-40"}]}
    const numOfAdded = cargos && cargos.added ? cargos.added.length : 0
    const numOfRemoved = cargos && cargos.removed ? cargos.removed.length : 0


    const CargoFields = ({ cargo, index, ...props }) => {

        return (
            <Box width="100%">
                <ComponentWrapper header={tranObj.cargo + " " + (index + 1)}
                    cardStyle={{ marginTop: 20 }} backgroundStyle="inner-card-background"
                    headerStyle='inner-card-background inner-card-group boldLabel'
                    >
                    
                    <span style={{ fontSize: 14, marginLeft: 17 }}>
                        <b>ID: {cargo.id}</b>
                    </span>
                    
                    <AuditTrailDualColumn {...props} object={cargo} field={'cargoDesc'} label={tranObj.cargoDesc} />
                    <AuditTrailDualColumn {...props} object={cargo} field={'hscode'} label={tranObj.hsCode} />
                    <AuditTrailDualColumn {...props} object={cargo} field={'packageType'} label={tranObj.packageType} />
                    <AuditTrailDualColumn {...props} object={cargo} field={'quantity'} label={tranObj.quantity} />
                    <AuditTrailDualColumn {...props} object={cargo} field={'grossVolume'} label={tranObj.grossVolume} />
                    {/* <AuditTrailDualColumn {...props} object={cargo} field={'netVolume'} label={tranObj.netVolume} /> */}
                    <AuditTrailDualColumn {...props} object={cargo} field={'grossWeight'} label={tranObj.grossWeight} />
                    {/* <AuditTrailDualColumn {...props} object={cargo} field={'netWeight'} label={tranObj.netWeight} />
                    <AuditTrailDualColumn {...props} object={cargo} field={'vgmWeight'} label={tranObj.vgmWeight} /> */}
                </ComponentWrapper>
            </Box>
        )
    }
    return (
        <Card>
            <Accordion expanded={scheduleExpanded}>
                <AccordionSummary aria-controls="cargoComponentContent" id='cargoComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.cargo} hideMandatory />
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container>
                        {
                            cargos && cargos.added && cargos.added.length > 0 && cargos.added.map((c, i) => (
                                <Grid container xs={12} sm={12}>
                                    <CargoFields added cargo={c} index={i} />
                                </Grid>
                            ))
                        }
                        {
                            cargos && cargos.removed && cargos.removed.length > 0 && cargos.removed.map((c, i) => (
                                <Grid container xs={12} sm={12}>
                                    <CargoFields removed cargo={c} index={i + numOfAdded} />
                                </Grid>
                            ))
                        }
                        {
                            cargos && cargos.updated && cargos.updated.length > 0 && cargos.updated.map((c, i) => {
                                if (Utils._changeValueEmpty(c, ['bookingId'])) {
                                    return <div />
                                }
                                return (
                                    <Grid container xs={12} sm={12}>
                                        <CargoFields cargo={c} index={i + numOfAdded + numOfRemoved} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleScheduleExpand}
                expanded={scheduleExpanded}
                footerText={tranObj.showCargoDetails}
                footerTextExpanded={tranObj.hideCargoDetails}
                noOptionalFields
            />
        </Card>
    )
}

export default AuditTrailCargo