import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredCarrierDialogContext from 'src/views/vesselschedule/managepreferredcarrier/ManagePreferredCarrierDialogContext.js'
import ManagePreferredCarrierForm from 'src/views/vesselschedule/managepreferredcarrier/ManagePreferredCarrierForm.js'
import Paper from '@material-ui/core/Paper'
import DGCargoDetailsContext from './DGCargoDetailsContext.js'
import DGCargoDetailsForm from './DGCargoDetailsForm.js'
import { components } from 'cng-web-lib'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import DialogTitle from '@material-ui/core/DialogTitle'

const { CngDialog, CngDialogTitle } = components

function DGCargoDetailsDialog({
  isDialogOpen,
  closeDialog,
  currentDGCargoIdx,
  dgCargoField,
  setDGCargoField,
  showNotification,
  currentDGDetailsId,
  showLocalCategory
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()

  return (
    <DGCargoDetailsContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        currentDGCargoIdx,
        dgCargoField,
        setDGCargoField,
        currentDGDetailsId,
        showLocalCategory
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <DGCargoDetailsForm />
          </Paper>
        }
         dialogTitle={
          <>
            <b>{translatedTextsObject.hazardousDetails}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </DGCargoDetailsContext.Provider>
  )
}

export default DGCargoDetailsDialog
