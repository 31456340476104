import * as CalistaConstant from 'src/constants/CalistaConstant'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import ViewLastModifiedComponent from '../components/ViewLastModifiedComponent'

const ViewLastActionComponent = (props) => {
    const { booking, bookingStatus } = props
    const translatedTextsObject = FreightBookingTranslationText()


    if (bookingStatus == CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.submittedInfo}
            date={booking.submittedDate}
        />)
    } else if (bookingStatus == CalistaConstant.BOOKING_STATUS_APPROVED) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.approvedInfo}
            date={booking.updatedDate}
        />)
    } else if (bookingStatus == CalistaConstant.BOOKING_STATUS_REJECTED) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.rejectedInfo}
            date={booking.updatedDate}
        />)
    } else if (bookingStatus == CalistaConstant.BOOKING_STATUS_CANCELLED) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.cancelledInfo}
            date={booking.updatedDate}
        />)
    } else if (bookingStatus == CalistaConstant.BOOKING_STATUS_CLOSED) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.closedInfo}
            date={booking.updatedDate}
        />)
    } else if (bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED) {
        return (<ViewLastModifiedComponent
            text={translatedTextsObject.amendedInfo}
            date={booking.updatedDate}
        />)
    } else {
        return null
    }

}

export default ViewLastActionComponent
