import { Box, Button, Grid, Typography } from '@material-ui/core'

import React from 'react'
import SearchScheduleTranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSwitchField }
  },
  CngGridItem
} = components

const FilterFooterComponent = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <section
      className={
        'cng-filter-pane--section-container cng-filter-pane--section-footer'
      }
    >
      <footer className={`cng-filter-pane--footer`}>
        <Box className={`cng-filter-pane--footer-label-box`}>
          <Typography class={'cng-filter-pane--footer-label'}>
            {/* {translatedTextsObject.filters} */}
            {props.label}
          </Typography>
        </Box>
        <Box className={`cng-filter-pane--footer-cta-box`}>
          <CngSwitchField
            name={props.name}
            label=''
            onChange={props.onToggleSwitchChange}
          />
        </Box>
      </footer>
    </section>
  )
}

export default FilterFooterComponent
