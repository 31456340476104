import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography,
    Box,
    Chip
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngChipInputField,
            CngCheckboxField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({

    representativeName: "",
    representativeEmail: "",
    representativeContactNo: "",
    contractNo: "",
    commonRef: "",
    shipperRefNo: "",
    forwarderRefNo: "",
    preAssBkRefNo: "",
    contractPartyName: "",
    mainNotifyParty: "",
    shipperName: "",
    consigneeName: "",
    forwarderName: "",
    partnerEmails: [],
    notifyPartnerEmails: false,
    notifyEventMilestonesEmail: false
})

const FormBody = (props) => {
    const { isCarrierAmend, isShipperAmend } = props
    const translatedTextsObject = FreightBookingTranslationText()
    const [contactDetailsExpanded, setContactDetailsExpanded] = useState(false)

    const [representativeNameField, , { setValue: setRepresentativeNameField }]
        = useField('representativeName')
    const [, ,] = useField('representativeEmail')
    const [, ,] = useField('representativeContactNo')
    const [, ,] = useField('contractNo')
    const [, ,] = useField('commonRef')
    const [, ,] = useField('shipperRefNo')
    const [, ,] = useField('forwarderRefNo')
    const [, ,] = useField('preAssBkRefNo')
    const [, ,] = useField('contractPartyName')
    const [, ,] = useField('mainNotifyParty')
    const [shipperNameField, , { setValue: setShipperNameField }]
        = useField('shipperName')
    const [, ,] = useField('consigneeName')
    const [, ,] = useField('forwarderName')
    const [partnerEmailsField, , ] = useField('partnerEmails')

    const handleContactDetailsExpand = () => {
        setContactDetailsExpanded(!contactDetailsExpanded)
    }

    const repNameRef = useRef(uuid())

    useEffect(() => {
        repNameRef.current = uuid()
        // setRepresentativeNameField('Why like that')
        if (isCarrierAmend) {
            setContactDetailsExpanded(true)
        }
    }, [])

    return (
        <Card>
            <Accordion expanded={contactDetailsExpanded}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.contactDetails} hideMandatory />
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            {/* {representativeNameField.value} */}
                            {/* <Autocomplete
                                // name='representativeName'
                                autoSelect
                                freeSolo
                                disableClearable
                                options={options.map((option) => option.title)}
                                renderInput={(params) => {
                                    return(
                                    <CngTextField 
                                        {...params}
                                        type='text'
                                        name='representativeName'
                                        label={translatedTextsObject.representativeName}
                                        value={representativeNameField.value}

                                    />
                                    )}

                                    // <StyledTextField
                                    //     {...params}
                                    //     {...textFieldProps}
                                    //     inputProps={{
                                    //     ...params.inputProps,
                                    //     ...textFieldProps.inputProps
                                    //     }}
                                    //     InputProps={{
                                    //     ...params.InputProps,
                                    //     ...textFieldProps.InputProps(params.InputProps)
                                    //     }}
                                    // />
                                }
                                onChange={(evt, val) => updateRepName(evt, val)}
                            /> */}
                            {/* <CngLookupAutocompleteField
                                name='representativeName'
                                label={translatedTextsObject.representativeName}
                                // value={representativeNameField.value}
                                key={repNameRef.current}
                                lookupProps={{
                                    label: 'name',
                                    url: SearchScheduleApiUrls.CARRIER_ENROLL_GET,
                                    value: 'name'
                                }}
                                freeSolo
                                disableClearable
                                onBlur={(e) => {
                                    setRepresentativeNameField(e.target.value)
                                }}
                            /> */}

                            <CngTextField
                                type='text'
                                name='representativeName'
                                inputProps={{ maxLength: 100 }}
                                label={translatedTextsObject.representativeName}
                                disabled={isCarrierAmend}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                name='representativeEmail'
                                inputProps={{ maxLength: 100 }}
                                label={translatedTextsObject.representativeEmail}
                                disabled={isCarrierAmend}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                inputProps={{ maxLength: 100 }}
                                name='representativeContactNo'
                                label={translatedTextsObject.representativeContactNumber}
                                disabled={isCarrierAmend}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}>
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.references}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='contractNo'
                                            inputProps={{ maxLength: 30 }}
                                            label={translatedTextsObject.contractNumber}
                                            disabled={isShipperAmend} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            inputProps={{ maxLength: 35 }}
                                            name='commonRef'
                                            label={translatedTextsObject.purchaseOrderNumber}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='shipperRefNo'
                                            inputProps={{ maxLength: 35 }}
                                            label={translatedTextsObject.shipperRefNumber}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='forwarderRefNo'
                                            inputProps={{ maxLength: 35 }}
                                            label={translatedTextsObject.forwarderRefNumber}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='preAssBkRefNo'
                                            inputProps={{ maxLength: 30 }}
                                            label={translatedTextsObject.preAssignmentBookingNumber}
                                            disabled={isShipperAmend}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}>
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.parties}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='contractPartyName'
                                            inputProps={{ maxLength: 30 }}
                                            label={translatedTextsObject.contractPartyName}
                                            disabled={isShipperAmend} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='mainNotifyParty'
                                            inputProps={{ maxLength: 100 }}
                                            label={translatedTextsObject.mainNotifyParty}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        {/* <CngSelectField
                                            name='shipperName'
                                            label={translatedTextsObject.shipperName}
                                            value={shipperNameField.value}
                                            disabled={isCarrierAmend}
                                        // items=""
                                        /> */}
                                        <CngTextField
                                            type='text'
                                            name='shipperName'
                                            inputProps={{ maxLength: 100 }}
                                            label={translatedTextsObject.shipperName}
                                            disabled={isCarrierAmend || isShipperAmend}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='consigneeName'
                                            inputProps={{ maxLength: 100 }}
                                            label={translatedTextsObject.consigneeName}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='forwarderName'
                                            inputProps={{ maxLength: 100 }}
                                            label={translatedTextsObject.forwarderName}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </CngGridItem>
                        <CngGridItem xs={12} sm={12}>
                        <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.partnerEmailNtf}
                                        </Typography>
                                    </CngGridItem>
                                    {isCarrierAmend ?                                 
                                    <CngGridItem xs={8} sm={8}>   
                                        <Box className='view-card-content'>
                                            <Box className='view-card-content-label'>
                                                {translatedTextsObject.emailToNotify}
                                            </Box>
                                            <Box
                                            className='view-card-content-value'
                                            style={{ paddingRight: 0 }}
                                            >
                                                <div>
                                                { partnerEmailsField.value? partnerEmailsField.value.map((email) => (
                                                    <span className='pr-10'>
                                                        <Chip label={email} size='small' />
                                                    </span>
                                                ))
                                                :
                                                'N/A'}
                                                </div>
                                            </Box>
                                        </Box>  
                                    </CngGridItem>
                                    :
                                    <CngGridItem xs={8} sm={8}>
                                        <CngChipInputField
                                            name='partnerEmails'
                                            label={translatedTextsObject.emailToNotify}
                                            allowDuplicates={false}
                                            blurBehavior = 'ignore'        
                                        />
                                        <Box pl={1}>
                                            <Typography variant="caption" style={{color:"#8e98b0"}}>
                                                {translatedTextsObject.partnerEmailCaption}
                                            </Typography>
                                        </Box>
                                    </CngGridItem>
                                    }
                                    <CngGridItem xs={4} sm={4}/>
                                    <CngGridItem xs={8} sm={8}>
                                        <CngCheckboxField   
                                            name='notifyPartnerEmails'
                                            label={translatedTextsObject.notifyBookingStatus}
                                            disabled={isCarrierAmend}
                                        />

                                        <CngCheckboxField
                                            name='notifyEventMilestonesEmail'
                                            label={translatedTextsObject.notifyEventMilestones}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </CngGridItem>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleContactDetailsExpand}
                expanded={contactDetailsExpanded}
                footerText={translatedTextsObject.additionalContactDetails}
            />
        </Card>
    )
}

const ContactDetailsComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default ContactDetailsComponent