import React, {useState} from 'react'
import {Step, StepLabel, Stepper} from '@material-ui/core'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

function getStepsLabel() {
    const translatedTextsObject = FreightBookingTranslationText()
    return [
        translatedTextsObject.stepLabelCreateBooking, 
        translatedTextsObject.stepLabelReviewBooking, 
        translatedTextsObject.stepLabelBookingSubmitted
    ];
  }

function NewBookingStepperComponent(props){

    // const [activeStep, setActiveStep] = useState(0);
    const steps = getStepsLabel();

    return (
        <Stepper activeStep={props.activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )

}

export default NewBookingStepperComponent