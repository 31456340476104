import BookingSyncLogApiUrls from 'src/apiUrls/BookingSyncLogApiUrls'
import BookingSyncLogFormProperties from './BookingSyncLogFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngViewForm }
} = components

function BookingSyncLogViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     },
    items
}) {
    return (
        <CngViewForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <BookingSyncLogFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                    items={items}
                />
            }
            formikProps={BookingSyncLogFormProperties.formikProps}
            toClientDataFormat={BookingSyncLogFormProperties.toClientDataFormat}
            toServerDataFormat={BookingSyncLogFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: BookingSyncLogApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default BookingSyncLogViewForm
