import { Grid, Typography } from '@material-ui/core'

import Hazardous from 'src/components/button/Hazardous.js'
import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import Utils from 'src/views/common/utils/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

const BookingDetailBottom = (props) => {
  const { dockey, updatedDate, hazardous } = props
  const translatedTextsObject = FreightBookingTranslationText()

  return (
    <footer className={`cng-summary-card--footer-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--footer-box--left`}>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
              ></img>
            </span>

            <span>
              <label data-label-suffix=':' className={`form-label-colon`}>
                {translatedTextsObject.bookingId}
              </label>
              <label>{dockey}</label>
            </span>

            <span>{hazardous && <Hazardous skipText />}</span>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--footer-box--right`}>
            <div className={`footer-box--right-col-1 text-right-align`}>
              <Typography className={'frb-card-label-large-grey'}>
                {translatedTextsObject.lastModifiedOn + ' '}
                <b>
                  {Utils.formatDate(
                    updatedDate,
                    Utils.FORMAT_DATE_TIME,
                    Utils.DASH
                  )}
                </b>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

export default BookingDetailBottom
