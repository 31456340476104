import { components } from 'cng-web-lib'
import React, { useContext } from 'react'

import { Box, Card, Grid } from '@material-ui/core'
import ReeferDetailsContext from './ReeferDetailsContext.js'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import LabelValueVertical from './LabelValueVertical.js'
import ComponentWrapper from './ComponentWrapper.js'

const {
  CngGridItem,
  form: { CngViewForm },
  button: { CngPrimaryButton }
} = components

function ViewReeferDetailsForm(props) {
  const dialogContext = useContext(ReeferDetailsContext)

  const {
    closeDialog,
    form: { isSubmitting },
    currentReeferDetails,
    currentAudit
  } = dialogContext

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
        },
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentReeferDetails={currentReeferDetails}
          currentAudit={currentAudit}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  currentReeferDetails
}) {
  const translatedTextsObject = FreightBookingTranslationText()
  const calistaUITranslatedTextObject = CalistaUiComponentTranslationText()

  return (
    <Box m={1.5}>
      <Box>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <Card>
              <Box className="view-card-header">
                {translatedTextsObject.reeferDetails}
              </Box>
              <Box>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.equipmentTemp}
                      value={currentReeferDetails.equipmentTemp} />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.tempUom}
                      value={currentReeferDetails.tempUom} />
                  </CngGridItem>
                </Grid>

                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereCO2}
                      value={currentReeferDetails.equipmentControlledAtmosphereCO2} />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.humidty}
                      value={currentReeferDetails.equipmentHumidity} />
                  </CngGridItem>
                </Grid>

                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereN2}
                      value={currentReeferDetails.equipmentControlledAtmosphereN2} />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    {currentReeferDetails.equipmentAirFlow ?
                    <LabelValueVertical label={translatedTextsObject.equipmentAirFlow}
                      value={` ${currentReeferDetails.equipmentAirFlow} ${translatedTextsObject.cbm}`} />
                      :
                      <LabelValueVertical label={translatedTextsObject.equipmentAirFlow}
                      value={currentReeferDetails.equipmentAirFlow} />
                    }
                  </CngGridItem>
                </Grid>

                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereO2}
                      value={currentReeferDetails.equipmentControlledAtmosphereO2} />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                </Grid>

                <ComponentWrapper header={translatedTextsObject.specialHandling} headerStyle="view-card-header-no-bg"
                  cardStyle={{ margin: 10 }} backgroundStyle="inner-card-background">
                  <Grid container xs={12} sm={12} >
                    <Grid item xs={4} sm={4}>
                      <Box
                        className='view-card-content'
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.superFreezerService}
                        </Box>
                        <Box className='view-card-content-value'>
                          {currentReeferDetails.superFreezerService ?
                            (
                              'Yes'
                            )
                            :
                            (
                              'No'
                            )
                          }
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Box
                        className='view-card-content'
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.gensetRequired}
                        </Box>
                        <Box className='view-card-content-value'>
                          {currentReeferDetails.gensetRequired ?
                            (
                              'Yes'
                            )
                            :
                            (
                              'No'
                            )
                          }
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Box
                        className='view-card-content'
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.inTransitColdSterilization}
                        </Box>
                        <Box className='view-card-content-value'>
                          {currentReeferDetails.inTransitColdSterilization ?
                            (
                              'Yes'
                            )
                            :
                            (
                              'No'
                            )
                          }
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12}>
                    <Grid item xs={4} sm={4}>
                    <LabelValueVertical label={translatedTextsObject.ventSetting}
                        value={currentReeferDetails.ventSetting} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <LabelValueVertical label={translatedTextsObject.tempVariance}
                        value={currentReeferDetails.tempVariance} />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={12} spacing={0}>
                    <Grid item xs={4} sm={4}>
                      <LabelValueVertical label={translatedTextsObject.numberOfTempProbes}
                        value={currentReeferDetails.numberOfTempProbes} />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <LabelValueVertical label={translatedTextsObject.numberOfUSDProbes}
                        value={currentReeferDetails.numberOfUSDProbes} />
                    </Grid>
                  </Grid>

                  <Grid container xs={12} sm={12} spacing={0}>
                    <LabelValueVertical label={translatedTextsObject.equipmentComments}
                      value={currentReeferDetails.equipmentComments} />
                  </Grid>
                </ComponentWrapper>
              </Box>
            </Card>
          </CngGridItem>
        </Grid>
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <Box mr={1}>
          <CngPrimaryButton onClick={closeDialog}>
            {calistaUITranslatedTextObject.close}
          </CngPrimaryButton>
        </Box>
      </Box>
    </Box>

  )
}

export default ViewReeferDetailsForm
