import { Avatar, Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { faAnchor } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HtmlTooltip from './HtmlTooltip'

const AvatarInitial = (props) => {
  const { withTooltip, longName, shortName } = props
  const [initial, setInitial] = useState('')
  let ini = ''
  let i = 0
  let char = ''

  const getFirst2Initial = () => {
    // alert(longName)
    if (longName != null && i <= longName.length) {
      char = longName.charAt(i)
      if (isNaN(char * 1)) {
        if (char == char.toUpperCase()) {
          ini = ini + char
        }
      }

      if (ini.length == 2) {
        console.log('initial : ' + ini)
        setInitial(ini)
        return
      } else {
        i++
      }
    }
  }

  useEffect(() => {
    getFirst2Initial()
  }, [])

  return (
    <Grid
      container
      xs={12}
      sm={12}
      direction='column'
      justify='space-between'
      alignItems='center'
    >
      <Box pt={0}>
        {/* <Avatar className='avatar'>{initial}</Avatar> */}
        <Avatar
          className='avatar'
          classes={{
            root: 'cng-summary-card--avatar circle',
            colorDefault: 'default-bg',
            img: 'img-src'
          }}
          variant='rounded'
        >
          <FontAwesomeIcon icon={faAnchor} />
        </Avatar>
      </Box>

      <HtmlTooltip title={withTooltip ? <Box>{longName}</Box> : ''}>
        <Typography>{shortName}</Typography>
      </HtmlTooltip>
    </Grid>
  )
}

export default AvatarInitial
