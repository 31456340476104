import {
    Box,
    Card,
    Grid,
    Chip
} from '@material-ui/core'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React, { useState, useEffect } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import LabelValueVertical from './LabelValueVertical'
import ComponentWrapper from './ComponentWrapper'
import DGCargoDetailsTable from './DGCargoDetailsTable'
import ViewDGCargoDetailsDialog from './ViewDGCargoDetailsDialog'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'

const {
    CngGridItem
} = components

const {
    CodeMaintenanceType
} = constants

const FormBody = (props) => {

    const { fetchRecords } = useServices()
    const dgCargoList = props.cargo;
    const { showDetails } = props
    console.log(dgCargoList)
    console.log(showDetails)
    const [
        isDGDetailsDialogOpen,
        setDGDetailsDialogOpen
    ] = useState(false)
    const [currentDGDetails, setCurrentDGDetails] = useState()
    const [currentDGCargoIdx, setCurrentDGCargoIdx] = useState()
    const [currentDGDetailsId, setCurrentDGDetailsId] = useState()

    const [uomItems, setUomItems] = useState([])
    const [imoItems, setImoItems] = useState([])
    const [packageUomItems, setPackageUomItems] = useState([])

    const translatedTextsObject = FreightBookingTranslationText();

    const handleDGDeailsViewClick = (dgCargoIdx, cargoDetailsIdx) => () => {
        console.log("handleDGDeailsViewClick")
        console.log(dgCargoList)
        console.log(dgCargoIdx)
        console.log(cargoDetailsIdx)

        console.log(dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx])
        setDGDetailsDialogOpen(true)
        setCurrentDGDetails(dgCargoList[dgCargoIdx].dgDetails[cargoDetailsIdx])
        setCurrentDGCargoIdx(dgCargoIdx)
        setCurrentDGDetailsId(cargoDetailsIdx)
    }

    function closeDialog() {

        setDGDetailsDialogOpen(false)

    }

    useEffect(() => {
        CodeMasterApi.fetchCodeMaster(fetchRecords, 'CAL_DG_IMO', false, onLoadIMOCodeSuccess)

        CodeMasterApi.fetchCodeMaster(fetchRecords, 'CAL_DG_TEMP_UOM', false, onLoadDGTempUOMCodeSuccess)

        CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PACKAGE_UOM, [], false, onLoadPackageUomSuccess)
    }, [props])

    // useFetchCodeMaster(props.codeType, false, onLoadCodeMasterSuccess)

    function onLoadPackageUomSuccess(data) {

        console.log("onLoadPackageUomSuccess")
        console.log(data)

        if (data.length > 0) {
            setPackageUomItems(data)
        }

    }

    function onLoadIMOCodeSuccess(data) {

        console.log("onLoadCodeMasterSuccessxdd")
        console.log(data)

        if (data.length == 0) {
            console.log("null")
        } else {


            setImoItems(data)
        }


    }

    function onLoadDGTempUOMCodeSuccess(data) {
        console.log("onLoadCodeMasterSuccess")
        console.log(data)
        let optionList = []

        if (data.length == 0) {
            console.log("null")
        } else {

            console.log(optionList)
            setUomItems(data)

            console.log(uomItems)
        }
    }

    const ViewDgCargoDetailComponent = () => {
        return (
            dgCargoList.map((m, idx) => (

                <Grid xs={12} sm={12} key={idx} spacing={2} className="view-card view-card-content">
                    <Card >
                        <Box >
                            <Grid container className='accordian-header'>

                                <CngGridItem xs={6} sm={6}>
                                    <Box fontSize='large' fontWeight='bolder'>
                                        {translatedTextsObject.dgCargo + ' ' + (idx + 1)}
                                    </Box>
                                </CngGridItem>
                                {showDetails && (
                                    <CngGridItem xs={6} sm={6} className={'alignRight'}>
                                        <Box p={1} fontSize='small'>
                                            {<React.Fragment> {translatedTextsObject.cargoId}:&nbsp;<b>{m.dgCargoId == null ? "N/A" : m.dgCargoId}</b></React.Fragment>}
                                        </Box>
                                    </CngGridItem>
                                )}
                            </Grid>
                            {
                                !showDetails && (<div>

                                    <Grid container xs={12} sm={12} className="view-card-item-content">
                                        <Grid item xs={4} sm={4}>
                                            <LabelValueVertical label={translatedTextsObject.cargoDesc}
                                                value={m.cargoDesc} />
                                        </Grid>
                                    </Grid>
                                </div>)}
                        </Box>
                        {
                            showDetails && (<div>

                                <Grid container xs={12} sm={12} className="view-card-item-content">
                                    <Grid item xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.cargoDesc}
                                            value={m.cargoDesc} />
                                    </Grid>
                                    <Grid item xs={8} sm={8} style={{ paddingRight: 20, marginTop: 16 }}>
                                        <ComponentWrapper>
                                            <Grid container xs={12} sm={12}>
                                                <Grid item xs={6} sm={6}>
                                                    <LabelValueVertical label={translatedTextsObject.hsCode}
                                                        value={m.hsCode} />
                                                </Grid>
                                                <Grid item xs={6} sm={6} alignContent="center">
                                                    {/* <LabelValueVertical label={translatedTextsObject.packageType}
                                                        value={m.packageType} /> */}


                                                    <Grid item xs={12} sm={12} >

                                                        <Box className="view-card-content">
                                                            <Grid container justify="space-between">
                                                                <Grid item xs={12} sm={12} >
                                                                    <Box className="view-card-content-label">
                                                                        {translatedTextsObject.packageType}
                                                                    </Box>
                                                                </Grid>
                                                                <Box className="view-card-content-value">
                                                                    <CodeMasterMiscViewField
                                                                        codeType={CodeMaintenanceType.PACKAGE_UOM}
                                                                        code={m.packageType}
                                                                    />
                                                                </Box>

                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </ComponentWrapper>

                                    </Grid>
                                </Grid>

                                <DGCargoDetailsTable
                                    cargoIdx={idx}
                                    detailsList={m.dgDetails}
                                    handleRowClick={handleDGDeailsViewClick}
                                    imoItems={imoItems}

                                />

                            </div>)}
                    </Card>
                </Grid>
            ))

        )
    };


    return (
        <Card>
            <Box className="view-card-header">
                <div> {translatedTextsObject.dgCargo}</div>
            </Box>
            <ViewDgCargoDetailComponent {...props} />

            <ViewDGCargoDetailsDialog
                isDialogOpen={isDGDetailsDialogOpen}
                closeDialog={closeDialog}
                currentDGDetails={currentDGDetails}
                currentDGCargoIdx={currentDGCargoIdx}
                currentDGDetailsId={currentDGDetailsId}
                uomItems={uomItems}
                packageUomItems={packageUomItems}
            />
        </Card>
    )
}

const ViewDgCargoComponent = Object.freeze({
    FormBody: FormBody,
    // initialValues : initialValues
})

export default ViewDgCargoComponent
