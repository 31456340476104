import { Box, Card, Grid, Chip, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import { components } from 'cng-web-lib'
import ComponentWrapper from './ComponentWrapper'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngCheckboxField
        }
    },
    CngGridItem
} = components

const FormBody = (props) => {
    const { data } = props
    const translatedTextsObject = FreightBookingTranslationText()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {
        setShouldRender(true)
    }, [])

    if (!shouldRender) {
        return null;
    }

    return (
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className="view-card-header">
                        {translatedTextsObject.reeferDetails + ' to apply to all Reefers and Tanks'}
                    </Box>
                    {!data.reefer.nonActiveReefer ?
                        (
                            <Box>
                                <Grid container xs={12} sm={12} spacing={0}>
                                    <CngGridItem xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.nonActiveReefer}
                                            value='Yes' />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.equipmentTemp}
                                            value={data.reefer.equipmentTemp} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.tempUom}
                                            value={data.reefer.tempUom} />
                                    </CngGridItem>
                                </Grid>

                                <ComponentWrapper header={translatedTextsObject.specialHandling} headerStyle="view-card-header-no-bg"
                                    cardStyle={{ margin: 10 }} backgroundStyle="inner-card-background">
                                    <Grid container xs={12} sm={12} >
                                        <Grid item xs={4} sm={4}>
                                            <Box
                                                className='view-card-content'
                                            // style={Utils.auditHighLight(auditBooking, ['polEta'])}
                                            >
                                                <Box className='view-card-content-label'>
                                                    {translatedTextsObject.superFreezerService}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.superFreezerService ?
                                                        (
                                                            'Yes'
                                                        )
                                                        :
                                                        (
                                                            'No'
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <Box
                                                className='view-card-content'
                                            // style={Utils.auditHighLight(auditBooking, ['polEta'])}
                                            >
                                                <Box className='view-card-content-label'>
                                                    {translatedTextsObject.gensetRequired}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.gensetRequired ?
                                                        (
                                                            'Yes'
                                                        )
                                                        :
                                                        (
                                                            'No'
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <Box
                                                className='view-card-content'
                                            // style={Utils.auditHighLight(auditBooking, ['polEta'])}
                                            >
                                                <Box className='view-card-content-label'>
                                                    {translatedTextsObject.inTransitColdSterilization}
                                                </Box>
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.inTransitColdSterilization ?
                                                        (
                                                            'Yes'
                                                        )
                                                        :
                                                        (
                                                            'No'
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12}>
                                        <Grid item xs={4} sm={4}>
                                            <ComponentWrapper header={translatedTextsObject.equipmentControlledAtmosphere} headerStyle="view-card-header-no-bg"
                                                cardStyle={{ margin: 10 }} backgroundStyle="inner-card-background">
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.equipmentControlledAtmosphere ?
                                                        (
                                                            'Yes'
                                                        )
                                                        :
                                                        (
                                                            'No'
                                                        )
                                                    }
                                                </Box>
                                                <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereCO2}
                                                    value={data.reefer.equipmentControlledAtmosphereCO2} />
                                                <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereN2}
                                                    value={data.reefer.equipmentControlledAtmosphereN2} />
                                                <LabelValueVertical label={translatedTextsObject.equipmentControlledAtmosphereO2}
                                                    value={data.reefer.equipmentControlledAtmosphereO2} />
                                            </ComponentWrapper>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <ComponentWrapper header={translatedTextsObject.ventSetting} headerStyle="view-card-header-no-bg"
                                                cardStyle={{ margin: 10 }} backgroundStyle="inner-card-background">
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.ventSetting ?
                                                        (
                                                            'Open'
                                                        )
                                                        :
                                                        (
                                                            'Closed'
                                                        )
                                                    }
                                                </Box>
                                                <Box className='view-card-content'>
                                                    {data.reefer.ventSetting ?
                                                        (
                                                            <Tooltip
                                                                title={`${translatedTextsObject.equipmentAirFlow}  - ${data.reefer.equipmentAirFlow} ${translatedTextsObject.cbm}`}
                                                            >
                                                                <Chip
                                                                    label={`${translatedTextsObject.equipmentAirFlow}  - ${data.reefer.equipmentAirFlow} ${translatedTextsObject.cbm}`}
                                                                    style={{
                                                                        margin: 'auto 5px',
                                                                        padding: '0',
                                                                        fontWeight: 'bold',
                                                                        background: '#8996af14',
                                                                        border: '1px solid #EEEEEE99',
                                                                        borderRadius: '17px',
                                                                        alignItems: 'left',
                                                                        justifyContent: 'left'
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )
                                                        :
                                                        ('')
                                                    }
                                                </Box>
                                            </ComponentWrapper>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <ComponentWrapper header={translatedTextsObject.humidty} headerStyle="view-card-header-no-bg"
                                                cardStyle={{ margin: 10 }} backgroundStyle="inner-card-background" >
                                                <Box className='view-card-content-value'>
                                                    {data.reefer.humidty ?
                                                        (
                                                            'Yes'
                                                        )
                                                        :
                                                        (
                                                            'No'
                                                        )
                                                    }
                                                </Box>

                                                <Box className='view-card-content'>
                                                    {data.reefer.humidty ?
                                                        (
                                                            <Tooltip
                                                                title={`${translatedTextsObject.equipmentHumidity}  - ${data.reefer.equipmentHumidity} ${translatedTextsObject.percentofMoistureInAir}`}
                                                            >
                                                                <Chip
                                                                    label={`${translatedTextsObject.equipmentHumidity}  - ${data.reefer.equipmentHumidity} ${translatedTextsObject.percentofMoistureInAir}`}
                                                                    style={{
                                                                        margin: 'auto 5px',
                                                                        padding: '0',
                                                                        fontWeight: 'bold',
                                                                        background: '#8996af14',
                                                                        border: '1px solid #EEEEEE99',
                                                                        borderRadius: '17px'
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )
                                                        :
                                                        ('')
                                                    }
                                                </Box>
                                            </ComponentWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} spacing={0}>
                                        <Grid item xs={4} sm={4}>
                                            <LabelValueVertical label={translatedTextsObject.numberOfTempProbes}
                                                value={data.reefer.numberOfTempProbes} />
                                        </Grid>

                                        <Grid item xs={4} sm={4}>
                                            <LabelValueVertical label={translatedTextsObject.numberOfUSDProbes}
                                                value={data.reefer.numberOfUSDProbes} />
                                        </Grid>

                                        <Grid item xs={4} sm={4}>
                                            <LabelValueVertical label={translatedTextsObject.tempVariance}
                                                value={data.reefer.tempVariance} />
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12} sm={12} spacing={0}>
                                        <LabelValueVertical label={translatedTextsObject.equipmentComments}
                                            value={data.reefer.equipmentComments} />
                                    </Grid>
                                </ComponentWrapper>
                            </Box>
                        )
                        :
                        (
                            <Box>
                                <Grid container xs={12} sm={12} spacing={0}>
                                    <CngGridItem xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.nonActiveReefer}
                                            value='No' />
                                    </CngGridItem>
                                </Grid>
                            </Box>
                        )

                    }
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({

})

const ViewReeferComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewReeferComponent