import { Box, Grid, Chip, Card } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'

import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import { useServices } from 'cng-web-lib'

const DGCargoDetailsPkgUomViewComponent = (props) => {
  const { label, value, uom } = props
  const { fetchRecords } = useServices()
  const [codeMaster, setCodeMaster] = useState([])

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_DG_PKG_TYPE',
      false,
      onLoadCodeMasterSuccess
    )
  }, [props])

  // useFetchCodeMaster(props.codeType, false, onLoadCodeMasterSuccess)

  function onLoadCodeMasterSuccess(data) {
    let matched = data.find((m) => {
      return m.code == props.uom
    })

    setCodeMaster(matched)
  }

  return (
    <Card className={props.backgroundClass}>
      <Box className='view-card-content'>
        <Box className='view-card-content-label'>
          <Grid container justify='space-between'>
            <Grid item>{label}</Grid>
          </Grid>
        </Box>
        {/* <Box className="view-card-content-value" alignItems="flex-end">
                {value ? value : '-'} X <React.Fragment><Chip label={<b>{uom}</b>} size='small' /></React.Fragment>{codeMaster?.descriptionEn}

            </Box> */}
        <Box className='view-card-content-value' alignItems='flex-end'>
          <Grid container alignItems='flex-end' spacing={1}>
            <Grid item>{value ? value : '-'}X</Grid>
            <Grid item>
              <Chip
                label={<b>{uom}</b>}
                size='small'
                color='primary'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Box ml={0.5} alignSelf='flex-end'>
                {codeMaster?.descriptionEn}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}
export default DGCargoDetailsPkgUomViewComponent
