import FormPropertiesResult from 'src/views/vesselschedule/searchschedule/FormPropertiesResult'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ResultPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data) {
    data.arrivalDate = moment(moment(data.departDate).format('DD-MMM-YYYY'))
      .add(data.weeks, 'weeks')
      .format('YYYY-MM-DD')

    let submittedData = { ...data }
    submittedData.departDate = moment(
      moment(submittedData.departDate).format('DD-MMM-YYYY')
    ).format('YYYY-MM-DD')

    console.log('submit', submittedData)

    // Ideally to refresh table list component or refresh page without reload
    history.push('/')
    history.replace({
      pathname: pathMap.BOOK_FROM_SCHEDULE_RESULT,
      state: submittedData
    })
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
              <FormPropertiesResult.Fields
                disabled={false}
                showNotification={showNotification}
              />
            }
            formikProps={{
              ...FormPropertiesResult.formikProps,
              onSubmit: onSubmit
            }}
            toClientDataFormat={FormPropertiesResult.toClientDataFormat}
            toServerDataFormat={FormPropertiesResult.toServerDataFormat}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ResultPage
