import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import CommonUtils from 'src/views/common/utils/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { components } from 'cng-web-lib'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateTimeField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  carrierPartyId: '',
  polCode: '',
  podCode: '',
  polCountryCode: '',
  podCountryCode: '',
  polEta: moment().format(CommonUtils.UI_FORMAT_DATE_TIME),
  podEta: null,
  polEtd: null,
  podEtd: null,
  vesselName: '',
  intVoyageNo: '',
  voyageNo: '',
  portOfLoadingTerminal: '',
  portOfDischargeTerminal: ''
})

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const { setFieldValue } = useFormikContext()
  const [, ,] = useField('voyageId')
  const [, ,] = useField('carrierPartyId')
  const [polCodeField, ,] = useField('polCode')
  const [podCodeField, ,] = useField('podCode')
  const [, ,] = useField('polCountryCode')
  const [, ,] = useField('podCountryCode')
  const [polEtaField, ,] = useField('polEta')
  const [podEtaField, ,] = useField('podEta')
  const [polEtdField, ,] = useField('polEtd')
  const [, ,] = useField('podEtd')
  const [, ,] = useField('vesselName')
  const [, ,] = useField('intVoyageNo')
  const [, ,] = useField('voyageNo')
  const [, ,] = useField('portOfLoadingTerminal')
  const [, ,] = useField('portOfDischargeTerminal')
  const [carrierKey, setCarrierKey] = useState()
  const SG_DATE_TIME_FORMAT = CommonUtils.UI_FORMAT_DATE_TIME
  const SG_DATE_FORMAT = CommonUtils.UI_FORMAT_DATE

  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  const onChangePolAutoComplete = (value, option) => {
    if (value != null) {
      props.setPlaceOfReceiptCodeField(value)
      props.setPlaceOfReceiptField(option.label)
      props.placeOfReceiptKeyRef.current = uuid()
    }
    setDGLocalCategoryFromPort(value)
  }
  const onChangePodAutoComplete = (value, option) => {
    if (value != null) {
      props.setPlaceOfDeliveryCodeField(value)
      props.setPlaceOfDeliveryField(option.label)
      props.placeOfDeliveryKeyRef.current = uuid()
    }
    setDGLocalCategoryFromPort(value)
  }

  const setDGLocalCategoryFromPort = (value) => {
    props.setShowLocalCategory(value === 'SGSIN')
  }

  const onChangePodEta = (date) => {
    props.PodEtaDateChange(date, 'podEta')
    setFieldValue('placeOfDeliveryEta', date, true)
  }

  const onChangePolEtd = (date) => {
    props.PolEtdDateChange(date, 'polEtd')
    setFieldValue('placeOfReceiptEtd', date, true)
  }

  useEffect(() => {
    setCarrierKey(uuid())
  }, [props.isEdit])

  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='scheduleComponentContent'
          id='scheduleComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent title={translatedTextsObject.schedule} />
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <CarrierAutocompleteField
                  name='carrierPartyId'
                  key={carrierKey}
                  label={translatedTextsObject.carrierNvoccBookingAgent}
                  disabled={props.bookFromSchedule}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='polCode'
                  label={translatedTextsObject.portOfLoading}
                  keyRef={props.polKeyRef}
                  value={polCodeField.value}
                  required
                  onChangeAutoComplete={onChangePolAutoComplete}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <PortSelectAutoComplete
                  name='podCode'
                  label={translatedTextsObject.portOfDischarge}
                  required
                  keyRef={props.podKeyRef}
                  value={podCodeField.value}
                  onChangeAutoComplete={onChangePodAutoComplete}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={translatedTextsObject.polPlannedEta}
                  name='polEta'
                  isRequired
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(moment().format(SG_DATE_FORMAT)).isAfter(
                      moment(date)
                    )
                  }}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={translatedTextsObject.podPlannedEta}
                  name='podEta'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(
                      moment(polEtdField.value).format(SG_DATE_FORMAT)
                    ).isAfter(moment(date))
                  }}
                  disabled={props.bookFromSchedule}
                  additionalOnChange={(event, date) => onChangePodEta(date)}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={translatedTextsObject.polPlannedEtd}
                  name='polEtd'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(
                      moment(polEtaField.value).format(SG_DATE_FORMAT)
                    ).isAfter(moment(date))
                  }}
                  disabled={props.bookFromSchedule}
                  additionalOnChange={(event, date) => onChangePolEtd(date)}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngDateTimeField
                  label={translatedTextsObject.podPlannedEtd}
                  name='podEtd'
                  format={SG_DATE_TIME_FORMAT}
                  shouldDisableDate={(date) => {
                    return moment(
                      moment(podEtaField.value).format(SG_DATE_FORMAT)
                    ).isAfter(moment(date))
                  }}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 100 }}
                  label={translatedTextsObject.loadTerminal}
                  name='portOfLoadingTerminal'
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 100 }}
                  label={translatedTextsObject.dischargeTerminal}
                  name='portOfDischargeTerminal'
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='vesselName'
                  label={translatedTextsObject.vesselName}
                  inputProps={{ maxLength: 35 }}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='intVoyageNo'
                  label={translatedTextsObject.internationalVoyageNumber}
                  inputProps={{ maxLength: 20 }}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='voyageNo'
                  label={translatedTextsObject.voyageNumber}
                  inputProps={{ maxLength: 17 }}
                  disabled={props.bookFromSchedule}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={translatedTextsObject.additionalScheduleDetails}
      />
    </Card>
  )
}

const ScheduleComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ScheduleComponent
