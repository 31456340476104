import FormProperties from 'src/views/vesselschedule/searchschedule/FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const history = useHistory()

  function onSubmit(data) {
    data.arrivalDate = moment(moment(data.departDate).format('DD-MMM-YYYY'))
      .add(data.weeks, 'weeks')
      .format('YYYY-MM-DD')

    let submittedData = { ...data }
    submittedData.departDate = moment(
      moment(submittedData.departDate).format('DD-MMM-YYYY')
    ).format('YYYY-MM-DD')

    console.log('submit', submittedData)

    history.push({
      pathname: pathMap.BOOK_FROM_SCHEDULE_RESULT,
      state: submittedData
    })
  }

  function onChange(value, option) {
    console.log('onChange')
  }

  return (
    <div>
      <Grid
        container
        // className={'vessel-search-container'}
        classes={{
          container: 'vessel-search-container'
        }}
      >
        <Grid item xs={12}>
          <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
              <FormProperties.Fields
                disabled={false}
                showNotification={showNotification}
                onChange={onChange}
              />
            }
            formikProps={{
              ...FormProperties.formikProps,
              onSubmit: onSubmit
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ViewPage
