import { Box, Card, Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import BookingDetailComponent from './BookingDetailComponent'
import CircularProgress from '@material-ui/core/CircularProgress'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import ResetFilterComponent from './ResetFilterComponent'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
    const observer = useRef()

    const { helpdesk } = props
    const [hasMore, setHasMore] = useState(false)
    const [loading, setLoading] = useState(false)

    const [displayedBooking, setDisplayedBooking] = useState([])

    const loadAmount = 10

    const { fetchPageableRecords } = useServices()

    const [pageNumber, setPageNumber] = useState(0)

    let searchCriteria = props.searchCriteria

    useEffect(() => {
    console.log('isHelpdesk ' + props.helpdesk)
    console.log('page number changes ' + pageNumber)
        console.log(searchCriteria)

        if (pageNumber > 0) {
            setLoading(true)

            ProgressiveLoadingBooking2()

        }
    }, [pageNumber])

    function ProgressiveLoadingBooking2() {
    console.log('ProgressiveLoadingBooking2')
        fetchPageableRecords.execute(
      helpdesk
        ? FreightBookingApiUrls.HELPDESK_BOOKING_LIST
        : FreightBookingApiUrls.BOOKING_LIST,
            {
                filters: [],
                sorts: [],
                page: pageNumber,
                pageSize: loadAmount,
                customData: searchCriteria


            },
            (data) => {
        console.log('booking is back')
                console.log(data.content)

                if (data.content.length < loadAmount) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }

                let temp3 = [...displayedBooking]
                console.log(temp3)
                let temp4 = temp3.concat(data.content)
                console.log(temp4)

                setDisplayedBooking(temp4)
                setLoading(false)
            },
            (error) => {
                
                props.setPageError(true)
            })
    }

    useEffect(() => {
        setLoading(false)
        let temp = [...props.bookingList]
        if (temp.length < loadAmount) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        console.log(hasMore)
        setPageNumber(0)

        setDisplayedBooking(temp)
        console.log(displayedBooking)

    }, [props.bookingList])

  const lastBookElementRef = useCallback(
    (node) => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
                console.log(hasMore)

            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
    <>
            {displayedBooking.map((m, idx) => (
                <div key={idx} ref={lastBookElementRef}>
                    <BookingDetailComponent.FormBody booking={m} {...props} />
                </div>
            ))}

      <div style={{ display: loading ? 'inline' : 'none' }}>
        <Grid container justify='center'>
                    <CngGridItem>
                        <CircularProgress />
                    </CngGridItem>
                </Grid>
            </div>

      <Box mt={5} style={{ display: loading ? 'none' : 'inline' }}>
                <ResetFilterComponent
                    leftCount={props.leftCount}
                    handleClearAllButtonClick={props.handleClearAllButtonClick} />
            </Box>
    </>
    )
}

const BookingListComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default BookingListComponent