import * as CalistaConstant from 'src/constants/CalistaConstant'

import { Box, Card, Grid, IconButton, Button } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ContainerTypeAutocompleteField from 'src/components/autocomplete/ContainerTypeAutocompleteField'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { v4 as uuid } from 'uuid'
import { useFieldArray } from 'react-hook-form'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import ReeferDetailsDialog from './ReeferDetailsDialog'
import { makeStyles } from '@material-ui/core/styles'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const {
  component: {
    textField: {
      customType: { PLAIN_NUMBER }
    }
  }
} = constants

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#5E81F4',
    fontFamily: 'inherit',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4169E1',
      color: '#fff',
    },
  },
  disabledButton: {
    backgroundColor: '#cfcfcf',
    fontFamily: 'inherit',
    color: '#fff'
  }
}))

const initialValues = {
  bookingContainers: [
    {
      containerId: '',
      containerQty: 1,
      containerStatus: CalistaConstant.FULL_CONTAINER_LOAD,
      containerIsoType: '',
      shipperOwned: false,
      reeferAvailable: false,
      nonActiveReefer: false
    }
  ]
}

const FormBody = (props) => {
  const { isCarrierAmend } = props
  const { errors, touched, setFieldValue } = useFormikContext()
  const contTypeKeyRef = useRef(uuid())
  const contStatusKeyRef = useRef(uuid())
  const { fields } = useFieldArray({ name: "bookingContainers" })
  const [reeferOrTankContType, setReeferOrTankContType] = useState(false);
  const [contIdx, setContIdx] = useState();
  const [reeferOrTanker, setReeferOrTanker] = useState([])
  const { fetchRecords } = useServices()
  const [currentReeferDetailsId, setCurrentReeferDetailsId] = useState()
  const [currentContainerIdx, setCurrentContainerIdx] = useState()
  const [isReeferDetailsDialogOpen, setReeferDetailsDialogOpen] = useState(false)
  const classes = useStyles()

  const getFieldError = (itemIndex, fieldPath) => {
    if (errors) {
      let pathArr = fieldPath.split('.')

      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  const translatedTextsObject = FreightBookingTranslationText()

  const containerItem = {
    containerId: '', //for react key reference, actual field is "contId"
    id: uuid(),
    containerQty: '',
    containerStatus: CalistaConstant.FULL_CONTAINER_LOAD,
    containerIsoType: '',
    shipperOwned: false,
    reeferAvailable: false,
    nonActiveReefer: false
  }

  // useState containerList for handle containerList dynamically
  const [containerList, setContainerList] = useState([])
  //useField containers for handle formik valiation and submission
  // const [containersField, , { setValue: setContainersField }] =
  //   useField('bookingContainers')
  const [shouldRender, setShouldRender] = useState(false)
  const draftContId = useRef(-1)

  // handle input change
  const handleInputChange = (e, index) => {
    // console.log('handleInputChange : ' + JSON.stringify(e) )
    const { name, value } = e.target
    const list = [...containerList]
    list[index][name.split('.')[1]] = value

    setContainerList(list)
    //setContainersField(list)
    setFieldValue("bookingContainers", list)
  }

  const handleSwitchChange = (e, index) => {
    const { name } = e.target
    const list = [...containerList]
    list[index][name] = e.target.checked
    setContainerList(list)
    // setContainersField(list)
    setFieldValue("bookingContainers", list)
  }

  const handleDropdownChange = (val, evt, index, name) => {
    if (evt) {
      const { value } = evt
      const list = [...containerList]
      list[index][name] = value
      setContainerList(list)
      setFieldValue("bookingContainers", list, true)

      if (name === 'containerIsoType') {
        setReeferOrTankContType(true)
        setContIdx(index)
      }
    }
  }

  function onContainerTypeLoadSuccess(data) {
    if (data != null) {
      const list = [...containerList]
      let containerItem = list[contIdx]
      if (containerItem != null) {
        let matched = false;

        data.forEach(container => {
          if (container?.isoCode === containerItem.containerIsoType &&
            (container.contTypeDesc.toLowerCase() === 'reefer' || container.contTypeDesc.toLowerCase() === 'tank')) {
            matched = true;
          }
        })

        if (matched) {
          setReeferOrTanker(current => [...current, true])
          list[contIdx]['reeferAvailable'] = true
          setContainerList(list)
          setFieldValue("bookingContainers", list, true)
        }
        else {
          setReeferOrTanker(current => [...current, false])
          list[contIdx]['reeferAvailable'] = false
          setContainerList(list)
          setFieldValue("bookingContainers", list, true)
        }
      }
    }
    setReeferOrTankContType(false);
  }

  useEffect(() => {
    if (reeferOrTankContType) {
      SharedServiceApi.fetchContainerType(
        fetchRecords,
        false,
        onContainerTypeLoadSuccess
      )
    }
  }, [reeferOrTankContType])

  function addReeferDetails(index) {
    setReeferDetailsDialogOpen(true)
    setCurrentReeferDetailsId(index)
    setCurrentContainerIdx(index)
  }

  function closeDialog() {
    setReeferDetailsDialogOpen(false)
  }

  const setContainerField = (newContainerList) => {
    setFieldValue('bookingContainers', newContainerList)
  }
  // handle click event of the Remove button
  const handleRemoveContainer = (index) => {
    const list = [...containerList]
    list.splice(index, 1)
    contTypeKeyRef.current = uuid()
    contStatusKeyRef.current = uuid()

    setContainerList(list)
    // setContainersField(list)
    setFieldValue("bookingContainers", list, true)

    const reeferList = [...reeferOrTanker]
    reeferList.splice(index, 1)
    setReeferOrTanker(reeferList);
  }

  // handle click event of the Add button
  const handleAddContainer = () => {
    const addContainer = { ...containerItem }
    addContainer.containerId = draftContId.current
    addContainer.containerQty = 1
    addContainer.containerIsoType = ''
    draftContId.current = draftContId.current - 1
    setContainerList([...containerList, addContainer])
    // setContainersField([...containerList, addContainer])
    setFieldValue("bookingContainers", [...containerList, addContainer], true)
  }

  //handle click event of the Copy buttion
  const handleCopyContainer = (index) => {
    const list = [...containerList]
    const copyContainer = list.slice(index, index + 1)[0]

    const copyContainerItem = { ...copyContainer }
    copyContainerItem.containerId = draftContId.current
    copyContainerItem.contId = null //cont id is unique for every cargo,cannot be copied from an existing one
    copyContainerItem.seqNo = null //seqNo cannot be copied from other containers
    copyContainerItem.id = uuid()
    draftContId.current = draftContId.current - 1

    setContainerList([...containerList, copyContainerItem])
    // alert('container List,' + JSON.stringify(containerList))

    // setContainersField([...containerList, copyContainerItem])
    setFieldValue("bookingContainers", [...containerList, copyContainerItem])
  }

  useEffect(() => {
    // alert(JSON.stringify(containersField))
    const cont = { ...containerItem }
    cont.containerId = 'cont' + draftContId.current
    cont.containerQty = 1
    cont.containerIsoType = ''
    cont.containerStatus = CalistaConstant.FULL_CONTAINER_LOAD
    draftContId.current = draftContId.current - 1

    console.log('container component props.isEdit > ' + props.isEdit)
    if (props.isEdit) {
      // if containerList is populated from review booking then set back to ContainerList to display
      // data population done in NewBookingFormProperties

      console.log('container component props.isEdit > ' + props.isEdit)
      // console.log(
      //   'container component useEffect > ' +
      //     JSON.stringify(containersField.value)
      // )
      // console.log(containersField)
      if (fields == null) {
        //in case there is no container saved in the bookings
        setContainerList([cont])
        // setContainersField([cont])
        setFieldValue("bookingContainers", [cont])
      } else {
        setContainerList(fields)
        setFieldValue("bookingContainers", fields)
      }

      //need another from useEffect to re render
    } else {
      // for create new booking
      setContainerList([cont])
      //setContainersField([cont])
      setFieldValue("bookingContainers", [cont])
    }

    setShouldRender(true)

  }, [props.isEdit])

  //to highlight container item grid when reefer mandatory details are not entered and submit button is clicked
  useEffect(() => {
    if (props.reeferError != undefined) {
      let element = document.querySelector(`div[id^="containerGrid${props.reeferError}"]`);
      if (element != undefined) {
        element.style.outline = "2px solid #FF808B";
      }
    }
    else {
      let elements = document.querySelectorAll(`div[id^="containerGrid"]`);
      if (elements != undefined) {
        elements.forEach(element => {
          element.style.outline = "none";
        });
      }
    }
  }, [props.reeferError])

  //render again when containersField.value has been set
  useEffect(() => {
    if (props.isEdit && fields != null) {
      setContainerList(fields)
      setShouldRender(true)
    }
  }, [fields])

  if (!shouldRender) {
    return null
  }

  return (
    <Card>
      <Box px={2} my={1.5}>
        <Grid container>
          <AccordionHeaderComponent
            title={translatedTextsObject.containerDetails}
          />
        </Grid>

        <Grid container xs={12} sm={12} spacing={2}>
          {
            console.log(
              'container component > ' + JSON.stringify(containerList)
            )}
          {containerList.map((x, i) => {
            return (
              <CngGridItem xs={12} sm={12} key={x.id} id={`containerGrid${i}`}>
                <Card className='inner-card-group'>
                  <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem xs={4} sm={4}>
                      <CngTextField
                        type='number'
                        name={`bookingContainers[${i}].containerQty`}
                        label={translatedTextsObject.quantity}
                        value={x.containerQty}
                        onChange={(e) => handleInputChange(e, i)}
                        customType={PLAIN_NUMBER}
                        inputProps={{ maxLength: 5 }}
                        isRequired
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <ContainerTypeAutocompleteField
                        name={`bookingContainers[${i}].containerIsoType`}
                        label={translatedTextsObject.containerSizeType}
                        onChange={(val, evt) =>
                          handleDropdownChange(val, evt, i, 'containerIsoType')
                        }
                        key={contTypeKeyRef.current}
                        isRequired
                        disabled={isCarrierAmend}
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngCodeMasterAutocompleteField
                        name={`bookingContainers[${i}].containerStatus`}
                        label={translatedTextsObject.status}
                        codeType='CAL_CONT_STATUS'
                        onChange={(val, evt) =>
                          handleDropdownChange(val, evt, i, 'containerStatus')
                        }
                        key={contStatusKeyRef.current}
                        isRequired
                      />
                    </CngGridItem>

                    {isCarrierAmend ? (
                      <CngGridItem xs={4} sm={4}>
                        {/* <LabelValueVertical label={translatedTextsObject.containerSizeType} 
                                                                value={x.containerIsoType} /> */}
                      </CngGridItem>
                    ) : (
                      <CngGridItem xs={4} sm={4}>
                        <IconButton aria-label='copy'>
                          <FileCopyIcon
                            onClick={() => handleCopyContainer(i)}
                          />
                        </IconButton>
                        {containerList.length > 1 && (
                          <IconButton aria-label='remove'>
                            <DeleteIcon
                              onClick={() => handleRemoveContainer(i)}
                            />
                          </IconButton>
                        )}
                      </CngGridItem>
                    )}
                    {x.reeferAvailable ?
                      <CngGridItem xs={4} sm={4}>
                        <Grid container xs={12} sm={12} spacing={2}>
                          <CngGridItem>
                            <CngSwitchField
                              name={`nonActiveReefer`}
                              label={translatedTextsObject.nonActiveReefer}
                              checked={x.nonActiveReefer}
                              onChange={(e) => handleSwitchChange(e, i)}
                              disabled={isCarrierAmend}
                            />
                          </CngGridItem>
                          {!isCarrierAmend ? (
                            <CngGridItem >
                              <Box display='flex' flexDirection={'row-reverse'} mt={2}>
                                <Button id={`btnAddReefer${i}`} variant='contained'
                                  className="originalText"
                                  classes={{
                                    root: classes.button,
                                    disabled: classes.disabled
                                  }}
                                  onClick={() => { addReeferDetails(i) }}
                                  disabled={x.nonActiveReefer}
                                >
                                  Add Reefer Details
                                </Button>
                              </Box>
                            </CngGridItem>
                          )
                            :
                            (
                              <CngGridItem>
                                <Box display='flex' flexDirection={'row-reverse'} mt={2}>
                                  <Button variant='contained'
                                    className="originalText"
                                    classes={{
                                      root: classes.button,
                                      disabled: classes.disabled
                                    }}
                                    onClick={() => { addReeferDetails(i) }}
                                    disabled={x.nonActiveReefer}
                                  >
                                    View Reefer Details
                                  </Button>
                                </Box>
                              </CngGridItem>
                            )
                          }
                        </Grid>


                      </CngGridItem>
                      :
                      <CngGridItem xs={4} sm={4}></CngGridItem>}

                    <CngGridItem xs={4} sm={4}>
                      <CngSwitchField
                        name='shipperOwned'
                        label={translatedTextsObject.shipperOwned}
                        checked={x.shipperOwned}
                        onChange={(e) => handleSwitchChange(e, i)}
                      />
                    </CngGridItem>
                  </Grid>
                </Card>
              </CngGridItem>
              // </div>
            )
          })}
        </Grid>
      </Box>

      {/* add Container */}
      {isCarrierAmend ? (
        ''
      ) : (
        <FooterAddComponent
          handleAddChild={handleAddContainer}
          footerText={translatedTextsObject.addAContainer}
        />
      )}

      <ReeferDetailsDialog
        isDialogOpen={isReeferDetailsDialogOpen}
        closeDialog={closeDialog}
        currentContainerIdx={currentContainerIdx}
        containerList={containerList}
        setContainerField={setContainerField}
        showNotification={props.showNotification}
        currentReeferDetailsId={currentReeferDetailsId}
        showLocalCategory={props.showLocalCategory}
        isCarrierAmend={isCarrierAmend}
      />
    </Card>
  )
}

const ContainerComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ContainerComponent
