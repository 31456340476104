import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailDualColumnPort from 'src/components/auditTrail/AuditTrailDualColumnPort'
import AuditTrailDualColumnCarrier from 'src/components/auditTrail/AuditTrailDualColumnCarrier'
import AuditTrailDualColumnMultipleFields from 'src/components/auditTrail/AuditTrailDualColumnMultipleFields'
const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const AuditTrailSchedule = (props) => {
    const {booking, ports, currentBooking, carriers} = props
    const tranObj = FreightBookingTranslationText()
    const [scheduleExpanded, setScheduleExpanded] = useState(false)

    const curCarrierPartyId = currentBooking.carrierPartyId

    const handleScheduleExpand = () => {
        setScheduleExpanded(!scheduleExpanded);
    };

    return (
        <Card>
            <Accordion expanded={scheduleExpanded}>
                <AccordionSummary aria-controls="scheduleComponentContent" id='scheduleComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.schedule} noMandatory/>                        
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12}>
                        <AuditTrailDualColumnCarrier object={booking} curCarrierPartyId={curCarrierPartyId} 
                            fields={['carrierPartyId', 'vesselName']} carriers={carriers}
                            label = {tranObj.carrier}/>
                        <AuditTrailDualColumn object={booking} field={'voyageNo'} label = {tranObj.voyageNumber} />
                        <AuditTrailDualColumn object={booking} field={'intVoyageNo'} label = {tranObj.internationalVoyageNumber} />
                        <AuditTrailDualColumnPort object={booking} field={'portOfLoadingCode'} ports={ports} label = {tranObj.portOfLoading} />
                        <AuditTrailDualColumnPort object={booking} field={'portOfDischargeCode'} ports={ports} label = {tranObj.portOfDischarge} />
                        <AuditTrailDualColumn object={booking} field={'polEta'} label = {tranObj.polPlannedEta} timestamp/>
                        <AuditTrailDualColumn object={booking} field={'podEta'} label = {tranObj.podPlannedEta} timestamp/>    
                        <AuditTrailDualColumn object={booking} field={'polEtd'} label = {tranObj.polPlannedEtd} timestamp/>
                        <AuditTrailDualColumn object={booking} field={'podEtd'} label = {tranObj.podPlannedEtd} timestamp/>    
                        <AuditTrailDualColumn object={booking} field={'portOfLoadingTerminal'} label = {tranObj.loadTerminal} />
                        <AuditTrailDualColumn object={booking} field={'portOfDischargeTerminal'} label = {tranObj.dischargeTerminal} />
                        
                        
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleScheduleExpand}
                expanded={scheduleExpanded}
                footerText={tranObj.showScheduleDetails}
                footerTextExpanded={tranObj.hideScheduleDetails}
                noOptionalFields
            />
        </Card>
    )
}

export default AuditTrailSchedule