import {
    Box,
    Grid
} from '@material-ui/core'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'

const LabelValueVertical = (props) => {
    const { label, value, rightConner, valueStyle, boxStyle } = props
    const valueCss = valueStyle ? valueStyle : "view-card-content-value"
    return (
        <Box className="view-card-content" style={boxStyle ? boxStyle : {}}>
            <Box className="view-card-content-label">
                <Grid container justify="space-between">
                    <Grid item>
                        {label}
                    </Grid>
                    {rightConner && (
                        <Grid item>
                            {rightConner}
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className={valueCss} style={{ wordBreak: 'break-word', whiteSpace: "pre-line" }}>
                {Utils.isNotEmpty(value) ? value : '-'}
            </Box>
        </Box>

    )
}
export default LabelValueVertical;