import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  makeStyles,
  Box
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect, useState } from 'react'
import { useServices } from 'cng-web-lib'
import DeleteIcon from '@material-ui/icons/Delete'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import Utils from 'src/views/common/utils/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

import HtmlTooltip from './HtmlTooltip'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto  !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: 'auto !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

const DGCargoDetailsTable = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const classes = useStyles()
  // console.log(props.detailsList)
  const cargoIdx = props.cargoIdx
  const editMode = props.editMode
  // console.log(props.editMode)
  const { fetchRecords } = useServices()

  const [imoItems, setImoItems] = useState([])
  const [auditHighlight, setAuditHighlight] = useState(false)
  const { audit } = props
  // console.log('detailsList audit', audit)
  console.log('props.detailsList', props.detailsList)

  function getImoDesc(imoCode) {
    let imoDesc = ''
    imoItems
      .filter((uomItem) => uomItem.code === imoCode)
      .map((selected) => {
        imoDesc = selected.descriptionEn
        return null
      })

    return imoDesc
  }

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_DG_IMO',
      false,
      onLoadIMOCodeSuccess
    )
  }, [props.editMode])

  function onLoadIMOCodeSuccess(data) {
    setImoItems(data)
  }

  if (props.detailsList == null || props.detailsList.length <= 0) {
    return <br />
  } else {
    return (
      <Box m='8px' className={classes.div}>
        <TableContainer component={Paper}>
          <Table id='table'>
            <TableHead>
              <TableRow>
                <TableCell>Details No</TableCell>
                <TableCell>{translatedTextsObject.imoClass}</TableCell>
                <TableCell>{translatedTextsObject.undgNumber}</TableCell>
                <TableCell>
                  {translatedTextsObject.properShippingName}
                </TableCell>
                <TableCell>{translatedTextsObject.outerPkgQTY}</TableCell>
                <TableCell>{translatedTextsObject.innerPkgQTY}</TableCell>
                <TableCell>{translatedTextsObject.emergencyContact}</TableCell>
                {editMode && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.detailsList.map((m, i) => {
                let highlight = {}
                if (audit && !Utils.isEmptyObject(audit)) {
                  console.log('id is ' + m.dgCargoDetailsId)
                  console.log('audit id is ', audit)
                  let auditAddUpdated = audit.added
                    ? audit.updated
                      ? audit.added.concat(audit.updated)
                      : audit.added
                    : [].concat(audit.updated)
                  console.log('audit and and update id is, ', auditAddUpdated)
                  if (!Utils.isEmptyObject(auditAddUpdated)) {
                    highlight = auditAddUpdated.filter(
                      (c) => c.id === m.dgCargoDetailsId
                    )

                    console.log('highlight id is, ', highlight)
                  }
                }

                return (
                  <TableRow
                    key={`row-${i}`}
                    hover
                    onClick={props.handleRowClick(cargoIdx, i)}
                    style={
                      Utils.isEmptyObject(highlight) ? {} : Utils.highLighted
                    }
                  >
                    {/* <CardActionArea style={{ backgroundColor: "red" }} onClick={(event) => props.handleRowClick(event, m)}> */}
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      <HtmlTooltip
                        title={getImoDesc(m.imoClass)}
                        placement='right'
                      >
                        <div>
                          {Utils.trimString(getImoDesc(m.imoClass), 17)}
                        </div>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell>{m.undgNumber}</TableCell>
                    {/* <TableCell>{m.properShippingName}</TableCell> */}
                    <TableCell>
                      <HtmlTooltip
                        title={m.properShippingName}
                        placement='right'
                      >
                        <div>{Utils.trimString(m.properShippingName, 17)}</div>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell>{m.outerPackingQty}</TableCell>
                    <TableCell>{m.innerPackingQty}</TableCell>
                    {/* <TableCell>{m.emergencyContact}</TableCell> */}
                    <TableCell>
                      <HtmlTooltip title={m.emergencyContact} placement='right'>
                        <div>{Utils.trimString(m.emergencyContact, 17)}</div>
                      </HtmlTooltip>
                    </TableCell>

                    {editMode && (
                      <TableCell align='right'>
                        <IconButton
                          onClick={props.copyRowClick(cargoIdx, i)}
                          aria-label='copy'
                        >
                          <FileCopyIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                          aria-label='remove'
                          onClick={props.removeRowClick(cargoIdx, i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default DGCargoDetailsTable
