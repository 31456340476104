import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import { components, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components


const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const initialValues = Object.freeze({
    imoClass: "",
    imoClassDesc: "",
    undgNumber: "",
    properShippingName: "",
    technicalName: "",
    imdgCodePage: "",
    regulationPageNumber: "",
    regulationVersionNumber: "",
    emsNumber: "",

})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [classIdenDetailsExpanded, setClassIdenDetailsExpanded] = useState(false)

    const [, ,] = useField('imoClass')
    const [, , { setValue: setImoClassDescField }] = useField('imoClassDesc')
    const [, ,] = useField('undgNumber')
    const [, ,] = useField('properShippingName')
    const [, ,] = useField('technicalName')
    const [, ,] = useField('imdgCodePage')
    const [, ,] = useField('regulationPageNumber')
    const [, ,] = useField('regulationVersionNumber')
    const [, ,] = useField('emsNumber')
    const [imoClassKey, setImoClassKey] = useState()


    const handleClassIdenDetailsExpand = () => {
        setClassIdenDetailsExpanded(!classIdenDetailsExpanded)
    }

    const onIMOClassChange = (value, option) => {
        console.log(value)
        console.log(option)
        if (option != null) {
            setImoClassDescField(option.label)
        } else {
            setImoClassDescField("")
        }
    }

    useEffect(() => {
        setImoClassKey(uuid())
    }, [props.isEdit])




    return (
        <Card>
            <Accordion expanded={classIdenDetailsExpanded}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.classificationIdentication} />

                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={8} sm={8}>
                                <CngCodeMasterAutocompleteField
                                    name='imoClass'
                                    label={translatedTextsObject.imoClass}
                                    required
                                    codeType='CAL_DG_IMO'
                                    onChange={onIMOClassChange}
                                    key={imoClassKey}
                                />

                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='number'
                                    name='undgNumber'
                                    inputProps={{ maxLength: 4 }}
                                    label={translatedTextsObject.undgNumber}
                                    required
                                    customType={PLAIN_NUMBER}
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='text'
                                    name='properShippingName'
                                    inputProps={{ maxLength: 100 }}
                                    label={translatedTextsObject.properShippingName}
                                    required
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                inputProps={{ maxLength: 100 }}
                                name='technicalName'
                                label={translatedTextsObject.technicalName}

                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                inputProps={{ maxLength: 100 }}
                                name='imdgCodePage'
                                label={translatedTextsObject.IMDGCodePage}

                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                inputProps={{ maxLength: 100 }}
                                name='regulationPageNumber'
                                label={translatedTextsObject.regulationPageNumber}

                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                inputProps={{ maxLength: 100 }}
                                name='regulationVersionNumber'
                                label={translatedTextsObject.regulationVersionNumber}

                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                name='emsNumber'
                                inputProps={{ maxLength: 100 }}
                                label={translatedTextsObject.EMSNumber} />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleClassIdenDetailsExpand}
                expanded={classIdenDetailsExpanded}
                footerText={translatedTextsObject.additionalClassIdenDetails}
            />
        </Card>
    )
}

const DGCargoDetailsClassIdenComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default DGCargoDetailsClassIdenComponent