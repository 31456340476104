import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailDualColumnMultipleFields from 'src/components/auditTrail/AuditTrailDualColumnMultipleFields'
// import useFetchCountryCodes from 'src/views/vesselschedule/searchschedule/useFetchCountryCodes'

const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const AuditTrailSchedule = (props) => {
    const {payment} = props
    const tranObj = FreightBookingTranslationText()
    const [scheduleExpanded, setScheduleExpanded] = useState(false)

    const handleScheduleExpand = () => {
        setScheduleExpanded(!scheduleExpanded);
    };

    return (
        <Card>
            <Accordion expanded={scheduleExpanded}>
                <AccordionSummary aria-controls="paymentComponentContent" id='paymentComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.paymentInstruction} hideMandatory/>                        
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12}>
                        <AuditTrailDualColumn object={payment} field={'chrgType'} label={tranObj.chargeType} />
                        <AuditTrailDualColumn object={payment} field={'frtTerm'} label={tranObj.freightTerm} />
                        <AuditTrailDualColumn object={payment} field={'payer'} label={tranObj.payer} />
                        <AuditTrailDualColumn object={payment} field={'pmtLoc'} label={tranObj.paymentLocation} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleScheduleExpand}
                expanded={scheduleExpanded}
                footerText={tranObj.showPaymentDetails}
                footerTextExpanded={tranObj.hidePaymentDetails}
                noOptionalFields
            />
        </Card>
    )
}

export default AuditTrailSchedule