import { Box, Grid } from '@material-ui/core'

import BookingStatusDescComponent from './BookingStatusDescComponent'
import React from 'react'
import StatusIconComponent from './StatusIconComponent'
import ViewLastActionComponent from './ViewLastActionComponent'
import { components } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import Hazardous from 'src/components/button/Hazardous.js'

const {
    CngGridItem
} = components

const FormBody = (props) => {
    const { booking, bookingStatus } = props
    const translatedTextsObject = FreightBookingTranslationText()

    return (
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={6} sm={6}>
                <Box fontWeight={600} fontSize="h3.fontSize" >
                    <Box display="flex" flexDirection="row">
                        <BookingStatusDescComponent bookingStatus={bookingStatus} />
                        <Box pl={1.5}>
                            <StatusIconComponent bookingStatus={bookingStatus} />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <ViewLastActionComponent booking={booking} bookingStatus={bookingStatus} />
                    {/* <ViewLastModifiedComponent text={actionText} date={actionDate} /> */}
                </Box>
            </CngGridItem>

            <CngGridItem xs={6} sm={6}>
                <Box className="view-card-content">
                    <Grid container xs={12} sm={12} spacing={2} justify="flex-end" alignItems="center">
                        <CngGridItem>
                            <Box fontSize="fontSize" >
                                <b>{(translatedTextsObject.freightBookingId + ' : '
                                    + Utils.getDashIfNullOrEmpty(booking.freightBookingId))}
                                </b>
                            </Box>
                        </CngGridItem>
                    </Grid>
                </Box>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({

})

const BookingInfoComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default BookingInfoComponent
