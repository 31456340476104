import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import DocumentDetails from './DocumentDetails'
import DocumentDetailsComponents from './DocumentDetailsComponents'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import DocumentApi from 'src/views/common/api/DocumentApi'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import Utils from 'src/views/common/utils/Utils'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'

const {
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  },
  CngGridItem,
  dropzone: { CngFileUpload, HelperTextArea },
  table: { useDefaultNotification }
} = components

const { CodeMaintenanceType } = constants

const FormBody = (props) => {

  const { fields: docField } = useFieldArray({ name: 'bookingDocuments' })
  const { fields: pendingDocField } = useFieldArray({
    name: 'pendingBookingDocuments'
  })
  const translatedTextsObject = FreightBookingTranslationText()
  const [pendingDocs, setPendingDocs] = useState([]) //for the file list below drop zone
  const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
  const [documentExpanded, setDocumentExpanded] = useState(false)
  const { securedSendRequest, fetchRecords } = useServices()
  const { errors, touched, setFieldValue } = useFormikContext()
  const docTypeKeyRef = useRef(uuid())
  const [fieldConfig, setFieldConfig] = useState([])
  const handleShipmentExpand = () => {
    setDocumentExpanded(!documentExpanded)
  }
  const [shouldRender, setShouldRender] = useState(false)
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  const handleUploadedInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...uploadedDocs]
    list[index][name.split('.')[1]] = value

    console.log(list)
    setUploadedDocs(list)
    setFieldValue('bookingDocuments', list)
  }

  const handleUploadedDropdownChange = (val, evt, index, name) => {
    if (evt) {
      const { value } = evt
      const list = [...uploadedDocs]

      list[index][name] = value

      setUploadedDocs(list)

      setFieldValue('bookingDocuments', list)
    }
  }


  const showErrorMessages = (errorMessages) => {
    showErrorNotification(errorMessages[0])
  }

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  function onLoadDocTypeSuccess(data) {
    console.log('onLoadDocTypeSuccess', props.isEdit)
    let docTypeOptionList = []
    let docTypeOptionDict = {}
    console.log(data)
    if (data.length == 0) {
      console.log('null')
    } else {
      data.map((singleDocTypeOption) => {
        var detailedOption = {
          value: singleDocTypeOption.code,
          label: singleDocTypeOption.descriptionEn
        }
        docTypeOptionList.push(detailedOption)
      })
    }

    let currentFieldConfig = [
      {
        fieldType: 'dropDown',
        fieldName: 'docType',
        label: 'File type',
        mandatory: true,
        error: false,
        errorMessage: 'Required',
        validationRule: '',
        dropDownLov: docTypeOptionList
      },
      {
        fieldType: 'textInput',
        fieldName: 'description',
        label: 'Description',
        mandatory: true,
        error: false,
        errorMessage: 'Max 1024 characters',
        validationRule: /^.{0,1024}$/
      }
    ]

    setFieldConfig(currentFieldConfig)

    if (props.isEdit) {
      console.log('pendingDocField', pendingDocField)
      setPendingDocs(pendingDocField)

      console.log('uploadedDocsField', docField)

      let uploadedDocList = docField
      if (uploadedDocList != null) {
        let uploadedDocListWithEdiable = uploadedDocList.map((v) => ({
          ...v,
          editable: false
        }))

        setUploadedDocs(uploadedDocListWithEdiable)

        //alert("list: " + JSON.stringify(uploadedDocList))
      }
      setShouldRender(true)
    } else {
      console.log('doc should render')
      setShouldRender(true)
    }
  }

  useEffect(() => {

    console.log('doc!')
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.DOCUMENT,
      [
        {
          field: 'docType',
          operator: 'EQUAL',
          value: 'CLOGS_FRB'
        }
      ],
      false,
      onLoadDocTypeSuccess
    )

  }, [props.isEdit])

  const removeUploadedRowClick = (id) => {

    uploadedDocs.splice(id, 1)
    const newFiles = [].concat([], uploadedDocs)
    setUploadedDocs(newFiles)

    setFieldValue('bookingDocuments', newFiles)
    docTypeKeyRef.current = uuid()
  }

  const downloadUploadedRowClick = (id) => {

    DocumentApi.downloadDocument(securedSendRequest, uploadedDocs[id])


  }



  const editRowClick = (id) => {

    console.log(id)

    const newFiles = [].concat([], uploadedDocs)
    newFiles[id].editable = true
    setUploadedDocs(newFiles)

    setFieldValue('bookingDocuments', newFiles)
  }



  const getFieldError = (itemIndex, fieldPath) => {

    if (errors) {
      let pathArr = fieldPath.split('.')

      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            // return true
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  const setSelectedFiles = (file) => {
    // alert('setSelectedFiles')
    //long file name
    let processedFileObjs = []
    file.forEach((item) => {

      if (item.file.name.length > 26) {
        var newFile = new File(
          [item.file],
          Utils.truncLongFileName(item.file.name)
        )

        let processedFileObj = {
          file: newFile,
          fields: item.fields
        }
        processedFileObjs.push(processedFileObj)
      } else {
        processedFileObjs.push(item)
      }
    })

    console.log('processedFileObjs', processedFileObjs)
    setFieldValue('pendingBookingDocuments', processedFileObjs)

  }


  if (!shouldRender) {
    return null
  }

  return (
    <Card>
      <Accordion expanded={documentExpanded}>
        <AccordionSummary
          aria-controls='shipmentComponentContent'
          id='shipmentComponentHeader'
        >
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <AccordionHeaderComponent
                  title={translatedTextsObject.document}
                  hideMandatory
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12} sm={12} spacing={2}>
            <CngGridItem xs={12} sm={12}>
              <DocumentDetailsComponents
                tile={uploadedDocs}
                removeRowClick={removeUploadedRowClick}
                handleInputChange={handleUploadedInputChange}
                handleDropdownChange={handleUploadedDropdownChange}
                editRowClick={editRowClick}
                downloadRowClick={downloadUploadedRowClick}
                getFieldError={getFieldError}
                docTypeKeyRef={docTypeKeyRef.current}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={12}>
              <CngFileUpload
                accept={[
                  '.pdf',
                  '.xls',
                  '.xlsx',
                  '.doc',
                  '.docx',
                  '.png',
                  '.jpg',
                  '.jpeg',
                  '.ppt',
                  '.pptx'
                ]}
                maxSize={10485760}
                onFileSelect={setSelectedFiles}
                showFormFields={true}
                files={pendingDocs}
                fieldConfig={fieldConfig}
                moreActions={moreActions}
                onDropRejected={(error) => showErrorMessages(error)}
                renderHelperText={() => {
                  return (
                    <HelperTextArea
                      accept={[
                        '.pdf',
                        '.xls',
                        '.xlsx',
                        '.doc',
                        '.docx',
                        '.png',
                        '.jpg',
                        '.jpeg',
                        '.ppt',
                        '.pptx']}
                      maxSize={10485760}
                    />
                  )
                }}
              />

            </CngGridItem>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AccordionFooterControlComponent
        handleAccordionExpand={handleShipmentExpand}
        expanded={documentExpanded}
        footerText={translatedTextsObject.attachDocument}
      />
    </Card>
  )
}

const initialValues = {
  bookingDocs: {
    description: '',
    docType: '',
    ownerType: 'FRB'
  }
}

const DocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default DocumentComponent
