import { Box, Button, Grid } from '@material-ui/core'
import React, { useCallback, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ArchiveButton from 'src/components/button/ArchiveButton.js'
import CancelButtonWithIcon from 'src/components/button/CancelButtonWithIcon.js'
import pathMap from 'src/paths/PathMap_FreightBooking'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import CopyButton from 'src/components/button/CopyButton.js'
import DeleteButton from 'src/components/button/DeleteButton.js'
import EditButton from 'src/components/button/EditButton.js'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
    table: { useDefaultNotification }
} = components


function ReviewBookingButtonComponent(props) {
    let defaultButtonLayout = {
        showEdit: false,
        showCopy: false,
        showDelete: false,
        showCancel: false,
        showArchive: false

    }

    const translatedTextsObject = FreightBookingTranslationText()
    const { createRecord } = useServices()
    const history = useHistory()
    const buttonLayout = props.buttonLayout != null ? props.buttonLayout : defaultButtonLayout
    const showPrimary = !buttonLayout.showEdit //if edit button is not shown, then copy button will be in primary style
    console.log(props)
    const { showNotification } = props
    console.log(showNotification)
    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)



    const gotoMyBooking = () => {
        history.push({
            pathname: '/cal-freightbooking/my-booking',
        })
    }

    const deleteBooking = () => {
        console.log("delete")
    }


    const cancelBooking = () => {
        console.log("cancel")
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.cancelSuccess} `)
                history.push({
                    pathname: pathMap.MY_BOOKING,
                    // state: response
                })
            }
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            // setLoading(false)
            // showErrorNotification(error.message)
        }


        createRecord.execute(
            FreightBookingApiUrls.CANCEL_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )
    }

    const archiveBooking = () => {
        console.log("archive")
    }

    const editBooking = () => {

        history.push({
            //pathname: '/cal-freightbooking/my-booking/edit',
            pathname: pathMap.CREATE_NEW_BOOKING,
            state: props.booking
        })

    }

    const copyBooking = () => {

        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.copySuccess} ${response.freightBookingId}`)
                history.push({
                    pathname: pathMap.CREATE_NEW_BOOKING,
                    state: response
                })
            }
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            // setLoading(false)
            // showErrorNotification(error.message)
        }


        createRecord.execute(
            FreightBookingApiUrls.COPY_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )

    }





    return (
        <Box pt={5}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify="flex-start">
                    <CngSecondaryButton onClick={gotoMyBooking}>Back to My Booking</CngSecondaryButton>
                </Grid>
                <Grid container xs={6} sm={6} justify="flex-end" >
                    <Box pr={2} style={{ display: buttonLayout.showDelete ? "inline" : "none" }}>
                        <DeleteButton color="secondary" onClick={deleteBooking} />
                    </Box>
                    <Box pr={2} style={{ display: buttonLayout.showCancel ? "inline" : "none" }}>
                        <CancelButtonWithIcon color="secondary" onClick={cancelBooking} />
                    </Box>
                    <Box pr={2} style={{ display: buttonLayout.showArchive ? "inline" : "none" }}>
                        <ArchiveButton onClick={archiveBooking} />
                    </Box>
                    <Box pr={2} style={{ display: buttonLayout.showCopy ? "inline" : "none" }}>
                        <CopyButton isPrimary={showPrimary} onClick={copyBooking} />

                    </Box>
                    <Box pr={2} style={{ display: buttonLayout.showEdit ? "inline" : "none" }}>
                        <EditButton onClick={editBooking} />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default ReviewBookingButtonComponent