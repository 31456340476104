import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
    CngGridItem
} = components

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [shipperName, setShipperName] = useState()
    const [shouldRender, setShouldRender] = useState(false)

    console.log('props.booking >' + JSON.stringify(props.booking))

    useEffect(()=> {
        console.log('props.booking.shipperName >' + props.booking.shipperName)
        setShipperName(props.booking.shipperName)
        setShouldRender(true)
    }, [props.booking])

    if (!shouldRender) {
        return null;
    }

    return (
        <Grid container xs={12} sm={12} >
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className="view-card-header">
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {translatedTextsObject.shipperName} : {shipperName}
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}


const initialValues = Object.freeze({

})

const ViewShipperInfoComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewShipperInfoComponent
