import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngRadioGroupField }
  },
  CngGridItem
} = components

const DateRangeFilterComponent = (props) => {
  const [, , { setValue: setDateRange }] = useField('dateRange')
  //  const [, , { setValue: setPollingId }] = useField('pollingId')
  const translatedTextsObject = FreightBookingTranslationText()
  const dateRangeOptions = [
    { label: translatedTextsObject.bookingFilterAll, value: 'all' },
    { label: translatedTextsObject.bookingFilterDate15, value: '15' },
    { label: translatedTextsObject.bookingFilterDate30, value: '30' },
    { label: translatedTextsObject.bookingFilterDate60, value: '60' }
  ]

  const { submitForm } = useFormikContext()

  useEffect(() => {
    console.log(props.dateRange)
    setDateRange(props.dateRange)
  }, [])

  return (
    <section className={'cng-filter-pane--section-container'}>
      <header>
        <Typography
          variant='subtitle1'
          className={'cng-filter-pane--section-header-label'}
        >
          {translatedTextsObject.lastModifiedDate}
        </Typography>
      </header>
      <article>
        <Box className={`cng-filter-pane--controls-wrapper`}>
          <CngRadioGroupField
            name='dateRange'
            options={dateRangeOptions}
            direction='column'
            onChange={(e) => {
              //  setPollingId('')
              props.dateRangeChange(e)
              //submitForm()
            }}
          />
        </Box>
      </article>
    </section>
  )
}

export default DateRangeFilterComponent
