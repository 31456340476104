import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import AddLocationOutlinedIcon from '@material-ui/icons/AddLocationOutlined'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import {
    components
} from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
    button: { CngPrimaryButton, CngSecondaryButton }
} = components


const BookingListNoResult = props => {
    const {manageBooking} = props
    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    const history = useHistory()

    const goToNewBooking = () =>{
        history.push({
            pathname: pathMap.CREATE_NEW_BOOKING,
        })
    }

    const goToBookFromSchedule = () =>{
        history.push({
            pathname: pathMap.BOOK_FROM_SCHEDULE,
        })
    }

    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/freightBooking/noresult.png'
                }
                alt='No Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px'}}>
                {translatedTextsObject.noItemHereYet}
            </Typography>
            {!manageBooking ? (
                <Typography variant='subtitle1' style={{ paddingTop: '10px', color:'#808080'}}>
                    {translatedTextsObject.noItemDescShipper}
                </Typography>
            ): 
                <Typography variant='subtitle1' style={{ paddingTop: '10px', color:'#808080'}}>
                    {translatedTextsObject.noItemDescCarrier}
                </Typography>
            }
            {!manageBooking ? (
            <Box pt={2}>
                <Grid container>
                    <CngPrimaryButton 
                        onClick={goToNewBooking}
                    >
                        <AddLocationOutlinedIcon />
                        <Box pl={1}>
                            {translatedTextsObject.createANewBooking}
                        </Box>
                    </CngPrimaryButton>
                    <Box pl={2} pr={2} display="flex" alignItems="center">{uiTranslatedTextsObject.or}</Box>
                    <CngSecondaryButton
                        onClick={goToBookFromSchedule}
                    >
                        <AddCircleOutlineOutlinedIcon />
                        <Box pl={1}>
                            {translatedTextsObject.bookFromScheduleTitle}
                        </Box>
                    </CngSecondaryButton>
                </Grid>

            </Box>
            ): 
            <Box pt={2}>
                <Grid container>
                    <CngSecondaryButton onClick={props.refreshAction}>
                        <RefreshIcon />
                        <Box pl={1}>
                            {translatedTextsObject.refresh}
                        </Box>
                    </CngSecondaryButton>
                </Grid>
            </Box>
            }

        </div>
    )
}

export default BookingListNoResult;