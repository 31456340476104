import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BookingSyncLogApiUrls from 'src/apiUrls/BookingSyncLogApiUrls'
import React from 'react'
import BookingSyncLogKeys from 'src/constants/locale/key/BookingSyncLog'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BOOKING_SYNC_LOG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let bookingSyncLog = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: bookingSyncLog
      }
    )
    let source = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.SOURCE
    )
    let destination = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.DESTINATION
    )
    let dockey = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.DOCKEY
    )
    let migrationTime = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.MIGRATION_TIME
    )
    let retry = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.RETRY
    )
    let startTime = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.START_TIME
    )
    let endTime = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.END_TIME
    )
    let processStatus = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.PROCESS_STATUS
    )
    let errorLog = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.ERROR_LOG
    )
    let content = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.CONTENT
    )
    let processResponse = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.PROCESS_RESPONSE
    )

    return {
      bookingSyncLog,
      tableTitle,
      source,
      destination,
      dockey,
      migrationTime,
      retry,
      startTime,
      endTime,
      processStatus,
      errorLog,
      content,
      processResponse
    }
  }

  const columns = [
    {
      field: "source",
      title: translatedTextsObject.source,
    },
    {
      field: "destination",
      title: translatedTextsObject.destination,
    },
    {
      field: "dockey",
      title: translatedTextsObject.dockey,
    },
    {
      field: "migrationTime",
      title: translatedTextsObject.migrationTime,
      type: "datetime",
      filterComponent: CngDateRangeFilter,
      defaultSort: "desc",
    },
    {
      field: "retry",
      title: translatedTextsObject.retry,
      type: "numeric",
    },
    {
      field: "startTime",
      title: translatedTextsObject.startTime,
      type: "datetime",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "endTime",
      title: translatedTextsObject.endTime,
      type: "datetime",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "processStatus",
      title: translatedTextsObject.processStatus,
    },
    {
      field: "errorLog",
      title: translatedTextsObject.errorLog,
    },
    // {
    //   field: "content",
    //   title: translatedTextsObject.content,
    // },
    // {
    //   field: "processResponse",
    //   title: translatedTextsObject.processResponse,
    // }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              // addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: BookingSyncLogApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: BookingSyncLogApiUrls.EXPORT }}
              fetch={{ url: BookingSyncLogApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
