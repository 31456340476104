import {
    Button,
    Grid,
    Typography,
    Box
} from '@material-ui/core'

import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore';
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import {
    components
} from 'cng-web-lib'

const {
    CngGridItem
} = components

const ResetFilterComponent = props => {


    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    return (
        <Box style={{ display: (props.leftCount > 0) ? "inline" : "none" }}>
            <Grid container="true" md={12} alignItems="center" justify="center">
                <Grid container md={6} className={'greyBox'} direction="row" justify="center" alignItems="center">
                    <CngGridItem style={{ paddingRight: 20 }}>
                        <Typography class={'greyLabel'}>{props.leftCount} more booking(s) hidden by filters</Typography>
                    </CngGridItem>
                    <Grid >
                        <Button
                            name="clear"
                            startIcon={<RestoreIcon />}
                            classes={{ root: 'ng-button-filled-secondary' }}
                            size="small"
                            onClick={props.handleClearAllButtonClick}
                        >{uiTranslatedTextsObject.resetFilter}</Button>
                    </Grid>
                </Grid>
            </Grid >
        </Box>
    )
}

export default ResetFilterComponent;