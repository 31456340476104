import React, { useState, useMemo } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ReeferDetailsContext from './ReeferDetailsContext.js'
import ViewReeferDetailsForm from './ViewReeferDetailsForm.js'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ViewReeferDetailsDialog({
  isDialogOpen,
  closeDialog,
  currentReeferDetails,
  showNotification,
  currentAudit,
  containerType
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const value = useMemo(() => {
    return {
      closeDialog,
      showNotification,
      form: {
        isSubmitting,
        setSubmitting
      },
      currentReeferDetails,
      currentAudit
    }
  }, [closeDialog, showNotification, isSubmitting, currentReeferDetails, currentAudit])


  return (
    <ReeferDetailsContext.Provider
      value={value}
    >
      <CngDialog
        customDialogContent={
          <Paper className='accordian-header'>
            <ViewReeferDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            {
              (containerType != null) ? (
                <b>{'Reefer Details for Container ' + containerType}</b>
              )
                :
                (
                  <b>{'Reefer Details for Container'}</b>
                )
            }
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </ReeferDetailsContext.Provider>
  )
}

export default ViewReeferDetailsDialog
