import BookingSyncLogApiUrls from 'src/apiUrls/BookingSyncLogApiUrls'
import BookingSyncLogFormProperties from './BookingSyncLogFormProperties'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngEditForm }
} = components


function BookingSyncLogEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     },
    items
}) {
    return (
        <CngEditForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <BookingSyncLogFormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    isEdit={true}
                    items={items}
                />
            }
            formikProps={BookingSyncLogFormProperties.formikProps}
            toClientDataFormat={BookingSyncLogFormProperties.toClientDataFormat}
            toServerDataFormat={BookingSyncLogFormProperties.toServerDataFormat}
            fetch={{
                url: BookingSyncLogApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: BookingSyncLogApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default BookingSyncLogEditForm
