import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import VesselSearchDialog from '../managebooking/amend/VesselSearchDialog'
import moment from 'moment'
import Utils from 'src/views/common/utils/Utils'
const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateTimeField, CngSwitchField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  carrierPartyId: '',
  polCode: '',
  podCode: '',
  polCountryCode: '',
  podCountryCode: '',
  polEta: DateTimeFormatter.toClientDate(new Date()),
  podEta: DateTimeFormatter.toClientDate(new Date()),
  polEtd: '',
  podEtd: '',
  vesselName: '',
  intVoyageNo: '',
  voyageNo: '',
  vesselVoyage: '',
  portOfLoadingTerminal: '',
  portOfDischargeTerminal: ''
})

const FormBody = (props) => {
  const showNotification = props.showNotification
  const { setFieldValue } = useFormikContext()
  const translatedTextsObject = FreightBookingTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)

  const [, , { setValue: setVoyageIdField }] = useField('voyageId')
  const [, ,] = useField('carrierPartyId')
  const [polCodeField, ,] = useField('polCode')
  const [podCodeField, ,] = useField('podCode')
  const [, ,] = useField('polCountryCode')
  const [, ,] = useField('podCountryCode')
  const [polEtaField, , { setValue: setPolEtaField }] = useField('polEta')
  const [podEtaField, , { setValue: setPodEtaField }] = useField('podEta')
  const [polEtdField, , { setValue: setPolEtdField }] = useField('polEtd')
  const [, , { setValue: setPodEtdField }] = useField('podEtd')
  const [, , { setValue: setVesselNameField }] = useField('vesselName')
  const [, , { setValue: setIntVoyageNoField }] = useField('intVoyageNo')
  const [, , { setValue: setVoyageNoField }] = useField('voyageNo')
  const [, , { setValue: setVesseVoyageField }] = useField('vesselVoyage')
  const [, , { setValue: setPortOfLoadingTerminal }] = useField(
    'portOfLoadingTerminal'
  )
  const [, , { setValue: setPortOfDischargeTerminal }] = useField(
    'portOfDischargeTerminal'
  )

  const [newVessel, setNewVessel] = useState(false)
  const [vesselDialog, setVesselDialog] = useState(false)

  // countryCodeByPortCodeRef.current = useFetchCountryCodes()
  const SG_DATE_TIME_FORMAT = Utils.UI_FORMAT_DATE_TIME
  const SG_DATE_FORMAT = Utils.UI_FORMAT_DATE

  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  const handleNewVesselChange = (e) => {
    setNewVessel(e.target.checked)
  }

  function openVesselSeachDialog() {
    console.log('openVesselSeachDialog')
    setVesselDialog(true)
  }

  function closeDialog(schedule) {
    if (schedule.vesselName != null) {
      console.log(schedule)
      setVesselNameField(schedule.vesselName)
      setVoyageNoField(schedule.voyageNo)
      setIntVoyageNoField(
        schedule.internationalVoyageNumber === null
          ? ''
          : schedule.internationalVoyageNumber
      )
      setPortOfLoadingTerminal(
        schedule.polTerminal === null ? '' : schedule.polTerminal
      )

      setPortOfDischargeTerminal(
        schedule.podTerminal === null ? '' : schedule.podTerminal
      )
      setVoyageIdField(schedule.voyageId)
      setPolEtaField(
        Utils.formatDate(schedule.polEta, Utils.UI_FORMAT_DATE_TIME, '')
      )
      setPolEtdField(
        Utils.formatDate(schedule.polEtd, Utils.UI_FORMAT_DATE_TIME, '')
      )
      setPodEtaField(
        Utils.formatDate(schedule.podEta, Utils.UI_FORMAT_DATE_TIME, '')
      )
      setPodEtdField(
        Utils.formatDate(schedule.podEtd, Utils.UI_FORMAT_DATE_TIME, '')
      )
      //placeOfReceiptEtd will follow poletd, placeOfReceiptEtd will follow podeta
      setFieldValue(
        'placeOfDeliveryEta',
        Utils.formatDate(schedule.podEta, Utils.UI_FORMAT_DATE_TIME, ''),
        true
      )
      setFieldValue(
        'placeOfReceiptEtd',
        Utils.formatDate(schedule.polEtd, Utils.UI_FORMAT_DATE_TIME, ''),
        true
      )

      setVesseVoyageField(schedule.vesselName + ' / ' + schedule.voyageNo)
    }
    setVesselDialog(false)
  }

  const onChangePodEta = (date) => {
    props.PodEtaDateChange(date, 'podEta')
    setFieldValue('placeOfDeliveryEta', date, true)
  }

  const onChangePolEtd = (date) => {
    props.PolEtdDateChange(date, 'polEtd')
    setFieldValue('placeOfReceiptEtd', date, true)
  }

  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='scheduleComponentContent'
          id='scheduleComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={translatedTextsObject.schedule}
              freightBookingId={props.scheduleData.freightBookingId}
            />
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  name='vesselVoyage'
                  label={translatedTextsObject.vesselVoyage}
                  disabled={newVessel}
                  onClick={!newVessel ? openVesselSeachDialog : ''}
                  inputProps={{ readOnly: true }}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngSwitchField
                  name='newVessel'
                  checked={newVessel}
                  label={translatedTextsObject.newVesselVoyage}
                  onChange={(e) => handleNewVesselChange(e)}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={12}>
                <Card className='inner-card-group'>
                  <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem xs={4} sm={4}>
                      <CarrierAutocompleteField
                        name='carrierPartyId'
                        label={
                          translatedTextsObject.carrierNvoccBookingAgent}
                        disabled
                        required
                        key={props.polKeyRef}
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <PortSelectAutoComplete
                        name='polCode'
                        label={translatedTextsObject.portOfLoading}
                        keyRef={props.polKeyRef}
                        value={polCodeField.value}
                        disabled={!newVessel}
                        required
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <PortSelectAutoComplete
                        name='podCode'
                        label={translatedTextsObject.portOfDischarge}
                        keyRef={props.podKeyRef}
                        value={podCodeField.value}
                        required
                        disabled={!newVessel}
                      />
                    </CngGridItem>

                    <CngGridItem xs={4} sm={4}></CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngDateTimeField
                        label={translatedTextsObject.polPlannedEta}
                        name='polEta'
                        disabled={!newVessel}
                        format={SG_DATE_TIME_FORMAT}
                        shouldDisableDate={(date) => {
                          return moment(
                            moment().format(SG_DATE_FORMAT)
                          ).isAfter(moment(date))
                        }}
                        isRequired
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngDateTimeField
                        label={translatedTextsObject.podPlannedEta}
                        name='podEta'
                        disabled={!newVessel}
                        format={SG_DATE_TIME_FORMAT}
                        shouldDisableDate={(date) => {
                          return moment(
                            moment(polEtdField.value).format(SG_DATE_FORMAT)
                          ).isAfter(moment(date))
                        }}
                        additionalOnChange={(event, date) =>
                          onChangePodEta(date)
                        }
                        isRequired
                      />
                    </CngGridItem>

                    <CngGridItem xs={4} sm={4}></CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngDateTimeField
                        label={translatedTextsObject.polPlannedEtd}
                        name='polEtd'
                        disabled={!newVessel}
                        format={SG_DATE_TIME_FORMAT}
                        shouldDisableDate={(date) => {
                          return moment(
                            moment(polEtaField.value).format(SG_DATE_FORMAT)
                          ).isAfter(moment(date))
                        }}
                        additionalOnChange={(event, date) =>
                          onChangePolEtd(date)
                        }
                        isRequired
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngDateTimeField
                        label={translatedTextsObject.podPlannedEtd}
                        name='podEtd'
                        disabled={!newVessel}
                        format={SG_DATE_TIME_FORMAT}
                        shouldDisableDate={(date) => {
                          return moment(
                            moment(podEtaField.value).format(SG_DATE_FORMAT)
                          ).isAfter(moment(date))
                        }}
                        isRequired
                      />
                    </CngGridItem>

                    <CngGridItem xs={4} sm={4}></CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngTextField
                        type='text'
                        name='vesselName'
                        label={translatedTextsObject.vesselName}
                        inputProps={{ maxLength: 35 }}
                        disabled={!newVessel}
                        isRequired
                      />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                      <CngTextField
                        type='text'
                        name='voyageNo'
                        label={translatedTextsObject.voyageNumber}
                        inputProps={{ maxLength: 17 }}
                        disabled={!newVessel}
                        isRequired
                      />
                    </CngGridItem>
                  </Grid>
                </Card>
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='intVoyageNo'
                  label={translatedTextsObject.internationalVoyageNumber}
                  inputProps={{ maxLength: 20 }}
                  disabled={!newVessel}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 100 }}
                  label={translatedTextsObject.loadTerminal}
                  name='portOfLoadingTerminal'
                  disabled={!newVessel}
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  inputProps={{ maxLength: 100 }}
                  label={translatedTextsObject.dischargeTerminal}
                  name='portOfDischargeTerminal'
                  disabled={!newVessel}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={translatedTextsObject.additionalScheduleDetails}
      />

      <VesselSearchDialog
        isDialogOpen={vesselDialog}
        closeDialog={closeDialog}
        showNotification={showNotification}
        booking={props.scheduleData}
      />
    </Card>
  )
}

const AmendScheduleComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default AmendScheduleComponent
