import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import IconButton from '@material-ui/core/IconButton'

const {
  form: {
    field: { CngTextField, CngSelectField, CngDateField, CngCheckboxField  }
  },
  CngGridItem
} = components

const { CngPackageUomAutocompleteField } = components.form.field

const CheckboxGroup = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()

  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(props.checkBoxList)
  })

  return (
    <>
      {options != null && (
        <FormGroup
          row={true}
          className={`cng-filter-criteria--chkbox-container`}
        >
          <ul className={`filter-criteria--all`}>
            {options
              .filter((option) => option.nameOth === 'ALL')
              .map((option, index) => (
                <li>
                  <FormControlLabel
                    key={options.length - 1}
                    control={
                      <CngCheckboxField 
                        disabled={!option.eligible}
                        checked={
                          options.filter((option) => option.checked === true)
                            .length === options.length
                        }
                        indeterminate={
                          options.filter((option) => option.checked === true)
                            .length > 0 &&
                          options.filter((option) => option.checked === true)
                            .length < options.length
                        }
                        onChange={props.onChange(options.length - 1)}
                        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                      />
                    }
                    label={`All ${props.filterName} (${option.bookingCount})`}
                  />
                </li>
              ))}
          </ul>
          <ul className={`filter-criteria--others`}>
            {options
              .filter((option) => option.nameOth !== 'ALL')
              .map((option, index) => (
                <li>
                  <FormControlLabel
                    key={index}
                    control={
                      <CngCheckboxField 
                        value={option.partyId}
                        disabled={!option.eligible}
                        checked={option.checked}
                        onChange={props.onChange(index)}
                      />
                    }
                    label={`${option.nameOth} (${option.bookingCount})`}
                  />
                </li>
              ))}
          </ul>
        </FormGroup>
      )}
    </>
  )
}

export default CheckboxGroup
