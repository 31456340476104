import React, { useContext, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import DGCargoDetailsContext from './DGCargoDetailsContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { Grid } from '@material-ui/core'
import DGCargoDetailsClassIdenComponent from './DGCargoDetailsClassIdenComponent'
import DGCargoDetailsPackageWeightComponent from './DGCargoDetailsPackageWeightComponent'
import DGCargoDetailsEmergencyComponent from './DGCargoDetailsEmergencyComponent'
import DGCargoDetailsHazardousIdentificationComponent from './DGCargoDetailsHazardousIdentificationComponent'
import DGCargoDetailsPhysicalChemicalComponent from './DGCargoDetailsPhysicalChemicalComponent'
import useDGCargoDetailsMakeValidationSchema from 'src/views/freightbooking/newbooking/useDGCargoDetailsMakeValidationSchema'
import ResetButton from 'src/components/button/ResetButton'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm},
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  button: { CngPrimaryButton }
} = components

function DGCargoDetailsForm(props) {
  const dialogContext = useContext(DGCargoDetailsContext)

  const {
    closeDialog,

    form: { isSubmitting },
    currentDGCargoIdx,
    dgCargoField,
    setDGCargoField,
    currentDGDetailsId,
    showLocalCategory
  } = dialogContext

  function onSubmit(data) {
    console.log('submit ')
    console.log(data)
    console.log(currentDGDetailsId)

    const newList = [...dgCargoField]

    if (currentDGDetailsId == null) {
      //add new cargo details in the cargo
      var dgCargo = dgCargoField[currentDGCargoIdx]

      console.log(dgCargo)
      var tempDGDetails = dgCargo.dgDetails == null ? [] : dgCargo.dgDetails

      tempDGDetails.push(data)
      dgCargo.dgDetails = tempDGDetails

      newList[currentDGCargoIdx] = dgCargo
    } else {
      //update existing cargo details

      newList[currentDGCargoIdx].dgDetails[currentDGDetailsId] = data
    }

    console.log(newList)
    setDGCargoField(newList)
    closeDialog()
  }

  const validationSchema = useDGCargoDetailsMakeValidationSchema()
  console.log(dgCargoField)
  console.log(currentDGCargoIdx)
  const currentDGDetails =
    currentDGDetailsId == null
      ? null
      : dgCargoField[currentDGCargoIdx].dgDetails[currentDGDetailsId]
  return (
    
    
    <CngViewForm
    formikProps={{
      initialValues: {  ...initialValues},
      validationSchema: validationSchema,
       onSubmit: onSubmit,
       validateOnChange: false
    }}
    bodySection={
     <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentDGDetails={currentDGDetails}
          showLocalCategory={showLocalCategory}
        />
    }
    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
  />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentDGDetails,
  showLocalCategory
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { setFieldValue, submitForm } = useFormikContext()
  const isEdit = useRef(false)
  const resetForm = () => {
    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      //  console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })
  }

  useEffect(() => {
    console.log(currentDGDetails)
    if (currentDGDetails != null) {
      populateDGCargoFields()
    }
  }, [currentDGDetails])

  function populateDGCargoFields() {
    const dataArr = Object.entries(currentDGDetails)
    dataArr.forEach(([key, val]) => {
      // console.log("key: " + key + " val: " + val)
      if (key === 'dateOfFumigation') {
        val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
      }
      setFieldValue(key, val)
    })

    isEdit.current = true
  }

  return (
    <Box m={1.5}>
      <Box pt={5}>
        <DGCargoDetailsClassIdenComponent.FormBody isEdit={isEdit.current} />
      </Box>

      <Box pt={5}>
        <DGCargoDetailsPackageWeightComponent.FormBody
          isEdit={isEdit.current}
        />
      </Box>

      <Box pt={5}>
        <DGCargoDetailsEmergencyComponent.FormBody isEdit={isEdit.current} />
      </Box>

      <Box pt={5}>
        <DGCargoDetailsHazardousIdentificationComponent.FormBody
          isEdit={isEdit.current}
          currentDGDetails={currentDGDetails}
          showLocalCategory={showLocalCategory}
        />
      </Box>

      <Box pt={5}>
        <DGCargoDetailsPhysicalChemicalComponent.FormBody
          isEdit={isEdit.current}
        />
      </Box>

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <ResetButton onClick={resetForm} disabled={isSubmitting} />
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
             <CngPrimaryButton
              type='button'
              disabled={isSubmitting}
              onClick={submitForm}
              >
                {uiTranslatedTextsObject.apply}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...DGCargoDetailsClassIdenComponent.initialValues,
  ...DGCargoDetailsEmergencyComponent.initialValues,
  ...DGCargoDetailsPackageWeightComponent.initialValues,
  ...DGCargoDetailsHazardousIdentificationComponent.initialValues,
  ...DGCargoDetailsPhysicalChemicalComponent.initialValues
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default DGCargoDetailsForm
