import {
    Box,
    Card,
    Grid,
    Chip,
    Typography
} from '@material-ui/core'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import { components, constants } from 'cng-web-lib'
import LabelValueVertical from './LabelValueVertical'
import ComponentWrapper from './ComponentWrapper'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import Utils from 'src/views/common/utils/Utils'
const {
    CngGridItem
} = components

const {
    CodeMaintenanceType
} = constants

const FormBody = (props) => {
    // const cargoSummaryItem = {
    //     cargoDesc: "cargo description "
    // }

    const cargoList = props.cargo;
    const { showDetails } = props
    const totalCargoWeight = calculateTotalWeight(cargoList)
    // console.log('ViewContainerDetailComponent cargoLists:', cargoList)

    const translatedTextsObject = FreightBookingTranslationText();
    function calculateTotalWeight(list) {
        var totalWeight = 0
        if (list != null && list.length > 0) {
            list.map((m, i) => {
                console.log(m.grossWeight)
                if (!Utils.isEmptyString(m.grossWeight)) {
                    var validWeight = parseFloat(m.grossWeight)

                    totalWeight += validWeight
                }
                //
            })
        }


        return Utils.roundToThree(totalWeight)
    }
    const ViewCargoDetailComponent = () => {
        return (
            cargoList.map((m, idx) => (

                <Grid xs={12} sm={12} key={idx} spacing={2} className="view-card view-card-content">
                    <Card >
                        <Box >
                            <Grid container className='accordian-header'>
                                <CngGridItem xs={6} sm={6}>
                                    <Box p={1} fontSize='large' fontWeight='bolder'>
                                        {translatedTextsObject.cargo + ' ' + (idx + 1)}
                                    </Box>
                                </CngGridItem>
                                {/* {showDetails && m.cargoId > -1 && (
                                    <CngGridItem xs={6} sm={6} className={'alignRight'}>
                                        <Box p={1} fontSize='small'>
                                            {<React.Fragment> {translatedTextsObject.cargoId}:&nbsp;<b>{m.cargoId}</b></React.Fragment>}
                                        </Box>
                                    </CngGridItem>
                                )} */}
                            </Grid>
                            {
                                !showDetails && (<div>

                                    <Grid container xs={12} sm={12} className="view-card-item-content">
                                        <LabelValueVertical label={translatedTextsObject.cargoDesc}
                                            value={m.cargoDesc} />
                                    </Grid>
                                </div>)}
                        </Box>
                        {
                            showDetails && (<div>

                                <Grid container xs={12} sm={12} className="view-card-item-content">
                                    <Grid item xs={4} sm={4}>
                                        <LabelValueVertical label={translatedTextsObject.cargoDesc}
                                            value={m.cargoDesc} />
                                    </Grid>
                                    <Grid item xs={8} sm={8} style={{ paddingRight: 20, marginTop: 16 }}>
                                        <ComponentWrapper>
                                            <Grid container xs={12} sm={12}>
                                                <Grid item xs={4} sm={4}>
                                                    <LabelValueVertical label={translatedTextsObject.hsCode}
                                                        value={m.hsCode} />
                                                </Grid>
                                                <Grid item xs={4} sm={4} alignContent="center">
                                                    <Grid item xs={12} sm={12} >

                                                        <Box className="view-card-content">
                                                            <Grid container justify="space-between">
                                                                <Grid item xs={12} sm={12} >
                                                                    <Box className="view-card-content-label">
                                                                        {translatedTextsObject.packageType}
                                                                    </Box>
                                                                </Grid>
                                                                <Box className="view-card-content-value">
                                                                    <CodeMasterMiscViewField
                                                                        codeType={CodeMaintenanceType.PACKAGE_UOM}
                                                                        code={m.packageType}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4} sm={4} alignContent="flex-end">
                                                    <LabelValueVertical label={translatedTextsObject.quantity}
                                                        value={m.quantity} />
                                                </Grid>
                                            </Grid>
                                        </ComponentWrapper>

                                        <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                                            <Grid item xs={6} sm={6} style={{ paddingRight: 10 }}>
                                                <ComponentWrapper rightConner={<Chip label={translatedTextsObject.cbm} size='small' className='white-chip' />} >
                                                    <LabelValueVertical label={translatedTextsObject.grossVolume}
                                                        value={m.grossVolume} />
                                                    {/* <LabelValueVertical label={translatedTextsObject.netVolume}
                                                        value={m.netVolume} /> */}
                                                </ComponentWrapper>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <ComponentWrapper rightConner={<Chip label={translatedTextsObject.kg} size='small' className='white-chip' />}>
                                                    <LabelValueVertical label={translatedTextsObject.grossWeight}
                                                        value={m.grossWeight} />
                                                    {/* <LabelValueVertical label={translatedTextsObject.netWeight}
                                                        value={m.netWeight} /> */}
                                                </ComponentWrapper>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                                            <Grid item xs={6} sm={6}>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <ComponentWrapper rightConner={<Chip label={translatedTextsObject.fullVgmKg} size='small' className='white-chip' />} >
                                                    <LabelValueVertical label={translatedTextsObject.totalVgmWeight}
                                                        value={m.vgmWeight} />
                                                </ComponentWrapper>
                                            </Grid>
                                        </Grid> */}



                                    </Grid>
                                </Grid>
                            </div>)
                        }
                    </Card>
                </Grid>
            ))



        )
    };


    return (
        <Card>
            <Box className="view-card-header">
                <div> {translatedTextsObject.cargo}</div>
            </Box>
            {cargoList != null && <ViewCargoDetailComponent />}

            <Box >
                <Grid container justify="flex-end" sytle={{ paddingLeft: 10, backgroundColor: "red" }}>
                    <Typography>
                        {translatedTextsObject.totalCargoWeight}: {totalCargoWeight} {translatedTextsObject.kg}
                    </Typography>
                </Grid>

            </Box>
        </Card>
    )
}

const ViewCargoComponent = Object.freeze({
    FormBody: FormBody,
    // initialValues : initialValues
})

export default ViewCargoComponent
