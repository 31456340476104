import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card, CardContent, CardHeader, Divider,
    Grid,
    Typography,
    InputAdornment
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants } from 'cng-web-lib'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    },
    CodeMaintenanceType
} = constants

const FormBody = (props) => {

    const [key, setKey] = useState()

    const {
        isCarrierAmend
    } = props


    const translatedTextsObject = FreightBookingTranslationText()
    const [reeferTypeExpanded] = useState(true)

    function limitDecimalPlaces(event, maxDecimalPlaces) {
        let cVal = event.target.value;
        let dec = cVal.indexOf('.');
        //no decimal places
        if (maxDecimalPlaces == 0) {
            if (event.which == 46) {
                //not allowing decimal places
                event.preventDefault();
            }
        } else {
            //allowing only one time
            if (event.which == 46 && dec != -1) {
                event.preventDefault(); // prevent if there is already a dot
            }
        }

        //prevent exceeding number of decimal places      
        if (dec != -1) {
            //reading the decimal places  count
            let decPlaces = cVal.substring(dec + 1, cVal.length).length;
            //compare it with props value
            if (decPlaces == maxDecimalPlaces) {
                event.preventDefault();
            }
        }
    }

    useEffect(() => {
        setKey(uuid())
    }, [props.isEdit])


    return (
        <Card>
            <Accordion expanded={reeferTypeExpanded}>
                <AccordionSummary aria-controls="reeferComponentContent" id='reeferComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.reeferDetails} />
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='number'
                                    name='equipmentTemp'
                                    label={translatedTextsObject.equipmentTemp}
                                    customType={PLAIN_NUMBER}
                                    inputProps={{ maxLength: 6 }}
                                    isRequired
                                    disabled={isCarrierAmend}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 1)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='tempUom'
                                    label={translatedTextsObject.tempUom}
                                    isRequired
                                    codeType='CAL_REEFER_TEMP_UOM'
                                    disabled={isCarrierAmend}
                                    key={props.tempUomKeyRef}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container>
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='number'
                                    name='equipmentControlledAtmosphereCO2'
                                    label={translatedTextsObject.equipmentControlledAtmosphereCO2}
                                    disabled={isCarrierAmend}
                                    customType={PLAIN_NUMBER}
                                    inputProps={{ maxLength: 3 }}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 0)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    name='equipmentHumidity'
                                    type='number'
                                    label={translatedTextsObject.equipmentHumidity}
                                    disabled={isCarrierAmend}
                                    customType={PLAIN_NUMBER}
                                    InputProps={{
                                        maxLength: 20,
                                        endAdornment: <InputAdornment position='end'>
                                            <div className='cfc-chip'>{translatedTextsObject.percentofMoistureInAir}</div>
                                        </InputAdornment>
                                    }}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 2)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    name='equipmentAirFlow'
                                    type='number'
                                    label={translatedTextsObject.equipmentAirFlow}
                                    disabled={isCarrierAmend}
                                    customType={PLAIN_NUMBER}
                                    InputProps={{
                                        maxLength: 20,
                                        endAdornment: <InputAdornment position='end'>
                                            <div className='cfc-chip'>{translatedTextsObject.cbm}</div>
                                        </InputAdornment>
                                    }}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 2)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>

                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container>
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='number'
                                    name='equipmentControlledAtmosphereN2'
                                    label={translatedTextsObject.equipmentControlledAtmosphereN2}
                                    disabled={isCarrierAmend}
                                    customType={PLAIN_NUMBER}
                                    inputProps={{ maxLength: 3 }}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 0)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <Grid container>
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='number'
                                    name='equipmentControlledAtmosphereO2'
                                    label={translatedTextsObject.equipmentControlledAtmosphereO2}
                                    disabled={isCarrierAmend}
                                    customType={PLAIN_NUMBER}
                                    inputProps={{ maxLength: 3 }}
                                    onKeyPress={(event) => {
                                        limitDecimalPlaces(event, 0)
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionDetails>

                <AccordionDetails>
                    <CngGridItem xs={12} sm={12}>
                        <Card className="inner-card-group">
                            <CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.specialHandling}</Typography>} />
                            <Divider />
                            <CardContent>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngSwitchField
                                            name='superFreezerService'
                                            label={translatedTextsObject.superFreezerService}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngSwitchField
                                            name='gensetRequired'
                                            label={translatedTextsObject.gensetRequired}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngSwitchField
                                            name='inTransitColdSterilization'
                                            label={translatedTextsObject.inTransitColdSterilization}
                                            disabled={isCarrierAmend}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CardContent>

                            <CardContent>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngCodeMasterAutocompleteField
                                            name='ventSetting'
                                            label={translatedTextsObject.ventSetting}
                                            codeType='CAL_REEFER_VENT_SETTING'
                                            disabled={isCarrierAmend}
                                            key={key}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='number'
                                            name='tempVariance'
                                            label={translatedTextsObject.tempVariance}
                                            disabled={isCarrierAmend}
                                            customType={PLAIN_NUMBER}
                                            inputProps={{ maxLength: 5 }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CardContent>

                            <CardContent>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='number'
                                            name='numberOfTempProbes'
                                            label={translatedTextsObject.numberOfTempProbes}
                                            disabled={isCarrierAmend}
                                            customType={PLAIN_NUMBER}
                                            inputProps={{ maxLength: 10 }}
                                            onKeyPress={(event) => {
                                                limitDecimalPlaces(event, 0)
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='numberOfUSDProbes'
                                            label={translatedTextsObject.numberOfUSDProbes}
                                            disabled={isCarrierAmend}
                                            inputProps={{ maxLength: 512 }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CardContent>

                            <CardContent>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={12} sm={12}>
                                        <CngTextField
                                            type='text'
                                            name='equipmentComments'
                                            label={translatedTextsObject.equipmentComments}
                                            disabled={isCarrierAmend}
                                            multiline
                                            rows={4}
                                            inputProps={{ maxLength: 1024 }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CngGridItem>
                </AccordionDetails>
            </Accordion>
        </Card>
    )
}

const initialValues = {
    equipmentTemp: undefined,
    tempUom: CalistaConstant.REEFER_TEMP_CEL,
    superFreezerService: false,
    gensetRequired: false,
    inTransitColdSterilization: false,
    equipmentControlledAtmosphereCO2: undefined,
    equipmentControlledAtmosphereN2: undefined,
    equipmentControlledAtmosphereO2: undefined,
    ventSetting: undefined,
    equipmentAirFlow: undefined,
    equipmentHumidity: undefined,
    numberOfTempProbes: undefined,
    numberOfUSDProbes: undefined,
    tempVariance: undefined,
    equipmentComments: undefined
}

const resetValues = {
    equipmentTemp: '',
    tempUom: CalistaConstant.REEFER_TEMP_CEL,
    superFreezerService: false,
    gensetRequired: false,
    inTransitColdSterilization: false,
    equipmentControlledAtmosphereCO2: '',
    equipmentControlledAtmosphereN2: '',
    equipmentControlledAtmosphereO2: '',
    ventSetting: '',
    equipmentAirFlow: '',
    equipmentHumidity: '',
    numberOfTempProbes: '',
    numberOfUSDProbes: '',
    tempVariance: '',
    equipmentComments: ''
}

const ReeferComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues,
    resetValues: resetValues
})

export default ReeferComponent