import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, useServices, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import DGPkgUOMAutocompleteField from 'src/components/autocomplete/DGPkgUOMAutocompleteField'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngDateField,
            CngSelectField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({

    flashPoint: null,
    flashPointUom: "",
    boilingPoint: null,
    boilingPointUom: "",
    controlTemperature: null,
    controlTemperatureUom: "",
    emergencyTemperature: null,
    emergencyTemperatureUom: "",
    sadt: null,
    sadtUom: "",



})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [packWeightDetailsExpanded, setPackWeightDetailsExpanded] = useState(false)

    const [, ,] = useField('flashPoint')
    const [, ,] = useField('flashPointUom')
    const [, ,] = useField('boilingPoint')
    const [, ,] = useField('boilingPointUom')
    const [, ,] = useField('controlTemperature')
    const [, ,] = useField('controlTemperatureUom')
    const [, ,] = useField('emergencyTemperature')
    const [, ,] = useField('emergencyTemperatureUom')
    const [, ,] = useField('sadt')
    const [, ,] = useField('sadtUom')


    const { setFieldValue } = useFormikContext()
    const { fetchRecords } = useServices()


    const handlePackWeightDetailsExpand = () => {
        setPackWeightDetailsExpanded(!packWeightDetailsExpanded)
    }

    // const uomItems = [
    //     // { text: '°C', value: 'CEL' },
    //     // { text: '°F', value: 'FAH' },

    // ]

    const [uomItems, setUomItems] = useState([])


    useEffect(() => {
        CodeMasterApi.fetchCodeMaster(fetchRecords, 'CAL_DG_TEMP_UOM', false, onLoadCodeMasterSuccess)
    }, [props])

    // useFetchCodeMaster(props.codeType, false, onLoadCodeMasterSuccess)

    function onLoadCodeMasterSuccess(data) {

        console.log("onLoadCodeMasterSuccess")
        console.log(data)
        let optionList = []

        if (data.length == 0) {
            console.log("null")
        } else {
            // setDocTypeOption(data)

            data.map((singleOption => {
                var detailedOption = {
                    value: singleOption.code,
                    text: singleOption.descriptionEn
                }
                optionList.push(detailedOption)

            }))

            console.log(optionList)
            setUomItems(optionList)


        }
    }



    // const repNameRef = useRef(uuid())

    // useEffect(() => {
    //     repNameRef.current = uuid()

    // }, [])

    return (
        <Card>
            <Accordion expanded={packWeightDetailsExpanded}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>

                    <AccordionHeaderComponent title={translatedTextsObject.physicalChemicalProps} hideMandatory />


                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={3} sm={3}>
                            <CngTextField
                                type='number'
                                name='flashPoint'
                                inputProps={{ maxLength: 3 }}
                                label={translatedTextsObject.flashpoint}
                                customType={PLAIN_NUMBER}
                            />

                        </CngGridItem>
                        <CngGridItem xs={1} sm={1}>
                            <CngSelectField
                                name='flashPointUom'
                                defaultValue="CEL"
                                items={uomItems}
                            />
                        </CngGridItem>

                        <CngGridItem xs={3} sm={3}>
                            <CngTextField
                                type='number'
                                name='boilingPoint'
                                inputProps={{ maxLength: 3 }}
                                label={translatedTextsObject.boilingPoint}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={1} sm={1}>
                            <CngSelectField
                                name='boilingPointUom'
                                defaultValue="CEL"
                                items={uomItems}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={3} sm={3}>
                            <CngTextField
                                type='number'
                                name='sadt'
                                inputProps={{ maxLength: 10 }}
                                label={translatedTextsObject.sadt}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={1} sm={1}>
                            <CngSelectField
                                name='sadtUom'
                                defaultValue="CEL"
                                items={uomItems}
                            />
                        </CngGridItem>
                        <CngGridItem xs={3} sm={3}>
                            <CngTextField
                                type='number'
                                name='controlTemperature'
                                inputProps={{ maxLength: 10 }}
                                label={translatedTextsObject.controlTemp}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={1} sm={1}>
                            <CngSelectField
                                name='controlTemperatureUom'
                                defaultValue="CEL"
                                items={uomItems}
                            />
                        </CngGridItem>

                        <CngGridItem xs={8} sm={8}></CngGridItem>
                        <CngGridItem xs={3} sm={3}>
                            <CngTextField
                                type='number'
                                name='emergencyTemperature'
                                inputProps={{ maxLength: 10 }}
                                label={translatedTextsObject.emergencyTemp}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={1} sm={1}>
                            <CngSelectField
                                name='emergencyTemperatureUom'
                                defaultValue="CEL"
                                items={uomItems}
                            />
                        </CngGridItem>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handlePackWeightDetailsExpand}
                expanded={packWeightDetailsExpanded}
                footerText={translatedTextsObject.additionalPhysiChemiDetails}
            />
        </Card>
    )
}

const DGCargoDetailsPhysicalChemicalComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default DGCargoDetailsPhysicalChemicalComponent