import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import BookingActionMenuItem from './BookingActionMenuItem'
import Utils from '../shared/Utils'
import pathMap from 'src/paths/PathMap_FreightBooking'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import ActionMenuBookingList from './ActionMenuBookingList'
import ActionMenuBookingDetail from './ActionMenuBookingDetail'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ManageBookingButtonComponent from './ManageBookingButtonComponent'
import CommonUtils from 'src/views/common/utils/Utils'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
    table: { useDefaultNotification }
} = components

function CarrierBookingActionMenu(props) {

    const history = useHistory()

    const { booking, bookingStatus, page, refreshAction, onClickApproveBooking, onClickDeclineBooking } = props
    const bookStatus = bookingStatus || booking.bookingStatus || booking.bookStatus || ''

    const [actions, setActions] = useState([])
    //let actionsH = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).carrierActionsH : []
    const [actionsH, setActionsH] = useState([])


    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    const { createRecord } = useServices()
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [confirmAction, setConfirmAction] = useState("")


    const { showNotification } = props

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)


    useEffect(() => {

        let result = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).carrierActions : []
        let resultH = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).carrierActionsH : []
        let bookStatusString = bookStatus != null ? bookStatus.toString() : ""

        console.log("bookStatusString:" + bookStatusString)
        console.log(bookStatusString == CalistaConstant.BOOKING_STATUS_CANCELLED && booking.carrierClosed)
        if (([CalistaConstant.BOOKING_STATUS_CANCELLED, CalistaConstant.BOOKING_STATUS_REJECTED].includes(bookStatusString)
        ) && booking.carrierClosed) {

            result = []
        }





        setActions(result)
        setActionsH(resultH)

    }, [props])



    const confirmDialog = () => {

        setConfirmDialogOpen(false)//close the dialog first
        // switch (confirmAction) {
        // case CANCEL_ACTION:
        //     proceedCancelBooking()
        //     break

        // case ARCHIVE_ACTION:
        proceedArchiveBooking()
        // break

        // case DELETE_ACTION:
        //     proceedDeleteBooking()
        //     break

        // }




    }

    function redirectToManageBooking() {

        if (page === Utils.Page.BookingDetail) {
            //if action is done from details page, need to redirect the page to booking list
            gotoManageBooking()
        }
        else {
            //if action is done from menu list, then just refresh the list
            refreshAction()
        }
    }

    const gotoManageBooking = () => {
        history.push({
            pathname: pathMap.MANAGE_BOOKING
        })
    }


    function approveBooking() {

        onClickApproveBooking()
    }

    function rejectBooking() {

        onClickDeclineBooking()
    }

    function onError(error) {

        showErrorNotification(CommonUtils.getErrorMessage(error))
    }


    const archiveBooking = () => {

        setConfirmDialogOpen(true)
        setConfirmAction("archive")
    }



    const proceedArchiveBooking = () => {
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response.errorMessages)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.archivedSuccess} `)
                redirectToManageBooking()
            }
        }




        createRecord.execute(
            FreightBookingApiUrls.ARCHIVE_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )
    }

    const editBooking = (prevPage) => event => {
        history.push({
            pathname: pathMap.MANAGE_BOOKING_AMEND,
            state: { booking: booking, prevPage: props.page }
        })

    }

    const amendBooking = (prevPage) => event => {
        editBooking() //for carrier side, "edit" in booking list = "amend" in booking review page
    }







    const eventHandlers = {

        approveBooking,
        rejectBooking,
        amendBooking,
        archiveBooking,
        editBooking,
    }



    return (
        <div>
            {/* the horizonal button layout in review page will not use from this action menu for now */}
            {
                page === Utils.Page.BookingDetail && <ManageBookingButtonComponent actions={actionsH} booking={booking} isCarrier
                    eventHandlers={eventHandlers} />
            }
            {
                page === Utils.Page.BookingList && <ActionMenuBookingList actions={actions} booking={booking} page={page} isCarrier
                    eventHandlers={eventHandlers} />

            }

            <ConfirmDialog
                isConfirmDialogOpen={confirmDialogOpen}
                closeDialog={() => setConfirmDialogOpen(false)}
                confirmDialog={confirmDialog}
                content={translatedTextsObject.dialogChangeStatusGeneralContent}
                okMsg={uiTranslatedTextsObject.ok}
                cancelMsg={uiTranslatedTextsObject.cancel}
                title={translatedTextsObject.dialogCancelBookingTitle}
            />
        </div>
    )
}


export default CarrierBookingActionMenu