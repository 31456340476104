import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography,
    FormControlLabel
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import DGPkgUOMAutocompleteField from 'src/components/autocomplete/DGPkgUOMAutocompleteField'
import Utils from 'src/views/common/utils/Utils'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngDateField,
            CngDateTimeField,
            CngSelectField,
            CngCodeMasterAutocompleteField,
            CngCheckboxField
        },
    },
    CngGridItem,
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const initialValues = Object.freeze({

    marinePollutantStatus: "",
    radioactiveMeasurement: "",
    acidConcentration: "",
    tremCardNumber: "",
    regulatoryInfo: "",
    radioactiveGoodsInfo: "",
    placardInformation: "",
    poisonInhalation: "",
    aggregationState: "",
    fumigationType: "",
    amountOfFumigation: null,
    dateOfFumigation: null,
    localCategory: "",
    inhalantHazard: false,
    emptyUncleanedReceptable: false,
    transportDgInLimitedQty: false,
    reportableQty: false,
    weightKgs: false,
    weightLbs: false,
    netExplosiveContent: false,


})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [packWeightDetailsExpanded, setPackWeightDetailsExpanded] = useState(false)

    const [, ,] = useField('marinePollutantStatus')
    const [, ,] = useField('radioactiveMeasurement')
    const [, ,] = useField('acidConcentration')
    const [, ,] = useField('tremCardNumber')
    const [, ,] = useField('regulatoryInfo')
    const [, ,] = useField('radioactiveGoodsInfo')
    const [, ,] = useField('placardInformation')
    const [, ,] = useField('poisonInhalation')
    const [, ,] = useField('aggregationState')
    const [, ,] = useField('acidConcentration')
    const [, ,] = useField('fumigationType')
    const [, ,] = useField('amountOfFumigation')
    const [, ,] = useField('dateOfFumigation')
    const [, ,] = useField('localCategory')
    const [, ,] = useField('inhalantHazard')
    const { setFieldValue } = useFormikContext()
    const [key, setKey] = useState()

    const [state, setState] = useState({
        inhalantHazard: false,
        emptyUncleanedReceptable: false,
        transportDgInLimitedQty: false,
        reportableQty: false,
        weightKgs: false,
        weightLbs: false,
        netExplosiveContent: false,
    });


    const handlePackWeightDetailsExpand = () => {
        setPackWeightDetailsExpanded(!packWeightDetailsExpanded)
    }


    const handleChange = (event) => {
        console.log(event.target.name)
        console.log(event.target.checked)
        setFieldValue(event.target.name, event.target.checked)
        setState({ ...state, [event.target.name]: event.target.checked });
    };



    // const repNameRef = useRef(uuid())

    useEffect(() => {
        setKey(uuid())

        if (props.currentDGDetails != null) {
            //populate the boolean value to state
            const newState = {}
            const dataArr = Object.entries(props.currentDGDetails);
            dataArr.map(([elem, val]) => (

                newState[elem] = val
            ));

            setState(newState)
            console.log(newState)


        }

    }, [props.isEdit])

    return (
        <Card>
            <Accordion expanded={packWeightDetailsExpanded}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                    {/* <Grid container> */}
                    <AccordionHeaderComponent title={translatedTextsObject.hazardIdentification} hideMandatory />

                    {/* </Grid> */}
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='marinePollutantStatus'
                                label={translatedTextsObject.marinePollutantStatus}
                                codeType='CAL_DG_MARINE'
                                key={key}
                            />

                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='radioactiveMeasurement'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.radioactiveMeasurement}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='acidConcentration'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.acidConcentration}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                name='tremCardNumber'
                                inputProps={{ maxLength: 100 }}
                                label={translatedTextsObject.tremCardNo}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <CngTextField
                                type='text'
                                name='regulatoryInfo'
                                inputProps={{ maxLength: 1024 }}
                                multiline
                                rows={3}
                                label={translatedTextsObject.regulatoryInfo}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <CngTextField
                                type='text'
                                name='radioactiveGoodsInfo'
                                inputProps={{ maxLength: 1024 }}
                                multiline
                                rows={3}
                                label={translatedTextsObject.radioactiveGoodsInfo}

                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <CngTextField
                                type='text'
                                name='placardInformation'
                                inputProps={{ maxLength: 1024 }}
                                multiline
                                rows={3}
                                label={translatedTextsObject.placardInfo}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <h3>{translatedTextsObject.additionalHazmatSettings}<br /></h3>

                            <FormControlLabel
                                control={<CngCheckboxField name="inhalantHazard" checked={state.inhalantHazard} onChange={handleChange} />}
                                label={translatedTextsObject.inhalantHazard}
                            /><br />

                            <FormControlLabel
                                control={<CngCheckboxField name="emptyUncleanedReceptable" checked={state.emptyUncleanedReceptable} onChange={handleChange} />}
                                label={translatedTextsObject.emptyUncleanedReceptacle}
                            /><br />
                            <FormControlLabel
                                control={<CngCheckboxField name="transportDgInLimitedQty" checked={state.transportDgInLimitedQty} onChange={handleChange} />}
                                label={translatedTextsObject.transportOfDangerousGoodsInLimitedQuantities}
                            /><br />


                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>


                            <CngCodeMasterAutocompleteField
                                name='poisonInhalation'
                                label={translatedTextsObject.poisonInhalationHazardZone}
                                codeType='CAL_DG_PSN_INH_HZD_ZN'
                                key={key}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>

                            <CngCodeMasterAutocompleteField
                                name='aggregationState'
                                label={translatedTextsObject.aggregationState}
                                codeType='CAL_DG_AGGRE'
                                key={key}
                            />

                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <FormControlLabel
                                control={<CngCheckboxField name="reportableQty" onChange={handleChange} checked={state.reportableQty} />}
                                label={translatedTextsObject.reportableQuantity}
                            /><br />
                            <FormControlLabel
                                control={<CngCheckboxField name="weightKgs" onChange={handleChange} checked={state.weightKgs} />}
                                label={translatedTextsObject.weightKgs}
                            /><br />

                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <FormControlLabel
                                control={<CngCheckboxField name="weightLbs" onChange={handleChange} checked={state.weightLbs} />}
                                label={translatedTextsObject.weightLbs}
                            /><br />
                            <FormControlLabel
                                control={<CngCheckboxField name="netExplosiveContent" onChange={handleChange} checked={state.netExplosiveContent} />}
                                label={translatedTextsObject.netExplosiveContent}
                            /><br />

                        </CngGridItem>


                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                name='fumigationType'
                                inputProps={{ maxLength: 50 }}
                                label={translatedTextsObject.fumigationType}
                            />


                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='amountOfFumigation'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.amountOfFumigation}
                                customType={PLAIN_NUMBER}
                            />

                        </CngGridItem>


                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>

                            <CngDateTimeField
                                label={translatedTextsObject.dateOfFumigation}
                                name='dateOfFumigation'
                                format={Utils.UI_FORMAT_DATE_TIME}
                            />
                        </CngGridItem>


                        {props.showLocalCategory ? (<CngGridItem xs={4} sm={4} >

                            <CngCodeMasterAutocompleteField
                                name='localCategory'
                                label={translatedTextsObject.localCategory}
                                codeType='CAL_DG_MPA_CLASS'
                                key={key}
                            />
                        </CngGridItem>)
                            :
                            (<CngGridItem xs={4} sm={4}></CngGridItem>)}


                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <CngTextField
                                type='text'
                                name='generalComments'
                                inputProps={{ maxLength: 1024 }}
                                multiline
                                rows={3}
                                label={translatedTextsObject.generalComments} />
                        </CngGridItem>


                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handlePackWeightDetailsExpand}
                expanded={packWeightDetailsExpanded}
                footerText={translatedTextsObject.additionalHazardIdenDetails}
            />
        </Card>
    )
}

const DGCargoDetailsHazardousIdentificationComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default DGCargoDetailsHazardousIdentificationComponent