import React, { useContext, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ReeferDetailsContext from './ReeferDetailsContext.js'
import { Grid } from '@material-ui/core'
import ReeferComponent from '../components/ReeferComponent'
import ResetButton from 'src/components/button/ResetButton'
import { components } from 'cng-web-lib'
import ReeferValidationSchema from 'src/views/freightbooking/shared/validation/ReeferValidationSchema'
import { v4 as uuid } from 'uuid'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  },
  button: { CngPrimaryButton }
} = components

function ReeferDetailsForm(props) {
  const dialogContext = useContext(ReeferDetailsContext)

  const {
    closeDialog,
    form: { isSubmitting },
    currentContainerIdx,
    containerList,
    setContainerField,
    showLocalCategory,
    isCarrierAmend
  } = dialogContext

  function onSubmit(data) {
    const newList = [...containerList]

    if (currentContainerIdx != null) {
      //add new reefer details in the selected reefer/tank container
      let containerItem = containerList[currentContainerIdx]
      containerItem.equipmentTemp = data.equipmentTemp
      containerItem.tempUom = data.tempUom
      containerItem.superFreezerService = data.superFreezerService
      containerItem.gensetRequired = data.gensetRequired
      containerItem.inTransitColdSterilization = data.inTransitColdSterilization
      containerItem.equipmentControlledAtmosphereCO2 = data.equipmentControlledAtmosphereCO2
      containerItem.equipmentControlledAtmosphereN2 = data.equipmentControlledAtmosphereN2
      containerItem.equipmentControlledAtmosphereO2 = data.equipmentControlledAtmosphereO2
      containerItem.ventSetting = data.ventSetting
      containerItem.equipmentAirFlow = data.equipmentAirFlow
      containerItem.equipmentHumidity = data.equipmentHumidity
      containerItem.numberOfTempProbes = data.numberOfTempProbes
      containerItem.numberOfUSDProbes = data.numberOfUSDProbes
      containerItem.tempVariance = data.tempVariance
      containerItem.equipmentComments = data.equipmentComments

    }
    setContainerField(newList)
    closeDialog()
  }


  const validationSchema = ReeferValidationSchema()
  const currentReeferDetails = containerList[currentContainerIdx]

  return (


    <CngViewForm
      formikProps={{
        initialValues: { ...initialValues },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          currentContainerIdx={currentContainerIdx}
          isSubmitting={isSubmitting}
          currentReeferDetails={currentReeferDetails}
          showLocalCategory={showLocalCategory}
          isCarrierAmend={isCarrierAmend}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  currentContainerIdx,
  isSubmitting,
  currentReeferDetails,
  showLocalCategory,
  isCarrierAmend
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const { setFieldValue, submitForm } = useFormikContext()
  const isEdit = useRef(false)
  const tempUomKeyRef = useRef(uuid())

  const resetForm = () => {
    const dataArr = Object.entries(RESET_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
    tempUomKeyRef.current = uuid()
  }

  //to populate data when coming back from Review screen
  useEffect(() => {
    const dataArr = Object.entries(currentReeferDetails)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
      if (key === "tempUom") {
        //it seems when amending a container from non-reefer to reefer, the temperature UOM value is not defaulted to CEL. 
        //Hence explicitly setting it here
        if (val == null || val == undefined) {
          setFieldValue("tempUom", "CEL")
        }
      }
    })
    tempUomKeyRef.current = uuid()
  }, [currentReeferDetails])

  return (
    <Box m={1.5}>
      <Box >
        <ReeferComponent.FormBody
          currentContainerIdx={currentContainerIdx}
          currentReeferDetails={currentReeferDetails}
          isEdit={isEdit.current}
          isCarrierAmend={isCarrierAmend}
          tempUomKeyRef={tempUomKeyRef.current}
        />
      </Box>

      {!isCarrierAmend ? (
        < Box display='flex' mt={2}>
          <Grid xs={6} sm={6} justify='flex-start'>
            <ResetButton onClick={resetForm} disabled={isSubmitting} />
          </Grid>

          <Grid container xs={6} sm={6} justify='flex-end'>
            <Box pr={2}>
              <CancelButton onClick={closeDialog} disabled={isSubmitting} />
            </Box>
            <Box>
              <CngPrimaryButton
                type='button'
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {uiTranslatedTextsObject.apply}
              </CngPrimaryButton>
            </Box>
          </Grid>
        </Box>
      )
        :
        (
          <Box display='flex' flexDirection={'row-reverse'} mt={2}>
            <Box mr={1}>
              <CngPrimaryButton onClick={closeDialog}>
                {uiTranslatedTextsObject.close}
              </CngPrimaryButton>
            </Box>
          </Box>
        )
      }
    </Box >
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ReeferComponent.initialValues
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

const RESET_INITIAL_VALUES = Object.freeze({
  ...ReeferComponent.resetValues
})

export default ReeferDetailsForm
