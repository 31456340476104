import { Box, Button, Grid } from '@material-ui/core'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'

function SubmittedBookingButtonComponent(props){

    const fbTranslatedTextsObject = FreightBookingTranslationText()
    
    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid container xs={12} sm={12} justify="flex-end" spacing={2}>
                    <Box>
                        <Button variant="contained" 
                            className="button-blue originalText" 
                            onClick={() => { props.onMyBooking() } }
                        >
                            {fbTranslatedTextsObject.myBooking}
                        </Button>
                    </Box>
                </Grid>                
            </Grid>
        </Box>
    )
}

export default SubmittedBookingButtonComponent