import * as CalistaConstant from 'src/constants/CalistaConstant'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'

const BookingStatusDescComponent = (props) => {
    const {bookingStatus} = props
    const translatedTextsObject = FreightBookingTranslationText()

    if(bookingStatus == CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL){
        return translatedTextsObject.bookingSubmitted
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_APPROVED){
        return translatedTextsObject.bookingApproved
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_REJECTED){
        return translatedTextsObject.bookingRejected
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_CANCELLED){
        return translatedTextsObject.cancelledInfo
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_CLOSED){
        return translatedTextsObject.closedInfo
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED){
        return translatedTextsObject.bookingAmended
    }else{
        return null
    }
}

export default BookingStatusDescComponent
