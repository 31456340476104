import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined'
import ViewSiIcon from '@material-ui/icons/VisibilityOutlined'
import CreateSiIcon from '@material-ui/icons/OpenInNew'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'
import BookingActionMenuItem from './BookingActionMenuItem'
import Utils from '../shared/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import PrintIcon from '@material-ui/icons/Print'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

const copyIcon = <FileCopyIcon fontSize='small' />
const cancelIcon = <CancelIcon fontSize='small' />
const deleteIcon = <DeleteIcon fontSize='small' />
const amendIcon = <EditIcon fontSize='small' />
const archiveIcon = <ArchiveIcon fontSize='small' />
const viewSiIcon = <ViewSiIcon fontSize='small' />
const createSiIcon = <CreateSiIcon fontSize='small' />
const trackContainersIcon = <FontAwesomeIcon icon={faRoute} fontSize='small' />
const printIcon = <PrintIcon fontSize='small' />
const surrenderBLIcon = <EventAvailableIcon fontSize='small' />

const ActionMenuBookingList = (props) => {
  const { actions, eventHandlers, booking, page, isCarrier, isCreateSI } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  console.log(actions)

  return (
    <Box>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={'moreMenuBtn'}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      {actions.length > 0 && (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actions.map((action, idx) => {
            switch (action) {
              //adding actions in SI module
              case SIUtils.Action.printbl:
                return (
                  <BookingActionMenuItem
                    icon={printIcon}
                    action={eventHandlers.printBL(page)}
                    label='Print B/L'
                  />
                )

              case SIUtils.Action.surrenderbl:
                return (
                  <BookingActionMenuItem
                    icon={surrenderBLIcon}
                    action={eventHandlers.surrenderBL(page)}
                    label='Surrender B/L'
                  />
                )

              //common actions in Bk and SI module

              case Utils.Action.copy:
                return (
                  <BookingActionMenuItem
                    icon={copyIcon}
                    label='Copy'
                    action={eventHandlers.copyBooking}
                  />
                )
              case Utils.Action.cancel:
                return (
                  <BookingActionMenuItem
                    icon={cancelIcon}
                    action={eventHandlers.cancelBooking}
                    label='Cancel'
                  />
                )
              case Utils.Action.delete:
                return (
                  <BookingActionMenuItem
                    icon={deleteIcon}
                    action={eventHandlers.deleteBooking}
                    label='Delete'
                  />
                )
              case Utils.Action.amend:
                return !booking.hazardous ? (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.amendBooking(page)}
                    label='Amend'
                  />
                ) : (
                  <Box pr={2}></Box>
                )
              case Utils.Action.edit:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.editBooking(page)}
                    label='Edit'
                  />
                )
              case Utils.Action.archive:
                return (
                  isCarrier ? !booking.carrierClosed : !booking.closed
                ) ? (
                  <BookingActionMenuItem
                    icon={archiveIcon}
                    action={eventHandlers.archiveBooking}
                    label='Archive'
                  />
                ) : (
                  <Box pr={2}></Box>
                )
              case Utils.Action.viewSI:
                return props.menuCodeList &&
                  props.menuCodeList.includes('CLOGS_SI_MY') ? (
                  <BookingActionMenuItem
                    icon={viewSiIcon}
                    action={eventHandlers.viewSI}
                    label='View SI'
                  />
                ) : (
                  <Box pr={2}></Box>
                )
              case Utils.Action.createSI:
                return (props.menuCodeList &&
                  props.menuCodeList.includes('CLOGS_SI_NEW')) ||
                  isCreateSI ? (
                  <BookingActionMenuItem
                    icon={createSiIcon}
                    action={eventHandlers.createSI(page)}
                    label='Create SI'
                  />
                ) : (
                  <Box pr={2}></Box>
                )
              // case Utils.Action.trackContainers:
              //   return <BookingActionMenuItem icon={trackContainersIcon} action={eventHandlers.trackContainers} label="Track Containers" />

              //adding actions in SI module
              case SIUtils.Action.manage:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.manageSI(page)}
                    label='Manage'
                  />
                )

              case SIUtils.Action.update:
                return (
                  <BookingActionMenuItem
                    icon={amendIcon}
                    action={eventHandlers.updateSI(page)}
                    label='Update'
                  />
                )

              default:
                return <div></div>
            }
          })}
        </Menu>
      )}
    </Box>
  )
}

export default ActionMenuBookingList
