import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'
import { faBiohazard } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngSelectField, CngSwitchField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  shipmentType: CalistaConstant.FULL_CONTAINER_LOAD,
  hazardous: false
  // forwardHubScheme: ""
})

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const [bookingTypeExpanded, setBookingTypeExpanded] = useState(false)
  const [shipmentTypeField, , { setValue: setShipmentTypeField }] =
    useField('shipmentType')

  const handleBookingExpand = () => {
    setBookingTypeExpanded(!bookingTypeExpanded)
  }

  const [shipmentKey, setShipmentKey] = useState()

  useEffect(() => {
    console.log(shipmentTypeField)
    setShipmentKey(uuid())
  }, [props.isEdit])

  return (
    <Card>
      <Accordion expanded={bookingTypeExpanded}>
        <AccordionSummary
          aria-controls='bookingComponentContent'
          id='bookingComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent
              title={translatedTextsObject.bookingType}
            />
            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
              <CngGridItem xs={4} sm={4}>
                <CngCodeMasterAutocompleteField
                  name='shipmentType'
                  label={translatedTextsObject.shipmentType}
                  codeType='CAL_CONT_STATUS'
                  key={shipmentKey}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}></CngGridItem>
              <CngGridItem xs={2} sm={2}>
                <FontAwesomeIcon icon={faBiohazard} color='blue' />
                &nbsp;{translatedTextsObject.hazardous}
              </CngGridItem>
              <CngGridItem xs={2} sm={2}>
                <CngSwitchField
                  name='hazardous'
                  onChange={props.onDGchange}
                  // label={translatedTextsObject.hazardous + ' *'}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          {/* forwardHub not ready yet */}
          <Grid container xs={12} sm={12} spacing={2}>
            <CngGridItem xs={4} sm={4}>
              <CngSelectField
                disabled
                name='forwardHubScheme'
                label={translatedTextsObject.forwardHubScheme}
              />
            </CngGridItem>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleBookingExpand}
        expanded={bookingTypeExpanded}
        footerText={translatedTextsObject.additionalBookingTypeDetails}
      />
    </Card>
  )
}

const BookingTypeComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default BookingTypeComponent
