import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import Paper from '@material-ui/core/Paper'
import LabelValueVertical from './LabelValueVertical'
import CheckIcon from '@material-ui/icons/Check'

const { CngGridItem } = components

const { CodeMaintenanceType } = constants

const ViewCheckBoxGroupComponent = (props) => {
  // const translatedTextsObject = FreightBookingTranslationText()
  const { cardStyle } = props
  const checkBoxGroup = props.checkBoxGroup
  const headerLabel = props.headerLabel
  const [showGroup, setShowGroup] = useState(true)

  let localCardStyle = cardStyle
    ? { ...cardStyle }
    : { paddingTop: 10, paddingBottom: 10, backgroundColor: '#8996AF0F' }

  useEffect(() => {
    if (checkBoxGroup == null || checkBoxGroup.length == 0) {
      setShowGroup(false)
    } else {
      //if in the check box group all the value is false, then no need to show the component at all?
      let tempShowGroup = checkBoxGroup.every(
        (checkBox) => checkBox.checked === false
      )
      console.log(tempShowGroup)
      setShowGroup(!tempShowGroup)
    }
  }, [])

  return (
    showGroup && (
      <Grid item xs={12} sm={12}>
        <h4>{headerLabel}</h4>
        <br></br>
        <Card
          style={localCardStyle}
          // style={{
          //   paddingTop: 10,
          //   paddingBottom: 10,
          //   backgroundColor: '#8996AF0F'
          // }}
        >
          <Grid container xs={12} sm={12} spacing={2}>
            {checkBoxGroup.map(
              (elem, i) =>
                elem.checked && (
                  <CngGridItem>
                    <p>
                      <CheckIcon color='secondary' />
                      {elem.label}
                    </p>
                  </CngGridItem>
                )
            )}
          </Grid>
        </Card>
      </Grid>
    )
  )
}

export default ViewCheckBoxGroupComponent
