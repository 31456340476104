import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Card, Chip, Grid
} from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useState } from 'react'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailDualColumnContainerType from 'src/components/auditTrail/AuditTrailDualColumnContainerType'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import Utils from 'src/views/common/utils/Utils'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import ComponentWrapper from './ComponentWrapper'

const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const CHANGE = '_changes_'
const ADDED = 'added'
const REMOVED = 'removed'
const REEFER = 'reefer'

const FIELDS_CONTAINER = ['contHeight', 'contIsoType', 'contQty', 'contSize', 'contStatus', 'contType', 'shpOwned']

const FIELDS_REEFER = ['nonActiveReefer', 'equipmentTemp', 'tempUom', 'superFreezerService', 'gensetRequired', 'inTransitColdSterilization',
    'numberOfTempProbes', 'numberOfUSDProbes', 'tempVariance', 'ventSetting', 'equipmentAirFlow',
    'equipmentControlledAtmosphereCO2', 'equipmentControlledAtmosphereN2', 'equipmentControlledAtmosphereO2', 'equipmentHumidity', 'equipmentComments']

const AuditTrailContainer = (props) => {
    const { containers, codeMasterList, containerTypes } = props
    const tranObj = FreightBookingTranslationText()
    const [scheduleExpanded, setScheduleExpanded] = useState(false)


    const handleScheduleExpand = () => {
        setScheduleExpanded(!scheduleExpanded);
    };
    const numOfAdded = containers && containers.added ? containers.added.length : 0
    const numOfRemoved = containers && containers.removed ? containers.removed.length : 0

    const addContainer = containers && containers.added ? containers.added.map(a => a) : []
    const removeContainer = containers && containers.removed ? containers.removed.map(r => r) : []
    const updateContainer = containers && containers.updated ? containers.updated.map(u => u) : []

    const allContainer = [...addContainer, ...removeContainer, ...updateContainer].sort((c1, c2) => c1.id - c2.id)
    let containerStatus = '';

    const ContainerFields = ({ container, index, ...props }) => {
        const {
            added,
            removed,
            updated,
        } = props
        let className = 'white-chip'
        const NO_VALUE = 'No values'
        const headers = Utils.getAuditTrailDiffs(container, ['seqNo'])
        let newHeader = Utils.isNotEmpty(headers.valueNew[0])
            ? headers.valueNew[0]
            : NO_VALUE
        let oldHeader = Utils.isNotEmpty(headers.valueOld[0])
            ? headers.valueOld[0]
            : NO_VALUE
        if (added) {
            oldHeader = NO_VALUE
            newHeader = tranObj.container + " " + newHeader
            containerStatus = tranObj.added;
            className = 'success-chip'
        }
        if (removed) {
            containerStatus = tranObj.deleted
            oldHeader = tranObj.container + " " + oldHeader
            newHeader = NO_VALUE
            className = 'danger-chip'
        }
        if (updated) {
            oldHeader = tranObj.container + " " + oldHeader
            newHeader = tranObj.container + " " + newHeader
            containerStatus = tranObj.updated
            className = 'primary-chip'

            const valueQty = Utils.getAuditTrailDiffs(container, ['contQty'])
            const valueIsoType = Utils.getAuditTrailDiffs(container, ['contIsoType'])
            const valueStatus = Utils.getAuditTrailDiffs(container, ['contStatus'])
            const valueShpOwned = Utils.getAuditTrailDiffs(container, ['shpOwned'])
            if (Utils.noChanges(valueQty) && Utils.noChanges(valueIsoType) && Utils.noChanges(valueStatus) && Utils.noChanges(valueShpOwned)) {
                containerStatus = null
            }
        }
        return (
            <Box width="100%">
                <ComponentWrapper
                    header={oldHeader}
                    headerChange={newHeader}
                    rightConnerHeader={
                        <Box>
                            {oldHeader != newHeader && oldHeader != NO_VALUE && newHeader != NO_VALUE && (
                                <Chip label={tranObj.renamed.toUpperCase()} size='small' className='info-chip' style={{ marginRight: 5 }} />
                            )}
                            {containerStatus && (<Chip label={containerStatus.toUpperCase()} size='small' className={className} style={{ marginRight: 5 }} />)}
                        </Box>
                    }
                    cardStyle={{ marginBottom: 20 }} backgroundStyle="inner-card-background"
                    headerStyle="inner-card-background inner-card-group boldLabel">
                    <AuditTrailDualColumn {...props} object={container} field={'contQty'} label={tranObj.quantity} />
                    <AuditTrailDualColumnContainerType container={container} containerTypes={containerTypes} {...props} />
                    <AuditTrailDualColumn {...props} object={container} field={'contStatus'}
                        codeType={CalistaConstant.CAL_CONT_STATUS}
                        codeMasterList={codeMasterList}
                        label={tranObj.status} />
                    <AuditTrailDualColumn {...props} object={container} field={'shpOwned'} label={tranObj.shipperOwned} />
                </ComponentWrapper>
            </Box> 
        )
    }

    return (
        <Card>
            <Accordion expanded={scheduleExpanded}>
                <AccordionSummary aria-controls="containerComponentContent" id='containerComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.containerDetails} hideMandatory/>                        
                    </Grid>
                </AccordionSummary>
                <Box mx={2}>
                    <Grid container xs={12} sm={12} spacing={0}>
                        <CngGridItem xs={6} sm={6}>
                            <Box>
                                <Box className="view-card-content-label">
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            {tranObj.oldContainer}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </CngGridItem>
                        <CngGridItem xs={6} sm={6}>
                            <Box>
                                <Box className="view-card-content-label">
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            {tranObj.newContainer}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </CngGridItem>
                    </Grid>
                </Box>
                <AccordionDetails>
                    <Grid container>
                        {
                            allContainer.map((c, i) => {
                                let containerField;
                                let reeferField;

                                //Check for Container Details and populate containerField var if there are changes
                                if (c.added) {
                                    containerField = <ContainerFields added container={c} index={i} />
                                } else if (c.removed) {
                                    containerField = <ContainerFields removed container={c} index={i + numOfAdded} />
                                } else {
                                    //Flow will come to this if only container has updates. 
                                    //However it also comes in when other container items have changes but current item has no changes
                                    //Hence adding this container check to ensure it displays changes if container item fields have changes
                                    if (Utils.hasFieldsChanged(c, FIELDS_CONTAINER)) {
                                        containerField = <ContainerFields updated container={c} index={i + numOfAdded + numOfRemoved} />
                                    }
                                }

                                //Check for Reefer Details and populate reeferField var if there are changes
                                if (c[ADDED] != undefined && c[ADDED][REEFER] &&
                                    c[ADDED][REEFER].added && c[ADDED][REEFER].added[0]) {
                                    reeferField = <ReeferFields added reefer={c[ADDED][REEFER].added[0]} />
                                }

                                else if (c[REMOVED] != undefined && c[REMOVED][REEFER] &&
                                    c[REMOVED][REEFER].removed && c[REMOVED][REEFER].removed[0]) {
                                    reeferField = <ReeferFields removed reefer={c[REMOVED][REEFER].removed[0]} />
                                }

                                else if (c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                                    c[CHANGE][REEFER].added && c[CHANGE][REEFER].added[0]) {
                                    reeferField = <ReeferFields added reefer={c[CHANGE][REEFER].added[0]} />
                                }

                                else if (c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                                    c[CHANGE][REEFER].removed && c[CHANGE][REEFER].removed[0]) {
                                    reeferField = <ReeferFields removed reefer={c[CHANGE][REEFER].removed[0]} />
                                }

                                else if (c[CHANGE] != undefined && c[CHANGE][REEFER] &&
                                    c[CHANGE][REEFER].updated && c[CHANGE][REEFER].updated[0] &&
                                    Utils.reeferObjectHasChanges(c[CHANGE][REEFER], FIELDS_REEFER)) {
                                    reeferField = <ReeferFields updated reefer={c[CHANGE][REEFER].updated[0]} />
                                }

                                return (
                                    <Grid container xs={12} sm={12}>
                                        <Grid container xs={12} sm={12}>
                                            {containerField}
                                        </Grid>
                                        <Grid container xs={12} sm={12}>
                                            {reeferField}
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleScheduleExpand}
                expanded={scheduleExpanded}
                footerText={tranObj.showContainerDetails}
                footerTextExpanded={tranObj.hideContainerDetails}
                noOptionalFields
            />
        </Card>
    )
}

const ReeferFields = ({ reefer, ...props }) => {
    const tranObj = FreightBookingTranslationText()

    const {
        added,
        removed,
        updated,
    } = props

    let reeferStatus = '';
    let className = 'white-chip'

    if (added) {
        reeferStatus = tranObj.added;
        className = 'success-chip'
    }
    if (removed) {
        reeferStatus = tranObj.deleted
        className = 'danger-chip'
    }
    if (updated) {
        reeferStatus = tranObj.updated
        className = 'primary-chip'
    }
    return (
        <Box width="100%">
            <ComponentWrapper
                rightConnerHeader={
                    <Box>
                        {reeferStatus && (<Chip label={reeferStatus.toUpperCase()} size='small' className={className} style={{ marginRight: 5 }} />)}
                    </Box>
                }
                cardStyle={{ marginBottom: 20 }} backgroundStyle="inner-card-background"
                headerStyle="inner-card-background inner-card-group boldLabel">

                <AccordionSummary>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.reeferDetails} hideMandatory />
                    </Grid>
                </AccordionSummary>

                <AuditTrailDualColumn object={reefer} field={'nonActiveReefer'} label={tranObj.nonActiveReefer} />
                <AuditTrailDualColumn object={reefer} field={'equipmentTemp'} label={tranObj.equipmentTemp} />
                <AuditTrailDualColumn object={reefer} field={'tempUom'} label={tranObj.tempUom} />
                <AuditTrailDualColumn object={reefer} field={'superFreezerService'} label={tranObj.superFreezerService} />
                <AuditTrailDualColumn object={reefer} field={'gensetRequired'} label={tranObj.gensetRequired} />
                <AuditTrailDualColumn object={reefer} field={'inTransitColdSterilization'} label={tranObj.inTransitColdSterilization} />
                <AuditTrailDualColumn object={reefer} field={'numberOfTempProbes'} label={tranObj.numberOfTempProbes} />
                <AuditTrailDualColumn object={reefer} field={'numberOfUSDProbes'} label={tranObj.numberOfUSDProbes} />
                <AuditTrailDualColumn object={reefer} field={'tempVariance'} label={tranObj.tempVariance} />
                <AuditTrailDualColumn object={reefer} field={'ventSetting'} label={tranObj.ventSetting} />
                <AuditTrailDualColumn object={reefer} field={'equipmentAirFlow'} label={tranObj.equipmentAirFlow} />
                <AuditTrailDualColumn object={reefer} field={'equipmentControlledAtmosphereCO2'} label={tranObj.equipmentControlledAtmosphereCO2} />
                <AuditTrailDualColumn object={reefer} field={'equipmentControlledAtmosphereO2'} label={tranObj.equipmentControlledAtmosphereO2} />
                <AuditTrailDualColumn object={reefer} field={'equipmentControlledAtmosphereN2'} label={tranObj.equipmentControlledAtmosphereN2} />
                <AuditTrailDualColumn object={reefer} field={'equipmentHumidity'} label={tranObj.equipmentHumidity} />
                <AuditTrailDualColumn object={reefer} field={'equipmentComments'} label={tranObj.equipmentComments} />
            </ComponentWrapper>
        </Box >
    )
}

export default AuditTrailContainer
