import {
    Box,
    Card,
    Grid
} from '@material-ui/core'

import ContainerItemViewField from 'src/components/field/ContainerItemViewField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const { auditBooking } = props
    const [, ,] = useField('containers')

    const translatedTextsObject = FreightBookingTranslationText();

    const containerList = props.containers;
    console.log('ViewContainerDetailComponent containerList:', containerList)
    const getContainerTotalCount = () => {
        var totalCount = 0
        if (containerList != null && containerList.length > 0) {
            containerList.map((container) => {
                totalCount = totalCount + parseInt(container.containerQty)
            })
        }

        return totalCount

    }

    /*
    useEffect(() => {
        // alert(JSON.stringify(containersField))

        const contnew = props.containers;
        console.log('ViewContainerDetailComponent containerList:', contnew)

        setContainerList(contnew)
        setContainersField(contnew)
    }, [])
*/
    const ViewContainerDetailComponent = () => {
        return (
            containerList.map((m) => (

                <Grid xs={12} sm={12} key={m.id}>
                    <Grid xs={12} sm={12} key={m.id} spacing={2} className="view-card view-card-content">
                        <ContainerItemViewField
                            containerQty={m.containerQty}
                            containerIsoType={m.containerIsoType}
                            containerStatus={m.containerStatus}
                            containerSOC={props.showDetails ? m.shipperOwned : false}
                            id={m.contId}
                            auditBooking={props.auditBooking}
                            currentReeferDetails={m}

                        />
                    </Grid>
                </Grid>
            ))
        )
    };

    return (
        <Card>
            <Box className="view-card-header">
                {translatedTextsObject.containerDetails} <span className='view-card-header-info'> / {getContainerTotalCount()} {translatedTextsObject.containers}</span>
            </Box>
            <ViewContainerDetailComponent auditBooking={auditBooking} />
        </Card>
    )
}

const ViewContainerComponent = Object.freeze({
    FormBody: FormBody,
    // initialValues : initialValues
})

export default ViewContainerComponent
