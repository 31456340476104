import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Box,
    Chip
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import Utils from 'src/views/common/utils/Utils'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import ComponentWrapper from './ComponentWrapper'

const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const AuditTrailDocument = (props) => {
    const {documents, docTypes} = props
    const tranObj = FreightBookingTranslationText()
    const [documentExpanded, setDocumentExpanded] = useState(false)

    const handleDocumentExpanded = () => {
        setDocumentExpanded(!documentExpanded);
    };
    const numOfAdded = documents && documents.added ? documents.added.length : 0
    const numOfRemoved = documents && documents.removed ? documents.removed.length : 0

    const DocumentFields = ({document,index,...props}) => {
        
        return (
            <Box width="100%">                     
                <ComponentWrapper 
                header={tranObj.document + " " + (index+1)}
                                cardStyle={{marginTop:20}} backgroundStyle="inner-card-background" 
                                headerStyle="inner-card-background inner-card-group boldLabel">   
                            <AuditTrailDualColumn {...props} object={document} field={'filename'} label = {tranObj.fileName}/>
                            <AuditTrailDualColumn {...props} object={document} field={'docType'} match={Utils.matching('code','descriptionEn', docTypes)} label = {tranObj.fileType}/> 
                            <AuditTrailDualColumn {...props} object={document} field={'description'} label = {tranObj.fileDesc}/>  
                </ComponentWrapper>
            </Box> 
        )
    }
    return (
        <Card>
            <Accordion expanded={documentExpanded}>
                <AccordionSummary aria-controls="documentComponentContent" id='documentComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.documents} hideMandatory/>                        
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                   <Grid container>
                    {
                       documents && documents.added && documents.added.length > 0 && documents.added.map((c,i)=> (
                        <Grid document xs={12} sm={12}>    
                               <DocumentFields added document={c} index={i}/>
                        </Grid>
                        ))
                    }
                    {
                       documents && documents.removed && documents.removed.length > 0 && documents.removed.map((c,i)=> (
                         <Grid document xs={12} sm={12}>     
                               <DocumentFields removed document={c} index={i+ numOfAdded} />
                               </Grid>
                        ))
                    }  
                    {
                        documents &&  documents.updated && documents.updated.length > 0 && documents.updated.map((c,i)=> {
                            if(Utils._changeValueEmpty(c, ['bookingId']))  {
                                return <div/>
                            }  
                            return(
                                <Grid document xs={12} sm={12}>  
                                    <DocumentFields updated document={c} index={i+ numOfAdded + numOfRemoved} />
                                </Grid>
                            )    
                        })
                    } 
                   </Grid>  
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleDocumentExpanded}
                expanded={documentExpanded}
                footerText={tranObj.showDocumentDetails}
                footerTextExpanded={tranObj.hideDocumentDetails}
                noOptionalFields
            />
        </Card>
    )
}

export default AuditTrailDocument