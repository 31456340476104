import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid
} from '@material-ui/core'
import React, { useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import SearchIcon from '@material-ui/icons/Search'
import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngSelectField }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const [bookingTypeExpanded, setBookingTypeExpanded] = useState(false)
  const [shipmentTypeField, , { setValue: setShipmentTypeField }] =
    useField('shipmentType')

  const handleBookingExpand = () => {
    setBookingTypeExpanded(!bookingTypeExpanded)
  }

  let pendingShown = props.pendingClicked
    ? 'shown(' + props.shownCount + ')'
    : ''
  let processedShown = !props.pendingClicked
    ? 'shown(' + props.shownCount + ')'
    : ''
  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        <div className={`cng-summary--header`}>
          <div className={`cng-summary--header-col auto`}>
            <Box
              display='flex'
              justifyContent='flex-start'
              className={`frb-tabs-btn-wrapper`}
            >
              <Button
                onClick={props.clickPending}
                variant={props.pendingClicked ? 'outlined' : 'text'}
                className={props.pendingClicked ? `tab-active` : `tab-normal`}
              >
                Pending
                <em>({props.pendingCount})</em>
              </Button>

              <Button
                onClick={props.clickProcessed}
                variant={!props.pendingClicked ? 'outlined' : 'text'}
                className={!props.pendingClicked ? `tab-active` : `tab-normal`}
              >
                Processed
                <em>({props.processedCount})</em>
              </Button>
            </Box>
          </div>
          <div className={`cng-summary--header-col w-190px`}>
            <CngSelectField
              name='sortCode'
              label='Sort By'
              items={props.sortByList}
              onClick={props.handleSortByChange}
              inlineLabel
            />
          </div>
        </div>
      </CngGridItem>

      <CngGridItem item xs={12} sm={12}>
        <span className={`summary-count-label-wrapper mt-25 mb-10`}>
          <label>
            {translatedTextsObject.shown} &#40;{props.shownCount}&#41;
          </label>
        </span>
      </CngGridItem>
    </Grid>
  )
}

const ButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default ButtonTabComponent
