import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import DocumentDetailsActionMenu from './DocumentDetailsActionMenu'

import Paper from '@material-ui/core/Paper'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDocumentAutocompleteField }
  }
} = components

const DocumentDetails = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()

  console.log(props.tile)

  useEffect(() => {
    var files = props.tile
    console.log(files)
  }, [props.tile])

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
            {props.tile.map((elem, i) => {
              return (
                <TableRow key={elem.id}>
                  <TableCell className={`file-format`}>
                    {' '}
                    <FileCopyIcon />
                  </TableCell>
                  <TableCell>
                    <DocumentInfoDecoratorComponent
                      name={elem.file.name}
                      size={elem.file.size}
                    />{' '}
                  </TableCell>

                  <TableCell>
                    <CngDocumentAutocompleteField
                      name={`pendingBookingDocuments[${i}].docType`}
                      label={translatedTextsObject.fileType}
                      onChange={(val, evt) =>
                        props.handleDropdownChange(val, evt, i, 'docType')
                      }
                      codeType='CLOGS_FRB'
                      key={props.docTypeKeyRef}
                      isRequired
                    />
                  </TableCell>
                  <TableCell>
                    <CngTextField
                      type='text'
                      name={`pendingBookingDocuments[${i}].description`}
                      label={translatedTextsObject.fileDesc}
                      onChange={(e) => props.handleInputChange(e, i)}
                      inputProps={{ maxLength: 1024 }}
                      isRequired
                    />
                  </TableCell>
                  <TableCell>
                    <DocumentDetailsActionMenu
                      id={i}
                      removeRowClick={props.removeRowClick(i)}
                      hideDownloadLog
                      hideEdit
                      hideDownload
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default DocumentDetails
