import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_FreightBooking'
import SearchPage from './SearchPage'
import ResultPage from './ResultPage'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

function WrappedBookFromSchedulePage(props) {
  const translatedTextsObject = FreightBookingTranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <div className={'outer-container'}>
          <SearchPage showNotification={showSnackbar} {...props} />
        </div>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedBookFromScheduleResultPage(props) {
  const translatedTextsObject = FreightBookingTranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ResultPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedBookFromSchedulePage as BookFromSchedulePage,
  WrappedBookFromScheduleResultPage as BookFromScheduleResultPage
}
