import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BookingSyncLogViewForm from './BookingSyncLogViewForm'
import BookingSyncLogEditForm from './BookingSyncLogEditForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import BookingSyncLogKeys from 'src/constants/locale/key/BookingSyncLog'

const { CngTabs } = components


function EditPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.BOOKING_SYNC_LOG])
  const translatedTextsObject = makeTranslatedTextsObject()
  const [bookingSyncLog, setBookingSyncLog] = useState({})

  function makeTranslatedTextsObject() {
    let bookingSyncLog = translate(
      Namespace.BOOKING_SYNC_LOG,
      BookingSyncLogKeys.TITLE
    )

    return {
      bookingSyncLog
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <BookingSyncLogViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
              }}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.bookingSyncLog,
              tabContent: (
                <BookingSyncLogEditForm
                  showNotification={showNotification}
                  id={id}
                  onFetchPreSuccess={(datum) => {
                    setBookingSyncLog(datum)
                  }}
                  items={bookingSyncLog}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage