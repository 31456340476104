import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import ManageCarriersTranslationText from 'src/views/vesselschedule/managepreferredcarrier/ManageCarriersTranslationText'
import ManagePreferredCarrierDialogContext from 'src/views/vesselschedule/managepreferredcarrier/ManagePreferredCarrierDialogContext.js'
import ManagePreferredCarrierForm from 'src/views/vesselschedule/managepreferredcarrier/ManagePreferredCarrierForm.js'
import Paper from '@material-ui/core/Paper'
import DGCargoDetailsContext from './DGCargoDetailsContext.js'
import ViewDGCargoDetailsForm from './ViewDGCargoDetailsForm.js'
import { components } from 'cng-web-lib'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'

const { CngDialog } = components

function ViewDGCargoDetailsDialog({
  isDialogOpen,
  closeDialog,
  currentDGDetails,
  showNotification,
  currentDGCargoIdx,
  currentDGDetailsId,
  uomItems,
  packageUomItems,
  currentAudit
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()

  return (
    <DGCargoDetailsContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        currentDGDetails,
        uomItems,
        packageUomItems,
        currentAudit
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper className='accordian-header'>
            <ViewDGCargoDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            <h3>
              <b>
                Cargo {currentDGCargoIdx + 1} DG Details{' '}
                {currentDGDetailsId + 1}
              </b>
            </h3>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </DGCargoDetailsContext.Provider>
  )
}

export default ViewDGCargoDetailsDialog
