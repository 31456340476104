import { Box, Grid } from '@material-ui/core'
import React from 'react'

const LabelValueVerticalSimple = (props) => {
  const { label, value, labelStyle, valueStyle } = props

  const valueCss = valueStyle ? valueStyle : 'view-card-content-value'
  const labelCss = labelStyle ? labelStyle : 'view-card-content-label'
  const defaultValue = props.defaultValue ? props.defaultValue : '-'
  return (
    <div className={`field-vertical--label-value`}>
      <span className={`field-vertical--item`}>
        <label className={`${labelCss} field-label`}>{label}</label>
      </span>

      <span className={`field-vertical--item`}>
        <label className={`${valueCss} field-value`}>
          {value ? value : defaultValue}
        </label>
      </span>
    </div>
  )
}
export default LabelValueVerticalSimple
