import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import Paper from '@material-ui/core/Paper';
import DocumentDetailsActionMenu from './DocumentDetailsActionMenu'
import Utils from 'src/views/common/utils/Utils'
import DocumentApi from 'src/views/common/api/DocumentApi'
const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const {
  CodeMaintenanceType
} = constants

const FormBody = (props) => {
  const { auditBooking } = props
  const translatedTextsObject = FreightBookingTranslationText()
  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const documentList = props.document;
  console.log('ViewDocumentComponent documents:', documentList)
  const auditDocuments = auditBooking && auditBooking.documents ? auditBooking.documents : {}
  const { securedSendRequest } = useServices()
  useEffect(() => {



    setShouldRender(true)

  }, [])

  if (!shouldRender) {
    return null;
  }



  const downloadRowClick = (id) => () => {
    console.log("download uploaded: " + id)

    DocumentApi.downloadDocument(securedSendRequest, documentList[id], props.module)
  }
  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className="view-card-header">
            {translatedTextsObject.document}
          </Box>
          <Box>
            <TableContainer component={Paper}>
              <Table id="table">
                <TableBody>
                  {documentList.map((elem, i) => {
                    let highlight = {}
                    if (Object.entries(auditDocuments).length > 0) {
                      highlight = Utils.auditHighLight(auditDocuments, ['docType', 'description'], elem.id, 'document')
                    }
                    return (<TableRow key={`row-${i}`} style={highlight.document}>

                      <TableCell className={`file-format`} style={{ verticalAlign: 'top' }}> <Box className="view-card-content" ><Box className="view-card-content-value" ><FileCopyIcon /></Box></Box></TableCell>
                      <TableCell style={{ verticalAlign: 'top' }} ><DocumentInfoDecoratorComponent name={elem.filename == null ? elem.file.name : elem.filename} size={elem.filesize == null ? elem.file.size : elem.filesize} /> </TableCell>


                      <TableCell style={{ verticalAlign: 'top' }}>
                        <Box className="view-card-content" style={highlight.docType}>
                          <Box className="view-card-content-label">
                            {translatedTextsObject.fileType}
                          </Box>
                          <Box className="view-card-content-value" >
                            <CodeMasterMiscViewField
                              codeType={CodeMaintenanceType.DOCUMENT}
                              code={elem.docType}
                            />
                          </Box>
                        </Box>

                      </TableCell>
                      <TableCell>
                        <Box className="view-card-content" style={highlight.description}>
                          <Box className="view-card-content-label">
                            {translatedTextsObject.fileDesc}
                          </Box>

                          <Box className="view-card-content-value" style={{ wordBreak: 'break-word' }}>
                            {elem.description}
                            {/* <LabelValueVertical label={translatedTextsObject.fileDesc}
                              value={elem.description} /> */}
                          </Box>
                        </Box>
                      </TableCell>

                      {props.allowDownload && (
                        <TableCell>
                          {/* download action */}
                          <DocumentDetailsActionMenu id={i}
                            downloadRowClick={downloadRowClick(i)}
                            hideDownloadLog
                            hideEdit
                            hideDelete
                          />

                        </TableCell>)
                      }


                    </TableRow>)
                  })
                  }
                </TableBody >
              </Table>
            </TableContainer>



            <Grid container xs={12} sm={12}>
              <CngGridItem xs={6} sm={6}>

              </CngGridItem>

            </Grid>
          </Box>
        </Card>
      </CngGridItem>
    </Grid >
  )
}


const initialValues = Object.freeze({

})

const ViewDocumentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewDocumentComponent
