import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Divider,
    Grid,
    Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import IconButton from '@material-ui/core/IconButton';

const {
    form: {
        field: {
            CngTextField,
            CngSelectField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants


const {
    CngPackageUomAutocompleteField,
} = components.form.field


const CargoDetailsComponent = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()



    return (
        props.list.map((m, idx) => (
           
            <Grid container xs={12} sm={12} spacing={2} key={m.id}>

                <CngGridItem xs={12} sm={12}>
                    <Card>
                        <Box >
                            <Grid container className='accordian-header'>
                                <CngGridItem xs={6} sm={6}>
                                    <Box p={1} fontSize='large' fontWeight='bolder'>
                                        {translatedTextsObject.cargo + ' ' + (idx + 1)}
                                    </Box>
                                </CngGridItem>
                                <Grid container xs={6} sm={6} justify="flex-end">
                                    {/* <Box> */}
                                    <IconButton aria-label="copy" onClick={props.copyRowClick(idx)}>
                                        <FileCopyIcon  />
                                    </IconButton>
                                    <IconButton aria-label="remove"
                                        onClick={props.removeRowClick(idx)}
                                        style={{ display: (props.list.length > 1) ? "inline" : "none" }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>

                                </Grid>
                            </Grid>

                        </Box>
                        <Accordion expanded={m.cargoBoxExpanded}>
                            <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={12} sm={12}>
                                        <CngTextField
                                            type='text'
                                            name={`bookingCargoes[${idx}].cargoDesc`}
                                            label={translatedTextsObject.cargoDesc}
                                            value={m.cargoDesc}
                                            multiline
                                            isRequired
                                            rows={4}
                                            inputProps={{ maxLength: 2400 }}
                                            onChange={e => props.handleInputChange(e, idx)}
                                            // error={props.getFieldError(idx, 'bookingCargoes.cargoDesc')}
                                            // helperText={props.getFieldError(idx, 'bookingCargoes.cargoDesc')}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            name={`bookingCargoes[${idx}].hsCode`}
                                            label={translatedTextsObject.hsCode}
                                            type='text'
                                            value={m.hsCode}
                                            inputProps={{ maxLength: 16 }}
                                            onChange={e => props.handleInputChange(e, idx)}
                                            // error={props.getFieldError(idx, 'bookingCargoes.hsCode')}
                                            // helperText={props.getFieldError(idx, 'bookingCargoes.hsCode')}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='number'
                                            name={`bookingCargoes[${idx}].quantity`}
                                            label={translatedTextsObject.quantity}
                                            value={m.quantity}
                                            customType={PLAIN_NUMBER}
                                            inputProps={{ maxLength: 5 }}
                                            onChange={e => props.handleInputChange(e, idx)}
                                            // error={props.getFieldError(idx, 'bookingCargoes.quantity')}
                                            // helperText={props.getFieldError(idx, 'bookingCargoes.quantity')}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngPackageUomAutocompleteField

                                            name={`bookingCargoes[${idx}].packageType`}
                                            label={translatedTextsObject.packageType}
                                            key={props.cargoPackageKeyRef}


                                            onChange={val => props.handleDropdownChange(val, idx, 'packageType')}
                                        />
                                    </CngGridItem>

                                    <CngGridItem xs={2} sm={2}>
                                    </CngGridItem>
                                    <CngGridItem xs={10} sm={10}>
                                        <Card className="inner-card-group">
                                            <Grid container xs={12} sm={12} spacing={2}>
                                                <CngGridItem xs={2} sm={2}>
                                                    <Typography className="page-content-groupLabel text-upper">
                                                        {translatedTextsObject.gross}
                                                    </Typography>
                                                </CngGridItem>
                                                <CngGridItem xs={5} sm={5}>
                                                    <CngTextField
                                                        type='number'
                                                        name={`bookingCargoes[${idx}].grossVolume`}
                                                        label={translatedTextsObject.grossVolume}
                                                        value={m.grossVolume}
                                                        // error={props.getFieldError(idx, 'bookingCargoes.grossVolume')}
                                                        // helperText={props.getFieldError(idx, 'bookingCargoes.grossVolume')}
                                                        onChange={e => props.handleInputChange(e, idx)}
                                                        customType={PLAIN_NUMBER}
                                                        inputProps={{ maxLength: 11 }}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={5} sm={5}>
                                                    <CngTextField
                                                        type='number'
                                                        name={`bookingCargoes[${idx}].grossWeight`}
                                                        label={translatedTextsObject.grossWeight}
                                                        value={m.grossWeight}
                                                        // error={props.getFieldError(idx, 'bookingCargoes.grossWeight')}
                                                        // helperText={props.getFieldError(idx, 'bookingCargoes.grossWeight')}
                                                        onChange={e => props.handleInputChange(e, idx)}
                                                        customType={PLAIN_NUMBER}
                                                        inputProps={{ maxLength: 11 }}
                                                    />
                                                </CngGridItem>

                                            </Grid>
                                        </Card>
                                    </CngGridItem>

                                    {/* <CngGridItem xs={2} sm={2}>
                                    </CngGridItem>
                                    <CngGridItem xs={10} sm={10}>
                                        <Card className="inner-card-group">
                                            <Grid container xs={12} sm={12} spacing={2}>
                                                <CngGridItem xs={2} sm={2}>
                                                    <Typography className="page-content-groupLabel text-upper">
                                                        {translatedTextsObject.net}
                                                    </Typography>
                                                </CngGridItem>
                                                <CngGridItem xs={5} sm={5}>
                                                    <CngTextField
                                                        type='number'
                                                        name={`bookingCargoes[${idx}].netVolume`}
                                                        label={translatedTextsObject.netVolume}
                                                        value={m.netVolume}
                                                        error={props.getFieldError(idx, 'bookingCargoes.netVolume')}
                                                        helperText={props.getFieldError(idx, 'bookingCargoes.netVolume')}
                                                        onChange={e => props.handleInputChange(e, idx)}
                                                        customType={PLAIN_NUMBER}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={5} sm={5}>
                                                    <CngTextField
                                                        type='number'
                                                        name={`bookingCargoes[${idx}].netWeight`}
                                                        label={translatedTextsObject.netWeight}
                                                        value={m.netWeight}
                                                        error={props.getFieldError(idx, 'bookingCargoes.netWeight')}
                                                        helperText={props.getFieldError(idx, 'bookingCargoes.netWeight')}
                                                        onChange={e => props.handleInputChange(e, idx)}
                                                        customType={PLAIN_NUMBER}
                                                    />
                                                </CngGridItem>

                                            </Grid>
                                        </Card>
                                    </CngGridItem> */}

                                    {/* <CngGridItem xs={2} sm={2}>
                                    </CngGridItem>
                                    <CngGridItem xs={10} sm={10}>
                                        <Card className="inner-card-group">
                                            <Grid container xs={12} sm={12} spacing={2}>
                                                <CngGridItem xs={4} sm={4}>
                                                    <Typography className="page-content-groupLabel text-upper">
                                                        {translatedTextsObject.vgm}
                                                    </Typography>
                                                </CngGridItem>
                                                <CngGridItem xs={3} sm={3}>

                                                </CngGridItem>
                                                <CngGridItem xs={5} sm={5}>
                                                    <CngTextField
                                                        type='number'
                                                        name={`bookingCargoes[${idx}].vgmWeight`}
                                                        label={translatedTextsObject.totalVgmWeight}
                                                        value={m.vgmWeight}
                                                        error={props.getFieldError(idx, 'bookingCargoes.vgmWeight')}
                                                        helperText={props.getFieldError(idx, 'bookingCargoes.vgmWeight')}
                                                        onChange={e => props.handleInputChange(e, idx)}
                                                        customType={PLAIN_NUMBER}
                                                    />
                                                </CngGridItem>

                                            </Grid>
                                        </Card>
                                    </CngGridItem> */}


                                </Grid>

                            </AccordionDetails>
                        </Accordion>

                        <AccordionFooterControlComponent
                            handleAccordionExpand={props.handleCargoDetailsExpand(idx)}
                            expanded={m.cargoBoxExpanded}
                            footerText={translatedTextsObject.additionalCargoDetails}
                        />
                    </Card>
                </CngGridItem>
            </Grid>

        ))



    )
}


export default CargoDetailsComponent