import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components

const initialValues = Object.freeze({

    emergencyContact: "",
    emergencyContactNo: "",


})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()


    const [, ,] = useField('emergencyContact')
    const [, ,] = useField('emergencyContactNo')


    return (
        <Card>
            <Accordion expanded={true}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.forEmergency} />

                        <Grid container xs={12} sm={12} spacing={2}>


                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='text'
                                    name='emergencyContact'
                                    inputProps={{ maxLength: 100 }}
                                    label={translatedTextsObject.emergencyContact}
                                    required
                                />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type='text'
                                    name='emergencyContactNo'
                                    inputProps={{ maxLength: 50 }}
                                    label={translatedTextsObject.emergencyContactNumber}
                                    required
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>

            </Accordion>


        </Card>
    )
}

const DGCargoDetailsEmergencyComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default DGCargoDetailsEmergencyComponent