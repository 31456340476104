import {
    Box,
    Grid,
    Typography,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import AddLocationOutlinedIcon from '@material-ui/icons/AddLocationOutlined'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import {
    components
} from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const {
    button: { CngPrimaryButton, CngSecondaryButton }
} = components


const BookingListPageError = props => {
    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    return (
        <div className={'vs-noresult'}>
            <img
                src={
                    process.env.PUBLIC_URL + '/static/images/freightBooking/noresult.png'
                }
                alt='No Result'
            ></img>
            <Typography variant='h3' style={{ fontWeight: 'bold', paddingTop: '10px'}}>
                {translatedTextsObject.smthWrong}
            </Typography>
            <Typography variant='subtitle1' style={{ paddingTop: '10px', color:'#808080'}}>
                {translatedTextsObject.smthWrongDesc}
            </Typography>

            <Box pt={2}>
                <Grid container>
                    <CngSecondaryButton onClick={props.refreshAction}>
                        <RefreshIcon />
                        <Box pl={1}>
                            {translatedTextsObject.refresh}
                        </Box>
                    </CngSecondaryButton>
                </Grid>
            </Box>

        </div>
    )
}

export default BookingListPageError;