import 'src/assets/scss/ShipperRemark.scss'

import { Box, Card, CardActionArea, Grid } from '@material-ui/core'

import AvatarInitial from './AvatarInitial'
import BookingDetailBottom from './BookingDetailBottom'
import BookingDetailTop from './BookingDetailTop'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVerticalSimple from './LabelValueVerticalSimple'
import PortDetailComponent from './PortDetailComponent'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const FormBody = (props) => {
  const translatedTextsObject = FreightBookingTranslationText()
  const { booking } = props
  const history = useHistory()

  let shipperShortName = booking.partyIdName
  let showTooltip = false
  if (booking.partyIdName && booking.partyIdName.length > 5) {
    shipperShortName = booking.partyIdName.substring(0, 5).trim() + '...'
    showTooltip = true
  }

  const gotoReviewBooking = () => {
    // console.log('gotoReviewBooking')
    history.push({
      pathname: pathMap.MANAGE_BOOKING_REVIEW,
      state: {
        bookingId: booking.bookingId,
        dockey: booking.dockey,
        bookStatus: booking.bookStatus
      }
    })
  }

  function processShpRemarks(remarks) {
    if (remarks == null) return remarks
    else {
      let trimRemarks = Utils.trimString(booking.shpRemarks, 88)
      let lines = trimRemarks.split('\n')
      if (lines.length == 1) {
        return lines[0]
      } else {
        return lines[0] + '\n' + lines[1]
      }
    }
  }
  return (
    <Card className={`summary-list-item--card`}>
      <BookingDetailTop booking={booking} isCarrier {...props} />
      <CardActionArea
        onClick={gotoReviewBooking}
        className={`cng-summary-card--body-box`}
      >
        <Grid container xs={12} className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={1}>
            <AvatarInitial
              withTooltip={showTooltip}
              longName={booking.partyIdName}
              shortName={shipperShortName}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.vesselName}
                value={booking.vesselName}
                defaultValue='-'
                labelStyle={'field-label'}
                valueStyle={'field-value'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.voyageNo}
                value={booking.voyageNo}
                defaultValue='-'
                labelStyle={'field-label'}
                valueStyle={'field-value'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.shipperRefNo}
                value={booking.shpRefNo}
                defaultValue='N/A'
                labelStyle={'field-label'}
                valueStyle={'field-value font-mediumBold'}
              />
            </Box>
          </CngGridItem>

          <CngGridItem xs={12} sm={6}>
            <Grid container>
              <CngGridItem xs={12} sm={12}>
                <Grid container>
                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfReceipt}
                      portCode={booking.placeOfReceiptCode}
                      portName={booking.placeOfReceipt}
                      date={booking.placeOfReceiptEtd}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfDelivery}
                      portCode={booking.placeOfDeliveryCode}
                      portName={booking.placeOfDelivery}
                      date={booking.placeOfDeliveryEta}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <LabelValueVerticalSimple
                  label={translatedTextsObject.shipperRemarks}
                  value={booking.shpRemarks}
                  //value={processShpRemarks(booking.shpRemarks)}
                  defaultValue='-'
                  labelStyle={'field-label'}
                  valueStyle={'field-value clamp-line-to-two font-mediumBold'}
                />
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </CardActionArea>
      <BookingDetailBottom
        dockey={booking.dockey}
        updatedDate={booking.updatedDate}
        hazardous={booking.hazardous}
      />
    </Card>
  )
}

const CarrierBookingListDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CarrierBookingListDetailComponent
