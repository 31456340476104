import { Box, Button, Grid, Typography } from '@material-ui/core'

import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import SearchScheduleTranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const FilterHeader = (props) => {
  const translatedTextsObject = SearchScheduleTranslationText()

  return (
    <header className={`cng-filter-pane--header`}>
      <Box className={`cng-filter-pane--header-label-box`}>
        <Typography class={'cng-filter-pane--header-label'}>
          {translatedTextsObject.filters}
        </Typography>
      </Box>
      <Box className={`cng-filter-pane--header-cta-box`}>
        <Button
          name='clear'
          startIcon={<RestoreIcon />}
          className={'clear-all-button'}
          style={props.isDisabled ? { color: 'grey' } : { color: '#5E81F4' }}
          size='small'
          disabled={props.isDisabled}
          onClick={props.handleClearAllButtonClick}
        >
          {translatedTextsObject.clearAll}
        </Button>
      </Box>
    </header>
  )
}

export default FilterHeader
