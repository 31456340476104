import {
    Avatar,
    Box,
    Card,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core'

import { AlignRight } from 'react-feather';
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Printer from './Printer'
import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore';
import Utils from '../shared/Utils'
import {
    components
} from 'cng-web-lib'
import moment from 'moment'

const {
    CngGridItem
} = components

const HeaderBarTop = (props) => {
    const { booking, bookingStatus, isCarrier } = props
    const bookStatus = bookingStatus != undefined ? bookingStatus : (booking.bookingStatus || '')
    const translatedTextsObject = FreightBookingTranslationText()
    let bgColor = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).color : ''
    let status = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).name.toUpperCase() : ''

    return (
        <Card className={'titleBox'} style={{ backgroundColor: bgColor }}>
            <Grid container xs={12} alignItems="center">
                <CngGridItem xs={12} >
                    <Typography className={'boldLabel'} style={{ fontSize: 16 }}>BOOKING {status}  {((isCarrier ? booking.carrierClosed : booking.closed) ? "/ARCHIVED" : "")}</Typography>
                </CngGridItem>
                <Grid xs />
                {/* commentted the print feature as it is not working as expect and pending framework team to release the component for this feature */}
                {/* <Grid container xs={3} alignItems="center">
                    <Grid sm={10}>
                        <Typography className={'greyLabel alignRight'} >Print document</Typography>
                    </Grid>
                    <Grid sm={2} container justify="flex-end">
                        <Printer />
                    </Grid>
                </Grid> */}
            </Grid>
        </Card>

    )
}

export default HeaderBarTop;