import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CargoDetailsComponent from './CargoDetailsComponent'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import useFetchCodeMaintainance from 'src/views/common/serviceHook/useFetchCodeMaintainance'
import { v4 as uuid } from 'uuid'
import Utils from 'src/views/common/utils/Utils'
import { useFieldArray } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const { CngCodeMasterAutocompleteField } = components.form.field

const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  /*
        seqNo
    */
  const { fields } = useFieldArray({ name: 'bookingCargoes' })
  const [packageUOMType, setPackageUOMType] = useState([])
  const [packageUOMTypeField, , { setValue: setPackageUOMTypeValue }] =
    useField('packageType')
  const { errors, touched, setFieldValue } = useFormikContext()
  const [cargoList, setCargoList] = useState([])
  // const [cargoField, cargoMeta, { setValue: setCargoField }] = useField('bookingCargoes')
  const draftCargoId = useRef(-1)
  const translatedTextsObject = FreightBookingTranslationText()
  const cargoPackageKeyRef = useRef(uuid())
  const [totalCargoWeight, setTotalCargoWeight] = useState(0)

  function onLoadPackageUOMTypeSuccess(data) {
    setPackageUOMType(data)
  }
  useFetchCodeMaintainance(
    CodeMaintenanceType.PACKAGE_UOM,
    [],
    true,
    onLoadPackageUOMTypeSuccess
  )

  const cargoItem = {
    cargoTempId: -1, //for react key reference, actual field is "cargoId"
    id: uuid(),
    cargoDesc: '',
    hsCode: '',
    quantity: '',
    packageType: '',
    grossVolume: '',
    grossWeight: '',
    netVolume: '',
    netWeight: '',
    vgmWeight: '',
    cargoBoxExpanded: false
  }

  const getFieldError = (itemIndex, fieldPath) => {
    //fieldPath : containers.quantity
    if (errors) {
      let pathArr = fieldPath.split('.')
      // alert(pathArr)
      // alert(errors[ pathArr[0] ])
      if (
        errors[pathArr[0]] &&
        errors[pathArr[0]][itemIndex] &&
        errors[pathArr[0]][itemIndex][pathArr[1]]
      ) {
        if (touched) {
          if (
            touched[pathArr[0]] &&
            touched[pathArr[0]][itemIndex] &&
            touched[pathArr[0]][itemIndex][pathArr[1]]
          ) {
            // return true
            return errors[pathArr[0]][itemIndex][pathArr[1]]
          } else {
            return null
          }
        }
      }
    }
    return null
  }

  const handleCargoDetailsExpand = (idx) => () => {
    const isCargoBoxExpanded = fields[idx].cargoBoxExpanded
    console.log(isCargoBoxExpanded)
    const temp = [...cargoList]
    //  const temp = [...fields]
    temp[idx].cargoBoxExpanded = !isCargoBoxExpanded
    console.log(temp)
    setCargoList([].concat([], temp))
    setFieldValue('bookingCargoes', [].concat([], temp))
    // console.log(fields)
  }

  const removeRowClick = (id) => (e) => {
    // cargoList.splice(id, 1);
    // const newlist = [].concat([], cargoList)

    const list = [...cargoList]
    list.splice(id, 1)

    cargoPackageKeyRef.current = uuid()
    // console.log('list-->', list)
    setCargoList(list)
    // setCargoField(list)
    setFieldValue('bookingCargoes', list)
    //reset('bookingCargoes', list)
    setTotalCargoWeight(calculateTotalWeight(list))
    e.stopPropagation()
  }

  const copyRowClick = (index) => () => {
    const list = [...cargoList]
    // const list = [...containersField];
    const copyCargo = list.slice(index, index + 1)[0]
    // alert('index : ' + index + ", " + JSON.stringify(copyContainer))

    const copyCargoItem = { ...copyCargo }
    copyCargoItem.cargoTempId = draftCargoId.current
    copyCargoItem.cargoId = null //cargo id is unique for every cargo,cannot be copied from an existing one
    copyCargoItem.id = uuid()
    draftCargoId.current = draftCargoId.current - 1

    setCargoList([...cargoList, copyCargoItem])
    // alert('container List,' + JSON.stringify(containerList))

    //  setCargoField([...cargoList, copyCargoItem]);
    setFieldValue('bookingCargoes', [...cargoList, copyCargoItem])
    setTotalCargoWeight(calculateTotalWeight([...cargoList, copyCargoItem]))
    // console.log("copy: " + id)
    // console.log(cargoList[id])

    // const list = [...cargoList]
    // // const list = [...containersField];
    // const copyCargo = list.slice(id, id + 1)[0]
    // // console.log('index : ' + index + ", " + JSON.stringify(copyContainer))

    // const copyCargoItem = { ...copyCargo }

    // setCargoList([...cargoList, copyCargoItem]);

    // const newCargoItem = {

    //     cargoDesc: val,
    //     author: cargoList[id].author,

    // }

    // console.log(newMessageObj)

    // setMessageList([].concat(messageList, newMessageObj))
    // console.log(messageList)
  }

  const handleAddCargo = () => {
    const addCargo = { ...cargoItem }
    //addCargo.cargoTempId = draftCargoId.current
    // addCargo.id = uuid()
    // draftCargoId.current = draftCargoId.current - 1

    setCargoList([...cargoList, addCargo])
    setFieldValue('bookingCargoes', [...cargoList, addCargo])
  }

  const handleInputChange = (e, index) => {
    console.log(e.target)
    const { name, value } = e.target
    const list = [...cargoList]
    // const list = [...containersField]
    list[index][name.split('.')[1]] = value

    // alert('name ' + name.split('.')[1] + ' - valu : ' + value)
    let fieldName = name.split('.')[1]
    if (fieldName == 'grossWeight') {
      //  alert(parseInt(value))
      setTotalCargoWeight(calculateTotalWeight(list))
    }
    setCargoList(list)
    // setCargoField(list)
    setFieldValue('bookingCargoes', list)
  }

  function calculateTotalWeight(list) {
    var totalWeight = 0
    if (list != null && list.length > 0) {
      list.map((m, i) => {
        console.log(m.grossWeight)
        if (!Utils.isEmptyString(m.grossWeight)) {
          var validWeight = parseFloat(m.grossWeight)

          totalWeight += validWeight
        }
        //
      })
    }

    return Utils.roundToThree(totalWeight)
  }

  // function roundToThree(num) {
  //     return +(Math.round(num + "e+3") + "e-3");
  // }

  const handleDropdownChange = (val, index, name) => {
    // const { name} = e.target
    const list = [...cargoList]
    // // const list = [...containersField]
    // alert('list[index][name] : ' + index + "." + name)
    list[index][name] = val
    // console.log('handleInputChange : ' + JSON.stringify(list) )
    setCargoList(list)
  }

  useEffect(() => {
    const cargo = { ...cargoItem }
    cargo.cargoTempId = draftCargoId.current

    draftCargoId.current = draftCargoId.current - 1
    console.log(fields)
    if (props.isEdit) {
      // if cargoList is populated from review booking then set back to ContainerList to display
      // data population done in NewBookingFormProperties
      if (fields == null) {
        //when user switch from dg cargo, the cargo field could be empty
        createNewCargoList(cargo)
      } else {
        let cargoListWithExpanded = fields.map((v) => ({
          ...v,
          cargoBoxExpanded: false
        }))

        setCargoList(cargoListWithExpanded)
        setFieldValue('bookingCargoes', cargoListWithExpanded)
        setTotalCargoWeight(calculateTotalWeight(cargoListWithExpanded))
      }
    } else {
      // for create new booking
      createNewCargoList(cargo)
    }
  }, [props.isEdit])

  const createNewCargoList = (cargo) => {
    setCargoList([cargo])
    // setCargoField([cargo])
    setFieldValue('bookingCargoes', [cargo])
    setTotalCargoWeight(calculateTotalWeight([cargo]))
  }

  return (
    <Card>
      <Box px={2} my={1.5}>
        <Grid container>
          <AccordionHeaderComponent title={translatedTextsObject.cargo} />
        </Grid>
        {/* {'cargo' + JSON.stringify(cargoList)} */}
        <CargoDetailsComponent
          list={cargoList}
          packageUOMType={packageUOMType}
          removeRowClick={removeRowClick}
          copyRowClick={copyRowClick}
          handleCargoDetailsExpand={handleCargoDetailsExpand}
          handleInputChange={handleInputChange}
          getFieldError={getFieldError}
          handleDropdownChange={handleDropdownChange}
          cargoPackageKeyRef={cargoPackageKeyRef.current}
        />
      </Box>

      <Box>
        <Grid
          container
          justify='flex-end'
          sytle={{ paddingLeft: 10, backgroundColor: 'red' }}
        >
          <Typography>
            {translatedTextsObject.totalCargoWeight}: {totalCargoWeight}{' '}
            {translatedTextsObject.kg}
          </Typography>
        </Grid>
      </Box>

      <FooterAddComponent
        handleAddChild={handleAddCargo}
        footerText={translatedTextsObject.addCargo}
      />
    </Card>
  )
}

const initialValues = {
  bookingCargoes: [
    {
      cargoId: '',
      cargoDesc: '',
      hsCode: '',
      quantity: '',
      packageType: '',
      grossVolume: '',
      grossWeight: '',
      netVolume: '',
      netWeight: '',
      vgmWeight: '',
      cargoBoxExpanded: false
    }
  ]
}

const CargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CargoComponent
