import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import ArchiveButton from 'src/components/button/ArchiveButton.js'
import CancelButtonWithIcon from 'src/components/button/CancelButtonWithIcon.js'
import CopyButton from 'src/components/button/CopyButton.js'
import DeleteButton from 'src/components/button/DeleteButton.js'
import EditButton from 'src/components/button/EditButton.js'
import AmendButton from 'src/components/button/AmendButton'
import TrackContainerButton from 'src/components/button/TrackContainerButton.js'
import ViewSIButton from 'src/components/button/ViewSIButton.js'
import CreateSIButton from 'src/components/button/CreateSIButton.js'
import Utils from '../shared/Utils'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components


function ActionMenuBookingDetail(props) {

  const { actions, eventHandlers, booking, isViewBk } = props
  const showPrimary = false
  const translationTextObject = FreightBookingTranslationText()

  return (
    <Box pt={5}>
      <Grid container xs={12} sm={12}>
        <Grid xs={3} sm={3} justify="flex-start">
          {isViewBk ?
            (<CngSecondaryButton onClick={eventHandlers.gotoViewBKOnly}>{translationTextObject.backToViewOnlyBookingPage}</CngSecondaryButton>)
            : (<CngSecondaryButton onClick={eventHandlers.gotoMyBooking}>Back to My Booking</CngSecondaryButton>)}
        </Grid>
        {!isViewBk ? (
          <Grid container xs={9} sm={9} justify="flex-end" direction='row'>
            {
              actions.map((action, idx) => {
                switch (action) {

                  case Utils.Action.copy:
                    return <Box pr={2}>
                      <CopyButton isPrimary={showPrimary} onClick={eventHandlers.copyBooking} />
                    </Box>
                  case Utils.Action.cancel:
                    return <Box pr={2}>
                      <CancelButtonWithIcon color="secondary" onClick={eventHandlers.cancelBooking} />
                    </Box>
                  case Utils.Action.delete:
                    return <Box pr={2}>
                      <DeleteButton color="secondary" onClick={eventHandlers.deleteBooking} />
                    </Box>
                  case Utils.Action.amend:
                    return (!booking.hazardous ? <Box pr={2}>
                      <AmendButton onClick={eventHandlers.amendBooking(Utils.Page.BookingDetail)} />
                    </Box> :
                      <Box pr={2}></Box>)
                  case Utils.Action.edit:
                    return <Box pr={2}>
                      <EditButton onClick={eventHandlers.editBooking(Utils.Page.BookingDetail)} />
                    </Box>
                  case Utils.Action.archive:

                    return (!booking.closed ? <Box pr={2}>
                      <ArchiveButton onClick={eventHandlers.archiveBooking} />
                    </Box> :
                      <Box pr={2}></Box>)
                  case Utils.Action.viewSI:
                    return ((props.menuCodeList && props.menuCodeList.includes("CLOGS_SI_MY")) ?
                      <Box pr={2}>
                        <ViewSIButton onClick={eventHandlers.viewSI} />
                      </Box>
                      :
                      <Box pr={2}></Box>)
                  case Utils.Action.createSI:
                    return ((props.menuCodeList && props.menuCodeList.includes("CLOGS_SI_NEW")) ?
                      <Box pr={2}>
                        <CreateSIButton onClick={eventHandlers.createSI(Utils.Page.BookingDetail)} />
                      </Box>
                      :
                      <Box pr={2}></Box>)
                  // case Utils.Action.trackContainers:
                  //   return <Box pr={2}>
                  //     <TrackContainerButton isPrimary={false} onClick={eventHandlers.trackContainers} />
                  //   </Box>
                  default:
                    return ''
                }
              })
            }
          </Grid>
        ) : (
          <Grid
            container
            xs={9}
            sm={9}
            justify='flex-end'
            direction='row'
          ></Grid>
        )}
      </Grid>
    </Box>
  )
}


export default ActionMenuBookingDetail