import React, { useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

export default function BookingActionMenuItem(props) {
  const {
    icon,
    label,
    action,
    notFreeze
  } = props

  const [disabled, setDisabled] = useState(false)
  const handleClick = () => {
    action();
    setDisabled(true)
  }

  return (
    <MenuItem onClick={handleClick} disabled={disabled && !notFreeze}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <Typography variant='inherit' style={{ fontSize: 'small' }}>
        {label}
      </Typography>
    </MenuItem>
  )
}
