import {
    Box, Card, Grid, Typography, Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from './ComponentWrapper'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import LabelValueVertical from './LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AuditTrailRowOfChange from 'src/components/auditTrail/AuditTrailRowOfChange'
import AuditTrailRowOfChangeComplex from 'src/components/auditTrail/AuditTrailRowOfChangeComplex'
import AuditTrailRowOfChangeReefer from 'src/components/auditTrail/AuditTrailRowOfChangeReefer'

import Api from '../shared/api'
const {
    CngGridItem,
    button: { CngPrimaryButton, CngSecondaryButton },
} = components

const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bolder"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const AuditTrailSummary = (props) => {
    const { ports, booking } = props
    const classes = useStyles();
    const tranObj = FreightBookingTranslationText()
    const calTranObj = CalistaUiComponentTranslationText()

    //console.log('booking.containers.added: ', booking && booking.containers && booking.containers.added ? booking.containers.added : '')
    const RowOfChange = (props) => {
        return (
            <TableRow>
                <StyledTableCell>{tranObj.changesDetected}</StyledTableCell>
            </TableRow>
        )
    }

    return (
        <div>
            <Grid container xs={12} sm={12}>
                <CngGridItem xs={12} sm={12}>
                    <Card>
                        <TableContainer component={Paper} style={{ padding: 0 }}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{tranObj.changesDetected}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <AuditTrailRowOfChange object={booking} field={'carRemarks'} label={tranObj.carrierComments} />
                                    <AuditTrailRowOfChange object={booking} field={'shpRemarks'} label={tranObj.shipperComments} />
                                    <AuditTrailRowOfChange object={booking} field={'carRefNo'} label={tranObj.bookingRefNo} />
                                    {/* Schedule */}

                                    <AuditTrailRowOfChange object={booking} field={'vesselName'} ports={ports} label={tranObj.vesselName} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'voyageNo'} ports={ports} label={tranObj.voyageNumber} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'intVoyageNo'} ports={ports} label={tranObj.internationalVoyageNumber} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'portOfLoadingCode'} ports={ports} label={tranObj.portOfLoading} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'portOfDischargeCode'} ports={ports} label={tranObj.portOfDischarge} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'polEta'} label={tranObj.polPlannedEta} timestamp group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'podEta'} label={tranObj.podPlannedEta} timestamp group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'polEtd'} label={tranObj.polPlannedEtd} timestamp group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'podEtd'} label={tranObj.podPlannedEtd} timestamp group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'portOfLoadingTerminal'} label={tranObj.loadTerminal} group={tranObj.schedule} />
                                    <AuditTrailRowOfChange object={booking} field={'portOfDischargeTerminal'} label={tranObj.dischargeTerminal} group={tranObj.schedule} />

                                    {/* Shipment */}
                                    <AuditTrailRowOfChange object={booking} field={'moveType'} label={tranObj.moveType} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfReceiptCode'} ports={ports} label={tranObj.placeOfReceipt} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfDeliveryCode'} ports={ports} label={tranObj.placeOfDelivery} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'cargoReadyDate'} label={tranObj.cargoReadyDate} timestamp group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'cargoDeliveryDate'} label={tranObj.cargoDeliveryDate} timestamp group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfReceipt'} label={tranObj.placeOfReceiptDesc} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfDelivery'} label={tranObj.placeOfDeliveryDesc} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfReceiptEtd'} label={tranObj.placeOfReceiptETD} timestamp group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'placeOfDeliveryEta'} label={tranObj.placeOfDeliveryETA} timestamp group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'destCode'} label={tranObj.destinationCode} group={tranObj.shipment} />
                                    <AuditTrailRowOfChange object={booking} field={'finalDest'} label={tranObj.finalDestination} group={tranObj.shipment} />

                                    <AuditTrailRowOfChange object={booking} field={'emptyPickupCompName'} label={tranObj.compName} group={tranObj.shipment} subGroup={tranObj.emptyPickup} />
                                    <AuditTrailRowOfChange object={booking} field={'emptyPickupCompAddress'} label={tranObj.compAddress} group={tranObj.shipment} subGroup={tranObj.emptyPickup} />
                                    <AuditTrailRowOfChange object={booking} field={'emptyPickupContactName'} label={tranObj.ContactName} group={tranObj.shipment} subGroup={tranObj.emptyPickup} />
                                    <AuditTrailRowOfChange object={booking} field={'emptyPickupContactPhoneNo'} label={tranObj.ContactPhoneNo} group={tranObj.shipment} subGroup={tranObj.emptyPickup} />
                                    <AuditTrailRowOfChange object={booking} field={'emptyPickupDatePickup'} label={tranObj.emptyPickupDateTime} timestamp group={tranObj.shipment} subGroup={tranObj.emptyPickup} />

                                    <AuditTrailRowOfChange object={booking} field={'shipFromCompName'} label={tranObj.compName} group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipFromCompAddress'} label={tranObj.compAddress} group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipFromContactName'} label={tranObj.ContactName} group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipFromContactPhoneNo'} label={tranObj.ContactPhoneNo} group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipFromDatePickup'} label={tranObj.shipFromDatePickup} timestamp group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipFromDatePositioning'} label={tranObj.shipFromDatePositioning} timestamp group={tranObj.shipment} subGroup={tranObj.shipFrom} />

                                    <AuditTrailRowOfChange object={booking} field={'shipToCompName'} label={tranObj.compName} group={tranObj.shipment} subGroup={tranObj.shipFrom} />
                                    <AuditTrailRowOfChange object={booking} field={'shipToCompAddress'} label={tranObj.compAddress} group={tranObj.shipment} subGroup={tranObj.shipTo} />
                                    <AuditTrailRowOfChange object={booking} field={'shipToContactName'} label={tranObj.ContactName} group={tranObj.shipment} subGroup={tranObj.shipTo} />
                                    <AuditTrailRowOfChange object={booking} field={'shipToContactPhoneNo'} label={tranObj.ContactPhoneNo} group={tranObj.shipment} subGroup={tranObj.shipTo} />
                                    <AuditTrailRowOfChange object={booking} field={'shipToDateDelivery'} label={tranObj.shipToDateDelivery} timestamp group={tranObj.shipment} subGroup={tranObj.shipTo} />

                                    {/* Contact */}
                                    <AuditTrailRowOfChange object={booking} field={'repName'} label={tranObj.representativeName} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'repEmail'} label={tranObj.representativeEmail} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'repContactNo'} label={tranObj.representativeContactNumber} group={tranObj.contact} />

                                    <AuditTrailRowOfChange object={booking} field={'contractNo'} label={tranObj.contractNumber} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'commonRef'} label={tranObj.purchaseOrderNumber} group={tranObj.contact} />

                                    <AuditTrailRowOfChange object={booking} field={'shpRefNo'} label={tranObj.shipperRefNumber} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'fwdRefNo'} label={tranObj.forwarderRefNumber} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'preAssBkRefNo'} label={tranObj.preAssignmentBookingNumber} group={tranObj.contact} />

                                    <AuditTrailRowOfChange object={booking} field={'contractPartyNo'} label={tranObj.contractPartyName} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'mainNotifyParty'} label={tranObj.mainNotifyParty} group={tranObj.contact} />
                                    {/* <AuditTrailRowOfChange object={booking} field={'shpName'} label = {tranObj.shipperName} group={tranObj.contact}/> */}
                                    <AuditTrailRowOfChange object={booking} field={'consigneeName'} label={tranObj.consigneeName} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'forwarderName'} label={tranObj.forwarderName} group={tranObj.contact} />
                                    <AuditTrailRowOfChange object={booking} field={'preshipContact'} label={tranObj.preShipmentNotificationContacts} group={tranObj.contact} />

                                    {
                                        booking && booking.containers && console.log('mani AuditTrailSummary', booking.containers)
                                    }

                                    {
                                        booking && booking.containers && booking.containers.added &&
                                        booking.containers.added.map((container, i) =>
                                            <>
                                                <AuditTrailRowOfChangeComplex object={container} added fields={['contQty', 'contIsoType']} delimiter={' x '} group={tranObj.containerDetails} label={calTranObj.added} />
                                                <AuditTrailRowOfChangeReefer object={container} added field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.added} />
                                                <AuditTrailRowOfChangeReefer object={container} added field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.added} />
                                                <AuditTrailRowOfChangeReefer object={container} added field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.added} />
                                            </>
                                        )
                                    }

                                    {
                                        booking && booking.containers && booking.containers.removed &&
                                        booking.containers.removed.map((container, i) =>
                                            <>
                                                <AuditTrailRowOfChangeComplex removed object={container} fields={['contQty', 'contIsoType']} delimiter={' x '} group={tranObj.containerDetails} label={calTranObj.removed} />
                                                <AuditTrailRowOfChangeReefer removed object={container} field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.removed} />
                                                <AuditTrailRowOfChangeReefer removed object={container} field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.removed} />
                                                <AuditTrailRowOfChangeReefer removed object={container} field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.removed} />
                                            </>
                                        )
                                    }

                                    {
                                        booking && booking.containers && booking.containers.updated &&
                                        booking.containers.updated.map((container, i) =>
                                            <>
                                                <AuditTrailRowOfChangeComplex object={container} fields={['contQty', 'contIsoType']} delimiter={' x '} group={tranObj.containerDetails} label={calTranObj.updated} />
                                                <AuditTrailRowOfChangeReefer object={container} field={'nonActiveReefer'} group={tranObj.reeferDetails} label={calTranObj.updated} />
                                                <AuditTrailRowOfChangeReefer object={container} field={'equipmentTemp'} group={tranObj.reeferDetails} label={calTranObj.updated} />
                                                <AuditTrailRowOfChangeReefer object={container} field={'tempUom'} group={tranObj.reeferDetails} label={calTranObj.updated} />

                                            </>
                                        )
                                    }

                                    {
                                        booking && booking.documents && booking.documents.added &&
                                        booking.documents.added.map((document, i) =>

                                            <AuditTrailRowOfChangeComplex object={document} added fields={['filename']} group={tranObj.documents} label={calTranObj.added} />
                                        )
                                    }
                                    {
                                        booking && booking.documents && booking.documents.removed &&
                                        booking.documents.removed.map((document, i) =>

                                            <AuditTrailRowOfChangeComplex removed object={document} fields={['filename']} group={tranObj.documents} label={calTranObj.removed} />
                                        )
                                    }
                                    {
                                        booking && booking.documents && booking.documents.updated &&
                                        booking.documents.updated.map((document, i) =>
                                            <AuditTrailRowOfChangeComplex object={document} fields={['filename']} group={tranObj.documents} label={calTranObj.updated} />
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </CngGridItem>
            </Grid>
        </div>
    );

}


export default AuditTrailSummary
