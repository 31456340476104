import { Box } from '@material-ui/core'
import React from 'react'

import Utils from 'src/views/common/utils/Utils'

const ViewLastModifiedComponent = (props) => {
    const {text, date} = props  

    return (
            <Box>
                {text} <b>{Utils.formatDate(date, Utils.FORMAT_LONG_DATE_TIME, Utils.DASH)}</b>
            </Box>
    )
}

export default ViewLastModifiedComponent
