import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from './ComponentWrapper'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    CngGridItem
} = components

const FormBody = (props) => {
    const { booking } = props
    const translatedTextsObject = FreightBookingTranslationText()


    return (
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className="view-card-header">
                        {translatedTextsObject.approval}
                    </Box>
                    <Box>
                        <Grid container xs={12} sm={12} spacing={0}>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.bookingRefenceNumber}
                                    value={booking.carrierRefNo} />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* <LabelValueVertical label={translatedTextsObject.bookingStatus}
                                    value={booking.bookStatusDesc} /> */}
                            </CngGridItem>
                        </Grid>
                        <Grid container xs={12} sm={12} spacing={0}>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.shipperRemarks}
                                    value={booking.shipperRemarks} />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.carrierRemarks}
                                    value={booking.carrierRemarks} />
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({

})

const ViewApprovalComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewApprovalComponent
