import React, { useState, useMemo } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ReeferDetailsContext from './ReeferDetailsContext.js'
import ReeferDetailsForm from './ReeferDetailsForm.js'
import { components } from 'cng-web-lib'


const { CngDialog, CngDialogTitle } = components

function ReeferDetailsDialog({
  isDialogOpen,
  closeDialog,
  currentContainerIdx,
  containerList,
  setContainerField,
  showNotification,
  currentReeferDetailsId,
  showLocalCategory,
  isCarrierAmend
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const value = useMemo(() => {
    return {
      closeDialog,
      showNotification,
      form: {
        isSubmitting,
        setSubmitting
      },
      currentContainerIdx,
      containerList,
      setContainerField,
      currentReeferDetailsId,
      showLocalCategory,
      isCarrierAmend
    }
  }, [closeDialog, showNotification, isSubmitting, currentContainerIdx, containerList, setContainerField, currentReeferDetailsId, showLocalCategory, isCarrierAmend])

  return (
    <ReeferDetailsContext.Provider
      value={value}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ReeferDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            {
              (containerList != null
                && containerList[currentContainerIdx] != null
                && containerList[currentContainerIdx].containerIsoType != null
              ) ? (
                <b>{'Reefer Details for Container ' + containerList[currentContainerIdx].containerIsoType}</b>
              )
                :
                (
                  <b>{'Reefer Details for Container'}</b>
                )
            }
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='lg'
      />
    </ReeferDetailsContext.Provider>
  )
}

export default ReeferDetailsDialog
