import * as CalistaConstant from 'src/constants/CalistaConstant'

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import React from 'react'

const StatusIconComponent = (props) => {
    const {bookingStatus} = props

    if(bookingStatus == CalistaConstant.BOOKING_STATUS_APPROVED){
        return (<CheckCircleOutlineOutlinedIcon className='light-green'/>)
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_REJECTED){
        return (<CancelOutlinedIcon style={{color: "red"}} />)
    }else if(bookingStatus == CalistaConstant.BOOKING_STATUS_CLOSED){
        return (<BlockOutlinedIcon style={{color: "red"}} />)
    }else{
        return null
    }
    
}

export default StatusIconComponent
