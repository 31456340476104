import { Box, Card, Grid } from '@material-ui/core'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'

const {
    CngGridItem
} = components

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()

    return (
        // <Grid container xs={12} sm={12}>
        <CngGridItem xs={6} sm={6}>
            {!Utils.isEmptyString(props.remarks) && (
                <Card style={{ height: '100%' }}>
                    <Box className="view-card-header">
                        {props.title}
                    </Box>
                    <Box>
                        <Grid container xs={12} sm={12}>
                            <Box className="view-card-content">
                                <Box className="view-card-content-value" style={{ wordBreak: 'break-word', whiteSpace: "pre-line" }}>
                                    {props.remarks}
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </Card>
            )}
        </CngGridItem>

        // </Grid>
    )
}


const initialValues = Object.freeze({

})

const ViewRemarksComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewRemarksComponent
