import { Box, Card, Grid, Chip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from './ComponentWrapper'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    form : {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngCheckboxField
        }
    },
    CngGridItem
} = components

const FormBody = (props) => {
    const { contactData,auditBooking} = props
    const translatedTextsObject = FreightBookingTranslationText()
    // const [moveTypeField, , { setValue: setMoveTypeField }] = useField('moveType')
    // const myMoveType = contactData.moveType

    const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCdeField }] = useField('placeOfReceiptCode')
    const [placeOfDeliveryCodeField, , { setValue: setPlaceOfDeliveryCodeField }] = useField('placeOfDeliveryCode')

    const placeOfReceiptKeyRef = useRef(uuid())
    const placeOfDeliveryKeyRef = useRef(uuid())
    const moveTypeKeyRef = useRef(uuid())

    const { setFieldValue } = useFormikContext()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {
        // setMoveTypeField(contactData.moveType)
        setPlaceOfReceiptCdeField(contactData.placeOfReceiptCode)
        setPlaceOfDeliveryCodeField(contactData.placeOfDeliveryCode)

        placeOfReceiptKeyRef.current = uuid()
        placeOfDeliveryKeyRef.current = uuid()
        moveTypeKeyRef.current = uuid()

        setFieldValue('moveType', contactData.moveType)

        setShouldRender(true)
    }, [])

    if (!shouldRender) {
        return null;
    }

    return (
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className="view-card-header">
                        {translatedTextsObject.contactDetails}
                    </Box>
                    <Box>
                        <Grid container xs={12} sm={12} spacing={0}>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.representativeName} 
                                                    value={contactData.representativeName}
                                                    boxStyle={{...Utils.auditHighLight(auditBooking, ['repName']), display: 'inline-block'}}/>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.representativeEmail} 
                                                    value={contactData.representativeEmail}
                                                    boxStyle={{...Utils.auditHighLight(auditBooking, ['repEmail']), display: 'inline-block'}}/>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical label={translatedTextsObject.representativeContactNumber} 
                                                    value={contactData.representativeContactNo}
                                                    boxStyle={{...Utils.auditHighLight(auditBooking, ['repContactNo']), display: 'inline-block'}}/>
                            </CngGridItem>
                        </Grid>

                        <ComponentWrapper header={translatedTextsObject.references} headerStyle="view-card-header-no-bg"
                            cardStyle={{ margin: 20 }} backgroundStyle="inner-card-background">
                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs sm>   
                                </Grid>    
                                <Grid item xs={4} sm={4}>     
                                            <LabelValueVertical label={translatedTextsObject.contractNumber} 
                                                                value={contactData.contractNo} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['contractNo']), display: 'inline-block'}}/>
                                </Grid>
                                <Grid item xs={4} sm={4}>          
                                            <LabelValueVertical label={translatedTextsObject.purchaseOrderNumber}  
                                                                value={contactData.commonRef} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['commonRef']), display: 'inline-block'}}/>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs sm>   
                                </Grid>    
                                <Grid item xs={4} sm={4}>          
                                            <LabelValueVertical label={translatedTextsObject.shipperRefNumber} 
                                                                value={contactData.shipperRefNo}  
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['shpRefNo']), display: 'inline-block'}}/>
                                </Grid>
                                <Grid item xs={4} sm={4}>            
                                            <LabelValueVertical label={translatedTextsObject.forwarderRefNumber} 
                                                                value={contactData.forwarderRefNo} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['fwdRefNo']), display: 'inline-block'}}/>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs sm>   
                                </Grid>    
                                <Grid item xs={4} sm={4}>            
                                            <LabelValueVertical label={translatedTextsObject.preAssignmentBookingNumber} 
                                                                value={contactData.preAssBkRefNo} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['preAssBkRefNo']), display: 'inline-block'}}/>
                                </Grid>
                            </Grid>
                        </ComponentWrapper>

                        <ComponentWrapper header={translatedTextsObject.parties} headerStyle="view-card-header-no-bg"
                            cardStyle={{ margin: 20 }} backgroundStyle="inner-card-background">
                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs sm>   
                                </Grid>    
                                <Grid item xs={4} sm={4}>     
                                            <LabelValueVertical label={translatedTextsObject.contractPartyName} 
                                                                value={contactData.contractPartyName} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['contractPartyNo']), display: 'inline-block'}}/>
                                </Grid>
                                <Grid item xs={4} sm={4}>          
                                            <LabelValueVertical label={translatedTextsObject.mainNotifyParty}  
                                                                value={contactData.mainNotifyParty} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['mainNotifyParty']), display: 'inline-block'}}/>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs={4} sm={4}>          
                                            <LabelValueVertical label={translatedTextsObject.shipperName} 
                                                                value={contactData.shipperName}  
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['shpName']), display: 'inline-block'}}/>
                                </Grid>    
                                <Grid item xs={4} sm={4}>          
                                            <LabelValueVertical label={translatedTextsObject.consigneeName} 
                                                                value={contactData.consigneeName} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['consigneeName']), display: 'inline-block'}} />
                                </Grid>
                                <Grid item xs={4} sm={4}>            
                                            <LabelValueVertical label={translatedTextsObject.forwarderName} 
                                                                value={contactData.forwarderName} 
                                                                boxStyle={{...Utils.auditHighLight(auditBooking, ['forwarderName']), display: 'inline-block'}}/>
                                </Grid>
                            </Grid>

                        </ComponentWrapper>
                        <ComponentWrapper header={translatedTextsObject.partnerEmailNtf} headerStyle="view-card-header-no-bg"
                            cardStyle={{ margin: 20 }} backgroundStyle="inner-card-background">
                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs sm>   
                                </Grid>    
                                <Grid item xs={8} sm={8}>   
                                    <Box className='view-card-content'>
                                        <Box className='view-card-content-label'>
                                            {translatedTextsObject.emailToNotify}
                                        </Box>
                                        <Box
                                        className='view-card-content-value'
                                        style={{ paddingRight: 0 }}
                                        >
                                            <div>
                                            { contactData.partnerEmails && contactData.partnerEmails.length > 0? contactData.partnerEmails.map((email) => (
                                                <span className='pr-10'>
                                                    <Chip label={email} size='small' />
                                                </span>
                                            ))
                                            :
                                            'N/A'}
                                            </div>
                                        </Box>
                                    </Box>  
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sm={12} spacing={0}>
                                <Grid item xs={4} sm={4}>          
                                </Grid>    
                                <Grid item xs={8} sm={8} >
                                <Box pl={2}> 
                                    <CngCheckboxField   
                                        name='notifyPartnerEmails'
                                        checked={JSON.stringify(contactData.notifyPartnerEmails) == "true" ? true : false}
                                        label={translatedTextsObject.notifyBookingStatus}
                                        disabled={true}
                                    />
                                </Box>
                                <Box pl={2}>
                                    <CngCheckboxField
                                        name='notifyEventMilestonesEmail'
                                        checked={JSON.stringify(contactData.notifyEventMilestonesEmail) == "true" ? true : false}
                                        label={translatedTextsObject.notifyEventMilestones}
                                        disabled={true}
                                    />
                                </Box>
                                </Grid>
                            </Grid>

                        </ComponentWrapper>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({
    moveType: "PTP"//myMoveType,

})

const ViewContactComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ViewContactComponent
