import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CarrierAutocompleteField from 'src/components/autocomplete/CarrierAutocompleteField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import { v4 as uuid } from 'uuid'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
// import useFetchCountryCodes from 'src/views/vesselschedule/searchschedule/useFetchCountryCodes'

const {
    form: {
        field: {
            CngTextField,
            CngDateField
        },
    },
    CngGridItem,
} = components

const AuditTrailSchedule = (props) => {
    const {booking} = props
    const tranObj = FreightBookingTranslationText()
    const [scheduleExpanded, setScheduleExpanded] = useState(false)

    const handleScheduleExpand = () => {
        setScheduleExpanded(!scheduleExpanded);
    };

    return (
        <Card>
            <Accordion expanded={scheduleExpanded}>
                <AccordionSummary aria-controls="contactComponentContent" id='contactComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={tranObj.contact} hideMandatory/>                        
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12}>
                        <AuditTrailDualColumn object={booking} field={'repName'} label = {tranObj.representativeName} />
                        <AuditTrailDualColumn object={booking} field={'repEmail'} label = {tranObj.representativeEmail} />
                        <AuditTrailDualColumn object={booking} field={'repContactNo'} label = {tranObj.representativeContactNumber} />

                        <AuditTrailDualColumn object={booking} field={'contractNo'} label = {tranObj.contractNumber} />
                        <AuditTrailDualColumn object={booking} field={'commonRef'} label = {tranObj.purchaseOrderNumber} />
                        <AuditTrailDualColumn object={booking} field={'shpRefNo'} label = {tranObj.shipperRefNumber} />
                        <AuditTrailDualColumn object={booking} field={'fwdRefNo'} label = {tranObj.forwarderRefNumber} />
                        <AuditTrailDualColumn object={booking} field={'preAssBkRefNo'} label = {tranObj.preAssignmentBookingNumber} />

                        <AuditTrailDualColumn object={booking} field={'contractPartyNo'} label = {tranObj.contractPartyName} />
                        <AuditTrailDualColumn object={booking} field={'mainNotifyParty'} label = {tranObj.mainNotifyParty} />    
                        <AuditTrailDualColumn object={booking} field={'shpName'} label = {tranObj.shipperName} />
                        <AuditTrailDualColumn object={booking} field={'consigneeName'} label = {tranObj.consigneeName} />    
                        <AuditTrailDualColumn object={booking} field={'forwarderName'} label = {tranObj.forwarderName} />
                        <AuditTrailDualColumn object={booking} field={'preshipContact'} label = {tranObj.preShipmentNotificationContacts} /> 
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleScheduleExpand}
                expanded={scheduleExpanded}
                footerText={tranObj.showContactDetails}
                footerTextExpanded={tranObj.hideContactDetails}
                noOptionalFields
            />
        </Card>
    )
}

export default AuditTrailSchedule