import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useContext, useEffect, useState } from 'react'

import { Box, Card, Grid, Chip } from '@material-ui/core'
import CancelButton from 'src/components/button/CancelButton.js'
import DGCargoDetailsContext from './DGCargoDetailsContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { Typography } from '@material-ui/core'
import check from 'check-types'
import moment from 'moment'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import useDGCargoDetailsMakeValidationSchema from 'src/views/freightbooking/newbooking/useDGCargoDetailsMakeValidationSchema'
import LabelValueVertical from './LabelValueVertical'
import ComponentWrapper from './ComponentWrapper'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import Utils from 'src/views/common/utils/Utils'
import CheckIcon from '@material-ui/icons/Check'
import DGCargoDetailsPkgUomViewComponent from './DGCargoDetailsPkgUomViewComponent'
import ViewCheckBoxGroupComponent from './ViewCheckBoxGroupComponent'
import { makeStyles } from '@material-ui/core/styles'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'

const {
  CngGridItem,
  form: { CngViewForm },
  button: { CngPrimaryButton }
} = components

const useStyles = makeStyles((theme) => ({
  highlightField: {
    height: '100%',
    backgroundColor: '#5E81F405'
  }
}))

function ViewDGCargoDetailsForm(props) {
  const dialogContext = useContext(DGCargoDetailsContext)

  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    currentDGDetails,
    uomItems,
    packageUomItems,
    currentAudit
  } = dialogContext

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          // ...initialValues
        },
        // validationSchema: validationSchema,
        // onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentDGDetails={currentDGDetails}
          uomItems={uomItems}
          packageUomItems={packageUomItems}
          currentAudit={currentAudit}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentDGDetails,
  uomItems,
  packageUomItems,
  currentAudit
}) {
  const translatedTextsObject = FreightBookingTranslationText()
  const calistaUITranslatedTextObject = CalistaUiComponentTranslationText()
  const classes = useStyles()
  const [dateOfFumigationDisplay, setDateOfFumigationDisplay] = useState()
  const [highlight, setHighlight] = useState([])

  useEffect(() => {
    console.log('in dialog now')
    console.log('currentAudit', currentAudit)
    console.log(currentDGDetails)
    if (currentDGDetails != null) {
      populateDGCargoFields()
    }
  }, [currentDGDetails])

  useEffect(() => {
    //  console.log('in dialog now')
    console.log('currentAudit', currentAudit)
    if (currentAudit && currentAudit.length > 0) {
      let auditDGDetails = currentAudit[0]
      if (auditDGDetails.added) {
        auditDGDetails._changes_ = auditDGDetails.added
      }
      let hl = SIUtils.auditHighLightDGCargoDetails(currentAudit[0])
      setHighlight(hl)
      console.log('currentAudit hl', hl)
    }
  }, [currentAudit])

  function getHighlight(field) {
    return Utils.isNotEmpty(highlight) ? highlight[field] : {}
    // return { background: '#F4BE5E0F 0% 0% no-repeat padding-box' }
  }

  function populateDGCargoFields() {
    setDateOfFumigationDisplay(
      currentDGDetails.dateOfFumigation != ''
        ? Utils.formatString(
            currentDGDetails.dateOfFumigation,
            Utils.UI_FORMAT_DATE_TIME
          )
        : '-'
    )
    // currentDGDetails.dateOfFumigationDisplay = currentDGDetails.dateOfFumigation != "" ? Utils.formatString(currentDGDetails.dateOfFumigation, Utils.UI_FORMAT_DATE_TIME) : "-"
    // currentDGDetails.dateOfFumigationDisplay = Utils.formatString(currentDGDetails.dateOfFumigation, Utils.UI_FORMAT_DATE_TIME)
  }

  function getPackageUomDesc(packageUomCode) {
    console.log('packageUomItems' + packageUomCode)
    console.log(packageUomItems)

    let uomDesc = '-'
    packageUomItems
      .filter((uomItem) => uomItem.code == packageUomCode)
      .map((selected) => {
        uomDesc = selected.descriptionEn
      })

    return uomDesc
  }

  function getDGUOMDesc(uomCode) {
    console.log('getDGUOMDesc' + uomCode)
    console.log(uomItems)

    let uomDesc = '-'
    uomItems
      .filter((uomItem) => uomItem.code == uomCode)
      .map((selected) => {
        uomDesc = selected.descriptionEn
      })

    return uomDesc
  }

  function isStringEmpty(str) {
    return str == '' || str == null
  }

  function parseNumberAndUom(number, uom) {
    if (isStringEmpty(number) && isStringEmpty(uom)) {
      return '-'
    } else {
      return (isStringEmpty(number) ? '-' : number) + ' ' + getDGUOMDesc(uom)
    }
  }

  const additionalHazmatSettings = [
    {
      checked: currentDGDetails.inhalantHazard,
      label: translatedTextsObject.inhalantHazard
    },
    {
      checked: currentDGDetails.emptyUncleanedReceptable,
      label: translatedTextsObject.emptyUncleanedReceptacle
    },
    {
      checked: currentDGDetails.transportDgInLimitedQty,
      label: translatedTextsObject.transportOfDangerousGoodsInLimitedQuantities
    }
  ]

  const miscSettings = [
    {
      checked: currentDGDetails.reportableQty,
      label: translatedTextsObject.reportableQuantity
    },
    {
      checked: currentDGDetails.weightKgs,
      label: translatedTextsObject.weightKgs
    },
    {
      checked: currentDGDetails.weightLbs,
      label: translatedTextsObject.weightLbs
    },
    {
      checked: currentDGDetails.netExplosiveContent,
      label: translatedTextsObject.netExplosiveContent
    }
  ]

  return (
    <Box m={1.5}>
      <Box pt={5}>
        <ComponentWrapper
          header={translatedTextsObject.classificationIdentication}
          headerStyle='view-card-header-no-bg'
        >
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}>
              <ComponentWrapper
                backgroundStyle={classes.highlightField}
                cardStyle={getHighlight('imoClass')}
                //backgroundStyle={getHighlight('imoClass')}
                //cardStyle={{ height: '100%' }}
              >
                <Grid item xs={12} sm={12}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Grid item xs={12} sm={12}>
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.imoClass}
                        </Box>
                      </Grid>
                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_IMO'
                          code={currentDGDetails.imoClass}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                backgroundStyle={classes.highlightField}
                cardStyle={{ height: '100%', ...getHighlight('undgNumber') }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.undgNumber}
                    value={currentDGDetails.undgNumber}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                backgroundStyle={classes.highlightField}
                cardStyle={{
                  height: '100%',
                  ...getHighlight('properShippingName')
                }}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.shippingName}
                    value={currentDGDetails.properShippingName}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('technicalName')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.technicalName}
                    value={currentDGDetails.technicalName}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('imdgCodePage')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.IMDGCodePage}
                    value={currentDGDetails.imdgCodePage}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs={4} sm={4} style={{ paddingLeft: 20 }}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('imdgCodePage')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.regulationPageNumber}
                    value={currentDGDetails.regulationPageNumber}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('regulationVersionNumber')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.regulationVersionNumber}
                    value={currentDGDetails.regulationVersionNumber}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper noBorder cardStyle={getHighlight('emsNumber')}>
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.EMSNumber}
                    value={currentDGDetails.emsNumber}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
        </ComponentWrapper>
      </Box>

      <Box pt={5}>
        <ComponentWrapper
          header={translatedTextsObject.packagingWeight}
          headerStyle='view-card-header-no-bg'
        >
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                cardStyle={{
                  ...getHighlight('outerPkgQty'),
                  ...getHighlight('outerPkgQtyUom')
                }}
              >
                <Grid item xs={12} sm={12}>
                  <DGCargoDetailsPkgUomViewComponent
                    backgroundClass={classes.highlightField}
                    label={translatedTextsObject.outerPkgQTY}
                    value={currentDGDetails.outerPackingQty}
                    uom={currentDGDetails.outerPackingQtyUom}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                cardStyle={{
                  ...getHighlight('innerPkgQty'),
                  ...getHighlight('innerPkgQtyUom')
                }}
              >
                <Grid item xs={12} sm={12}>
                  <DGCargoDetailsPkgUomViewComponent
                    backgroundClass={classes.highlightField}
                    label={translatedTextsObject.innerPkgQTY}
                    value={currentDGDetails.innerPackingQty}
                    uom={currentDGDetails.innerPackingQtyUom}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('limitedQTY'),
                  ...getHighlight('limitedQtyUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.limitedQTY}
                    value={
                      isStringEmpty(currentDGDetails.limitedQty)
                        ? '-'
                        : currentDGDetails.limitedQty +
                          ' ' +
                          getPackageUomDesc(currentDGDetails.limitedQtyUom)
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('expectedQTY'),
                  ...getHighlight('exceptedQtyUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.expectedQTY}
                    value={
                      isStringEmpty(currentDGDetails.exceptedQty)
                        ? '-'
                        : currentDGDetails.exceptedQty +
                          ' ' +
                          getPackageUomDesc(currentDGDetails.exceptedQtyUom)
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper noBorder cardStyle={getHighlight('netWeight')}>
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.netWeight}
                    value={
                      isStringEmpty(currentDGDetails.netWeight)
                        ? '-'
                        : currentDGDetails.netWeight + ' KG'
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('grossWeight')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.dgGrossWeight}
                    value={
                      isStringEmpty(currentDGDetails.grossWeight)
                        ? '-'
                        : currentDGDetails.grossWeight + ' KG'
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper noBorder cardStyle={getHighlight('netVolume')}>
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.netVolume}
                    value={
                      isStringEmpty(currentDGDetails.netVolume)
                        ? '-'
                        : currentDGDetails.netVolume + ' CBM'
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('grossVolume')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.grossVolume}
                    value={
                      isStringEmpty(currentDGDetails.grossVolume)
                        ? '-'
                        : currentDGDetails.grossVolume + ' CMB'
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper noBorder cardStyle={getHighlight('vgmWeight')}>
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.vgmWeight}
                    value={currentDGDetails.vgmWeight}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper noBorder cardStyle={getHighlight('quantity')}>
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.quantity}
                    value={currentDGDetails.quantity}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('packingGroupCode')}
              >
                <Grid item xs={12} sm={12}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Grid item xs={12} sm={12}>
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.packingGroup}
                        </Box>
                      </Grid>
                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_PACKING'
                          code={currentDGDetails.packingGroup}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('ibcPackageCode')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.ibcPackageCode}
                    value={currentDGDetails.ibcPackageCode}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm style={{ paddingLeft: 20 }}></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('packingDetails')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.packagingDetails}
                    value={currentDGDetails.packingDetails}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
        </ComponentWrapper>
      </Box>

      <Box pt={5}>
        <ComponentWrapper
          header={translatedTextsObject.forEmergency}
          headerStyle='view-card-header-no-bg'
        >
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                backgroundStyle={classes.highlightField}
                cardStyle={getHighlight('emergencyContactName')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.emergencyContact}
                    value={currentDGDetails.emergencyContact}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                backgroundStyle={classes.highlightField}
                cardStyle={getHighlight('emergencyContactNumber')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.emergencyContactNumber}
                    value={currentDGDetails.emergencyContactNo}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
        </ComponentWrapper>
      </Box>

      <Box pt={5}>
        <ComponentWrapper
          header={translatedTextsObject.hazardIdentification}
          headerStyle='view-card-header-no-bg'
        >
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('marinePollutantStatus')}
              >
                <Grid item xs={12} sm={12}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Grid item xs={12} sm={12}>
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.marinePollutantStatus}
                        </Box>
                      </Grid>
                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_MARINE'
                          code={currentDGDetails.marinePollutantStatus}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('radioactiveMeasurement')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.radioactiveMeasurement}
                    value={currentDGDetails.radioactiveMeasurement}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('acidConcentration')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.acidConcentration}
                    value={currentDGDetails.acidConcentration}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('tremCardNumber')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.tremCardNo}
                    value={currentDGDetails.tremCardNumber}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('regulatoryInfomation')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.regulatoryInfo}
                    value={currentDGDetails.regulatoryInfo}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('radioactiveGoodsInfo')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.radioactiveGoodsInfo}
                    value={currentDGDetails.radioactiveGoodsInfo}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('placardInformation')}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.placardInfo}
                    value={currentDGDetails.placardInformation}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8} style={{ paddingLeft: 25 }}>
              <ComponentWrapper
                noBorder
                // cardStyle={getHighlight('inhalantHazard')}
              >
                {/* <Grid item xs={12} sm={12} > */}

                <ViewCheckBoxGroupComponent
                  headerLabel={translatedTextsObject.additionalHazmatSettings}
                  checkBoxGroup={additionalHazmatSettings}
                  cardStyle={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    ...getHighlight('inhalantHazard'),
                    ...getHighlight('emptyUncleanedRecept'),
                    ...getHighlight('transportDgLimitQuant')
                  }}
                />
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('poisonInhalationHazardZone')}
              >
                <Grid item xs={6} sm={6}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Box className='view-card-content-label'>
                        {translatedTextsObject.poisonInhalationHazardZone}
                      </Box>

                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_PSN_INH_HZD_ZN'
                          code={currentDGDetails.poisonInhalation}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('aggregationState')}
              >
                <Grid item xs={4} sm={4}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Box className='view-card-content-label'>
                        {translatedTextsObject.aggregationState}
                      </Box>

                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_AGGRE'
                          code={currentDGDetails.aggregationState}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8} style={{ paddingLeft: 25 }}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('tremCardNumber')}
              >
                <ViewCheckBoxGroupComponent
                  checkBoxGroup={miscSettings}
                  cardStyle={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    ...getHighlight('reportableQty'),
                    ...getHighlight('weightKgs'),
                    ...getHighlight('weightLbs'),
                    ...getHighlight('netExplosiveContent')
                  }}
                />
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('fumigationType')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.fumigationType}
                    value={currentDGDetails.fumigationType}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('amountOfFumigation')}
              >
                <Grid item xs={8} sm={8}>
                  <LabelValueVertical
                    label={translatedTextsObject.amountOfFumigation}
                    value={currentDGDetails.amountOfFumigation}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('dateOfFumigation')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.dateOfFumigation}
                    value={dateOfFumigationDisplay}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('localCategory')}
              >
                <Grid item xs={4} sm={4}>
                  <Box className='view-card-content'>
                    <Grid container justify='space-between'>
                      <Box className='view-card-content-label'>
                        {translatedTextsObject.localCategory}
                      </Box>

                      <Box className='view-card-content-value'>
                        <CodeMasterViewField
                          codeType='CAL_DG_MPA_CLASS'
                          code={currentDGDetails.localCategory}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={getHighlight('generalComments')}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.generalComments}
                    value={currentDGDetails.generalComments}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
        </ComponentWrapper>
      </Box>

      <Box pt={5}>
        <ComponentWrapper
          header={translatedTextsObject.physicalChemicalProps}
          headerStyle='view-card-header-no-bg'
        >
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('flashpoint'),
                  ...getHighlight('flashpointUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.flashpoint}
                    value={
                      isStringEmpty(currentDGDetails.flashPoint)
                        ? '-'
                        : currentDGDetails.flashPoint +
                          ' ' +
                          getDGUOMDesc(currentDGDetails.flashPointUom)
                    }
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('boilingPoint'),
                  ...getHighlight('boilingPointUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.boilingPoint}
                    //value={isStringEmpty(currentDGDetails.boilingPoint) ? "-" : currentDGDetails.boilingPoint + " " + getDGUOMDesc(currentDGDetails.boilingPointUom)}
                    value={parseNumberAndUom(
                      currentDGDetails.boilingPoint,
                      currentDGDetails.boilingPointUom
                    )}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('sadt'),
                  ...getHighlight('sadtUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.sadt}
                    //value={isStringEmpty(currentDGDetails.sadt) ? "-" : currentDGDetails.sadt + " " + getDGUOMDesc(currentDGDetails.sadtUom)}
                    value={parseNumberAndUom(
                      currentDGDetails.sadt,
                      currentDGDetails.sadtUom
                    )}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
            <Grid item xs={4} sm={4}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('controlTemperature'),
                  ...getHighlight('controlTemperatureUom')
                }}
              >
                <Grid item xs={6} sm={6}>
                  <LabelValueVertical
                    label={translatedTextsObject.controlTemp}
                    //value={isStringEmpty(currentDGDetails.controlTemp) ? "-" : currentDGDetails.controlTemperature + " " + getDGUOMDesc(currentDGDetails.controlTemperatureUom)}
                    value={parseNumberAndUom(
                      currentDGDetails.controlTemperature,
                      currentDGDetails.controlTemperatureUom
                    )}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            className='view-card-item-content'
          >
            <Grid item xs sm></Grid>
            <Grid item xs={8} sm={8}>
              <ComponentWrapper
                noBorder
                cardStyle={{
                  ...getHighlight('emergencyTemperature'),
                  ...getHighlight('emergencyTemperatureUom')
                }}
              >
                <Grid item xs={12} sm={12}>
                  <LabelValueVertical
                    label={translatedTextsObject.emergencyTemp}
                    value={parseNumberAndUom(
                      currentDGDetails.emergencyTemperature,
                      currentDGDetails.emergencyTemperatureUom
                    )}
                  />
                </Grid>
              </ComponentWrapper>
            </Grid>
          </Grid>
        </ComponentWrapper>
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <Box mr={1}>
          <CngPrimaryButton onClick={closeDialog}>
            {calistaUITranslatedTextObject.close}
          </CngPrimaryButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ViewDGCargoDetailsForm
