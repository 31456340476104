import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
} = components


function AmendBookingButtonComponent(props) {

    const translatedTextsObject = CalistaUiComponentTranslationText()

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify="flex-start">

                </Grid>
                <Grid container xs={6} sm={6} justify="flex-end" >
                    <Box pr={2}>
                        <CngSecondaryButton
                            onClick={() => { props.cancel() }
                            }
                        >
                            {translatedTextsObject.cancel}
                        </CngSecondaryButton>

                    </Box>
                    <Box>
                        <CngPrimaryButton
                            onClick={() => { props.submitAmendments() }
                            }
                        >
                            {translatedTextsObject.viewAmendments}
                        </CngPrimaryButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AmendBookingButtonComponent