import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import DGPkgUOMAutocompleteField from 'src/components/autocomplete/DGPkgUOMAutocompleteField'

const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField,
            CngPackageUomAutocompleteField
        },
    },
    button: { CngPrimaryButton, CngSecondaryButton },
    CngGridItem,
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const initialValues = Object.freeze({


    outerPackingQty: null,
    outerPackingQtyUom: "",
    innerPackingQty: null,
    innerPackingQtyUom: "",
    limitedQty: null,
    limitedQtyUom: "",
    exceptedQty: null,
    exceptedQtyUom: "",
    netWeight: null,
    grossWeight: null,
    vgmWeight: null,
    quantity: null,
    packingGroup: "",
    ibcPackageCode: "",
    packingDetails: "",

})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [packWeightDetailsExpanded, setPackWeightDetailsExpanded] = useState(false)

    const [, ,] = useField('outerPackingQty')
    const [, ,] = useField('outerPackingQtyUom')
    const [, ,] = useField('innerPackingQty')
    const [, ,] = useField('innerPackingQtyUom')
    const [, ,] = useField('limitedQty')
    const [, ,] = useField('limitedQtyUom')
    const [, ,] = useField('exceptedQty')
    const [, ,] = useField('exceptedQtyUom')
    const [, ,] = useField('netWeight')
    const [, ,] = useField('grossWeight')
    const [, ,] = useField('vgmWeight')
    const [, ,] = useField('quantity')
    const [, ,] = useField('packingGroup')
    const [, ,] = useField('ibcPackageCode')
    const [, ,] = useField('packingDetails')

    const [key, setKey] = useState()

    const handlePackWeightDetailsExpand = () => {
        setPackWeightDetailsExpanded(!packWeightDetailsExpanded)
    }

    // const repNameRef = useRef(uuid())

    useEffect(() => {
        setKey(uuid())

    }, [props.isEdit])

    return (
        <Card>
            <Accordion expanded={packWeightDetailsExpanded}>
                <AccordionSummary aria-controls="contactDetailsComponentContent" id='contactDetailsComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.packagingWeight} />

                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <CngTextField
                                    type='number'
                                    name='outerPackingQty'
                                    inputProps={{ maxLength: 10 }}
                                    label={translatedTextsObject.outerPkgQTY}
                                    required
                                    customType={PLAIN_NUMBER}
                                />
                            </CngGridItem>

                            <CngGridItem xs={2} sm={2}>
                                <DGPkgUOMAutocompleteField
                                    name='outerPackingQtyUom'
                                    label={translatedTextsObject.uom}
                                    required
                                    key={key}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <CngTextField
                                    type='number'
                                    name='innerPackingQty'
                                    inputProps={{ maxLength: 10 }}
                                    label={translatedTextsObject.innerPkgQTY}
                                    required
                                    customType={PLAIN_NUMBER}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <DGPkgUOMAutocompleteField
                                    name='innerPackingQtyUom'
                                    label={translatedTextsObject.uom}
                                    required
                                    key={key}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={2} sm={2}>
                            <CngTextField
                                type='number'
                                name='limitedQty'
                                inputProps={{ maxLength: 10 }}
                                label={translatedTextsObject.limitedQTY}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={2} sm={2}>
                            <CngPackageUomAutocompleteField
                                name='limitedQtyUom'
                                label={translatedTextsObject.uom}
                                key={key}
                            />
                        </CngGridItem>
                        <CngGridItem xs={2} sm={2}>
                            <CngTextField
                                type='number'
                                name='exceptedQty'
                                inputProps={{ maxLength: 10 }}
                                label={translatedTextsObject.expectedQTY}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={2} sm={2}>
                            <CngPackageUomAutocompleteField
                                name='exceptedQtyUom'
                                label={translatedTextsObject.uom}
                                key={key}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='netWeight'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.netWeight}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='grossWeight'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.dgGrossWeight}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='netVolume'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.netVolume}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='grossVolume'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.grossVolume}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}></CngGridItem>

                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='vgmWeight'
                                inputProps={{ maxLength: 11 }}
                                label={translatedTextsObject.vgmWeight}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='number'
                                name='quantity'
                                inputProps={{ maxLength: 5 }}
                                label={translatedTextsObject.quantity}
                                customType={PLAIN_NUMBER}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            {/* <CngTextField
                                type='text'
                                inputProps={{ maxLength: 3 }}
                                name='packingGroup'
                                label={translatedTextsObject.packingGroup}

                            /> */}

                            <CngCodeMasterAutocompleteField
                                name='packingGroup'
                                label={translatedTextsObject.packingGroup}
                                codeType='CAL_DG_PACKING'
                                key={key}
                            />
                        </CngGridItem>


                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type='text'
                                name='ibcPackageCode'
                                inputProps={{ maxLength: 100 }}
                                label={translatedTextsObject.ibcPackageCode} />
                        </CngGridItem>

                        <CngGridItem xs={4} sm={4}></CngGridItem>
                        <CngGridItem xs={8} sm={8}>
                            <CngTextField
                                type='text'
                                name='packingDetails'
                                inputProps={{ maxLength: 1024 }}
                                multiline
                                rows={3}
                                label={translatedTextsObject.packagingDetails} />
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handlePackWeightDetailsExpand}
                expanded={packWeightDetailsExpanded}
                footerText={translatedTextsObject.additionalPackWeightDetails}
            />
        </Card>
    )
}

const DGCargoDetailsPackageWeightComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default DGCargoDetailsPackageWeightComponent