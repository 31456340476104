import * as CalistaConstant from 'src/constants/CalistaConstant'

import { Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ArchiveButton from 'src/components/button/ArchiveButton.js'
import AmendButton from 'src/components/button/AmendButton'
import ApproveButton from 'src/components/button/ApproveButton'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import DeclineButton from 'src/components/button/DeclineButton'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'
import Utils from '../shared/Utils'

// import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
// import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'


const {
    button: { CngSecondaryButton }
} = components


function ManageBookingButtonComponent(props) {
    const { booking, actions, eventHandlers, isCarrier } = props
    //console.log('ManageBookingButtonComponent showButton' + JSON.stringify(showButton))
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    const history = useHistory()
    console.log(props)
    const { showNotification } = props
    console.log(showNotification)


    const gotoManageBooking = () => {
        history.push({
            pathname: pathMap.MANAGE_BOOKING
        })
    }

    const amendBooking = () => {
        history.push({
            pathname: pathMap.MANAGE_BOOKING_AMEND,
            state: { booking: booking }
        })
    }

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify="flex-start">
                    <CngSecondaryButton onClick={gotoManageBooking}>{uiTranslatedTextsObject.backToManageBooking}</CngSecondaryButton>
                </Grid>
                <Grid container xs={6} sm={6} justify="flex-end" >

                    {actions.map((action, idx) => {
                        switch (action) {


                            case Utils.Action.reject:
                                return (

                                    <Box pr={2}>
                                        <DeclineButton
                                            onClick={eventHandlers.rejectBooking} />
                                    </Box>
                                )

                            case Utils.Action.amend:
                                return (
                                    <Box pr={2}>
                                        <AmendButton onClick={amendBooking} />
                                    </Box>
                                )

                            case Utils.Action.approve:
                                return (
                                    <Box pr={2}>
                                        <ApproveButton onClick={eventHandlers.approveBooking} />
                                    </Box>
                                )

                            case Utils.Action.archive:

                                return ((isCarrier ? !booking.carrierClosed : !booking.closed) ? < Box pr={2} >
                                    <ArchiveButton onClick={eventHandlers.archiveBooking} />
                                </Box> :
                                    <Box pr={2}></Box>)

                            default:
                                return ''
                        }

                    })}
                    {/* <Box pr={2} style={{ display: showButton.showReject }}>
                        <DeclineButton
                            onClick={() => {
                                props.onClickDeclineBooking()
                            }
                            }
                        />
                    </Box>

                    <Box pr={2} style={{ display: showButton.showReject }}>
                        <DeclineButton
                            onClick={() => {
                                props.onClickDeclineBooking()
                            }
                            }
                        />
                    </Box>

                    <Box pr={2} style={{ display: showButton.showAmend }}>
                        <AmendButton
                            onClick={amendBooking}
                        />
                    </Box>

                    <Box style={{ display: showButton.showApprove }}>
                        <ApproveButton
                            onClick={() => {
                                props.onClickApproveBooking()
                            }
                            }
                        />
                    </Box> */}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ManageBookingButtonComponent