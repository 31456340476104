import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BookingActionMenuItem from './BookingActionMenuItem'
import Utils from '../shared/Utils'
import pathMap from 'src/paths/PathMap_FreightBooking'
import pathMapSI from 'src/paths/PathMap_ShippingInstruction'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import ActionMenuBookingList from './ActionMenuBookingList'
import ActionMenuBookingDetail from './ActionMenuBookingDetail'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import CommonUtils from 'src/views/common/utils/Utils'
const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification }
} = components

function BookingActionMenu(props) {
  const history = useHistory()

  const { booking, bookingStatus, page, refreshAction, isCreateSI } = props
  const bookStatus =
    bookingStatus || booking.bookingStatus || booking.bookStatus || ''

  let actions =
    bookStatus != ''
      ? isCreateSI
        ? ['createSI']
        : Utils.getBookingStatusById(bookStatus).actions
      : []

  let actionsH =
    bookStatus != '' ? Utils.getBookingStatusById(bookStatus).actionsH : []

  const translatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState('')
  const CANCEL_ACTION = 'cancel'
  const ARCHIVE_ACTION = 'archive'
  const DELETE_ACTION = 'delete'
  const containers =
    booking.containerISOCounts || booking.bookingContainers || []
  console.log('booking', booking)

  const { showNotification } = props

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const showPrimary = false

  const gotoMyBooking = () => {
    history.push({
      pathname: '/cal-freightbooking/my-booking'
    })
  }

  const gotoViewBKOnly = () => {
    history.push({
      pathname: pathMap.VIEW_ONLY_BOOKING
    })
  }

  const deleteBooking = () => {
    console.log('delete NEW')
    setConfirmDialogOpen(true)
    setConfirmAction(DELETE_ACTION)
  }

  const proceedDeleteBooking = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${translatedTextsObject.deleteSuccess} `)
        redirectToMyBooking()
      }
    }

    createRecord.execute(
      FreightBookingApiUrls.DELETE_BOOKING,
      { bookingId: props.booking.bookingId },
      onSuccess,
      onError
    )
  }

  const viewSI = () => {
    console.log('viewSI clicked')
    history.push({
      pathname: pathMapSI.MY_SI,
      state: { fromMyBooking: true, booking: props.booking }
    })
  }

  const createSI = (prevPage) => (event) => {
    var totalCount = 0
    if (!Utils.isEmpty(containers)) {
      containers.forEach((container) => {
        totalCount =
          totalCount +
          parseInt(
            page === Utils.Page.BookingList
              ? container.count
              : container.containerQty
          )
      })

      if (totalCount < SIUtils.maxContainerNo) {
        history.push({
          pathname: pathMapSI.CREATE_SI_NEW_SI,
          state: { prevPage: prevPage, booking: props.booking }
        })
      } else {
        showErrorNotification(
          `${siTranslatedTextsObject.cannotCreateSIMsg}${SIUtils.maxContainerNo}`
        )
      }
    }
  }

  const trackContainers = () => {
    console.log('trackContainers clicked')
  }

  const amendBooking = (prevPage) => (event) => {
    history.push({
      pathname: pathMap.MY_BOOKING_AMEND,
      state: {
        booking: props.booking,
        prevPage: prevPage,
        action: Utils.Action.amend
      }
    })
  }

  const cancelBooking = () => {
    console.log('new cancel')
    setConfirmDialogOpen(true)
    setConfirmAction(CANCEL_ACTION)
  }

  const confirmDialog = () => {
    console.log('confirmAction--> ' + confirmAction)
    setConfirmDialogOpen(false) //close the dialog first
    switch (confirmAction) {
      case CANCEL_ACTION:
        proceedCancelBooking()
        break

      case ARCHIVE_ACTION:
        proceedArchiveBooking()
        break

      case DELETE_ACTION:
        proceedDeleteBooking()
        break
    }
  }

  function redirectToMyBooking() {
    if (page === Utils.Page.BookingDetail) {
      gotoMyBooking()
    } else {
      //if action is done from menu list, then just refresh the list
      refreshAction()
    }
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  
  }

  const proceedCancelBooking = () => {
    console.log('cancel')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${translatedTextsObject.cancelSuccess} `)
        redirectToMyBooking()
      }
    }

    createRecord.execute(
      FreightBookingApiUrls.CANCEL_BOOKING,
      { bookingId: props.booking.bookingId },
      onSuccess,
      onError
    )
  }

  const archiveBooking = () => {
    console.log('archive NEW')
    setConfirmDialogOpen(true)
    setConfirmAction('archive')
  }

  const proceedArchiveBooking = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${translatedTextsObject.archivedSuccess} `)
        redirectToMyBooking()
      }
    }

    createRecord.execute(
      FreightBookingApiUrls.ARCHIVE_BOOKING,
      { bookingId: props.booking.bookingId },
      onSuccess,
      onError
    )
  }

  const editBooking = (prevPage) => (event) => {
    history.push({
      pathname: pathMap.CREATE_NEW_BOOKING,
      state: {
        booking: props.booking,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const copyBooking = () => {
    setConfirmDialogOpen(false) //close the dialog first

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${translatedTextsObject.copySuccess} ${response.freightBookingId}`
        )
        history.push({
          pathname: pathMap.CREATE_NEW_BOOKING,
          state: { booking: response, action: Utils.Action.copy }
        })
      }
    }

    createRecord.execute(
      FreightBookingApiUrls.COPY_BOOKING,
      { bookingId: props.booking.bookingId },
      onSuccess,
      onError
    )
  }

  const eventHandlers = {
    gotoMyBooking,
    gotoViewBKOnly,
    copyBooking,
    archiveBooking,
    editBooking,
    amendBooking,
    deleteBooking,
    cancelBooking,
    viewSI,
    createSI,
    trackContainers
  }

  return (
    <div>
      {page === Utils.Page.BookingDetail && (
        <ActionMenuBookingDetail
          actions={actionsH}
          booking={booking}
          eventHandlers={eventHandlers}
          menuCodeList={props.menuCodeList}
          isViewBk={props.isViewBk}
        />
      )}
      {page === Utils.Page.BookingList && (
        <ActionMenuBookingList
          actions={actions}
          booking={booking}
          page={page}
          eventHandlers={eventHandlers}
          menuCodeList={props.menuCodeList}
          isCreateSI={isCreateSI}
        />
      )}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={translatedTextsObject.dialogChangeStatusGeneralContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={translatedTextsObject.dialogCancelBookingTitle}
      />
    </div>
  )
}

export default BookingActionMenu
