import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid
} from '@material-ui/core'
import React, { useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components


const initialValues = Object.freeze({
    bookingStatus: 5102,
    carrierRefNo: "",
    carrierRemarks: "",
    bookStatusDesc: "",
    shipperRemarks: ""
})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    const [paymentExpanded, setPaymentExpanded] = useState(false)

    const [, ,] = useField('carrierRefNo')
    const [, ,] = useField('carrierRemarks')
    const [, ,] = useField('bookingStatus')
    //const [, , { setValue: setBookingStatusDescField }] = useField('bookStatusDesc')

    const bookingStatusOptions = [
        { text: uiTranslatedTextsObject.approve, value: CalistaConstant.BOOKING_STATUS_APPROVED },
        { text: uiTranslatedTextsObject.reject, value: CalistaConstant.BOOKING_STATUS_REJECTED }
    ]


    // const bookingStatusOnChange = (value, option) => {
    //     console.log("bookingStatusOnChange")
    //     console.log(value.target.value)
    //     var selected = bookingStatusOptions.filter((test) => {
    //         return test.value == value.target.value
    //     })

    //     console.log(selected[0].text)
    //     setBookingStatusDescField(selected[0].text)
    // };

    return (
        <Card>

            <Box px={2} my={1.5}>
                <Grid container>
                    <AccordionHeaderComponent title={translatedTextsObject.approval} />
                </Grid>

                <Grid container xs={12} sm={12} spacing={2}>
                    <CngGridItem xs={4} sm={4}></CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                        <CngTextField
                            type='text'
                            name='carrierRefNo'
                            label={translatedTextsObject.bookingRefenceNumber}
                            inputProps={{ maxLength: 35 }}
                            isRequired
                        />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                        {/*  <CngSelectField
                            name='bookingStatus'
                            label={translatedTextsObject.bookingStatus + ' *'}
                            items={bookingStatusOptions}
                            onChange={
                                bookingStatusOnChange}
                        />*/}
                    </CngGridItem>

                    <CngGridItem xs={4} sm={4}></CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                        <CngTextField
                            type='text'
                            name='shipperRemarks'
                            label={translatedTextsObject.shipperRemarks}
                            disabled
                            multiline
                            rows={3}
                        />
                    </CngGridItem>

                    <CngGridItem xs={4} sm={4}>
                        <CngTextField
                            type='text'
                            name='carrierRemarks'
                            label={translatedTextsObject.carrierRemarks}
                            inputProps={{ maxLength: 1024 }}
                            multiline
                            rows={3}
                        // disabled={isSubmitting}
                        />

                    </CngGridItem>
                </Grid>

            </Box>

        </Card>
    )
}

const ApprovalComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ApprovalComponent