import { Box, Card, Grid } from '@material-ui/core'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'
import Hazardous from 'src/components/button/Hazardous.js'

const {
    CngGridItem
} = components

const FormBody = (props) => {
    const { bookingTypeData } = props
    // alert(JSON.stringify(bookingTypeData))
    const translatedTextsObject = FreightBookingTranslationText()

    return (
        // <Grid container xs={12} sm={12}>
        <CngGridItem xs={12} sm={12}>
            <Card>
                <Box className="view-card-header">
                    {translatedTextsObject.bookingType}
                </Box>
                <Box>
                    <Grid container xs={12} sm={12}>
                        <CngGridItem xs={6} sm={6}>
                            {/* {'shipmentType ' + bookingTypeData.shipmentType} */}
                            <Box className="view-card-content">
                                <Box className="view-card-content-label">
                                    {translatedTextsObject.shipmentType}
                                </Box>
                                <Box className="view-card-content-value">
                                    <CodeMasterViewField
                                        codeType='CAL_CONT_STATUS'
                                        code={bookingTypeData.shipmentType}
                                    />
                                </Box>
                            </Box>
                        </CngGridItem>

                        <CngGridItem xs={6} sm={6} >
                            <Grid container xs={12} sm={12} spacing={2} justify="flex-end">
                                <CngGridItem xs={12} sm={12} >
                                    <Grid container xs direction='row' justify='flex-end' alignItems='center'>

                                        <Box>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                                            ></img>
                                        </Box>

                                        <Box className={'boldLabel'} >&nbsp;reference no: {props.bookingTypeData.freightBookingId}</Box>

                                        {
                                            props.bookingTypeData.hazardous && (

                                                <Box ml={1}>
                                                    <Hazardous />
                                                </Box>)
                                        }

                                    </Grid>
                                </CngGridItem>
                            </Grid>
                        </CngGridItem>

                        {/* <CngGridItem xs={12} sm={12}>
                            <Box className="view-card-content">
                                <Box className="view-card-content-label">
                                    {translatedTextsObject.forwardHubScheme}
                                </Box>
                                <Box className="view-card-content-value">
                                    N/A
                                    </Box>
                            </Box>
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}>
                            <Box className="view-card-content">
                                <Box className="view-card-content-label">
                                    {translatedTextsObject.outboundRefNo}
                                </Box>
                                <Box className="view-card-content-value">
                                    N/A
                                    </Box>
                            </Box>
                        </CngGridItem> */}


                    </Grid>
                </Box>
            </Card>
        </CngGridItem>

        // </Grid>
    )
}


const initialValues = Object.freeze({

})

const AmendBookingTypeComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default AmendBookingTypeComponent
