import {
    Avatar,
    Box,
    Card,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core'

import BookingActionMenu from './BookingActionMenu'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CarrierBookingListActionMenu from './CarrierBookingListActionMenu'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined'
import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import Utils from '../shared/Utils'
import {
    components
} from 'cng-web-lib'
import moment from 'moment'

const {
    CngGridItem
} = components

const ManageBookingReviewHeader = (props) => {
    const {booking, bookingStatus} = props
    const bookStatus = bookingStatus || ''
    const translatedTextsObject = CalistaUiComponentTranslationText()
    let bgColor = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).color : ''
    let status = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).name.toUpperCase() : ''

    return (
        <Card className={'titleBox'} style={{ backgroundColor: bgColor}}>
                <Grid container xs={12} alignItems="center" className="review-header">
                    <CngGridItem xs={3} >
                        <Typography className={'boldLabel'} style={{ fontSize: 16 }}>{status}</Typography>
                    </CngGridItem>
                    <Grid xs />
                    <Grid container xs={3} alignItems="center">
                        {/* <Grid sm={11}>
                            <Typography className={'boldLabel'} >Print document</Typography>
                        </Grid> */}
                        <Grid sm={12} container justify="flex-end">
                            <Box pr={2} alignItems="center">
                                <Typography className={'boldLabel'} >{translatedTextsObject.printDocument}</Typography>
                            </Box>
                            <Box pr={1} alignItems="center">
                                <PrintOutlinedIcon />
                            </Box>
                            
                            {/* <CarrierBookingListActionMenu /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Card>  
 
    )
}

export default ManageBookingReviewHeader;