import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components


const initialValues = Object.freeze({
    chrgType: "",
    payer: "",
    frtTerm: "",
    pmtLoc: ""

})

const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [paymentExpanded, setPaymentExpanded] = useState(false)

    const [, ,] = useField('chrgType')
    const [, ,] = useField('payer')
    const [, ,] = useField('frtTerm')
    const [, ,] = useField('pmtLoc')
    const [chargeTypeKey, setChargeTypeKey] = useState()
    const [payerKey, setPayerkey] = useState()
    const [frtTermKey, setFrtTermKey] = useState()

    const handlePaymentExpand = () => {
        setPaymentExpanded(!paymentExpanded);
    };

    useEffect(() => {

        setChargeTypeKey(uuid())
        setPayerkey(uuid())
        setFrtTermKey(uuid())

    }, [props.isEdit])

    return (
        <Card>
            <Accordion expanded={paymentExpanded}>
                <AccordionSummary aria-controls="paymentComponentContent" id='paymentComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.paymentInstruction} hideMandatory />
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='chrgType'
                                label={translatedTextsObject.chargeType}
                                codeType='CAL_CHG_TYPE'
                                key={chargeTypeKey}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='payer'
                                label={translatedTextsObject.payer}
                                codeType='CAL_CHG_PAYER'
                                key={payerKey}
                            />
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngCodeMasterAutocompleteField
                                name='frtTerm'
                                label={translatedTextsObject.freightTerm}
                                codeType='CAL_CHG_FT'
                                key={frtTermKey}
                            />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={12}>
                            <CngTextField
                                type='text'
                                name='pmtLoc'
                                label={translatedTextsObject.pmtLoc}
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 30 }}
                            // disabled={isSubmitting}
                            />

                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handlePaymentExpand}
                expanded={paymentExpanded}
                footerText={translatedTextsObject.addPaymentInstruction}
            />
        </Card>
    )
}

const PaymentInstructionComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default PaymentInstructionComponent