import { Avatar, Box, Card, Chip, Grid, Typography } from '@material-ui/core'

import BookingActionMenu from './BookingActionMenu'
import CarrierBookingActionMenu from './CarrierBookingActionMenu'
import CarrierBookingListActionMenu from './CarrierBookingListActionMenu'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVerticalSimple from './LabelValueVerticalSimple'
import React from 'react'
import RestoreIcon from '@material-ui/icons/Restore'
import Utils from '../shared/Utils'
import { components } from 'cng-web-lib'
import moment from 'moment'

const { CngGridItem } = components

const BookingDetailTop = (props) => {
  const { booking, isCarrier, helpdesk, isViewBk } = props
  const { bookStatus, bookStatusDesc, carRefNo, shpRefNo } = booking
  const translatedTextsObject = FreightBookingTranslationText()
  console.log('Booking status: ' + bookStatus)

  //style={{ backgroundColor: Utils.getBookingStatusById(bookStatus).color }}
  console.log(Utils.getBookingStatusById(bookStatus).color)
  let temp = Utils.getBookingStatusById(bookStatus).color
  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={`font-mediumBold font-16`}>
                  {bookStatusDesc.toUpperCase()}
                  {(isCarrier ? booking.carrierClosed : booking.closed)
                    ? '/' + translatedTextsObject.archived
                    : ''}
                </Typography>
              }
              style={{ backgroundColor: temp }}
            />
          </div>
          </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1`}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.bookingRefNo + ':'}
                value={carRefNo}
                defaultValue='N/A'
                labelStyle={'field-label'}
                valueStyle={'field-value font-mediumBold'}
              />
            </div>

            <div className={`header-box--right-col-2 cta-box`}>
              {helpdesk || isViewBk ? (
                <Typography className={'boldLabel'}></Typography>
              ) : (
                <div className={`cng-summary-card--more-actions-wrapper`}>
                  {isCarrier ? (
                    <CarrierBookingActionMenu
                      booking={booking}
                      {...props}
                      page={Utils.Page.BookingList}
                    />
                  ) : (
                    <BookingActionMenu
                      booking={booking}
                      {...props}
                      page={Utils.Page.BookingList}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
            </Grid>
          </Grid>
    </header>
  )
}

export default BookingDetailTop
