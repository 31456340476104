import { Box, Card, Chip, Grid } from '@material-ui/core'

import React from 'react'

const ComponentWrapper = (props) => {
  const {
    header,
    headerChange,
    children,
    headerStyle,
    cardStyle,
    noBorder,
    rightConner,
    rightConnerHeader,
    backgroundStyle
  } = props

  let bgStyle = backgroundStyle || ''
  let style = headerStyle || 'view-card-header'
  let cardBorder = noBorder ? { border: 'none', boxShadow: 'none' } : {}
  let cardStyle2 = cardStyle ? { ...cardStyle, ...cardBorder } : cardBorder
  return (
    <Card style={cardStyle2}>
      <Grid container className={bgStyle}>
        <Grid container justify='space-between'>
          <Grid item xs={6}>
            {header && (
              <Box className={style}>
                <div> {header}</div>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            {headerChange && (
              <Box className={style}>
                <div> {headerChange}</div>
              </Box>
            )}
          </Grid>
          {rightConnerHeader && (
            <Grid item xs={3} className={style + ' alignRight'}>
              {React.cloneElement(rightConnerHeader, {})}
            </Grid>
          )}
        </Grid>

        <Grid container justify='space-between'>
          <Grid item xs>
            <div style={{ height: '100%' }}>{children}</div>
          </Grid>
          {rightConner && (
            // <Grid item xs={4} style={{margin:5}} className={'alignRight'} >
            <Box className='rightConnerBox'>
              {React.cloneElement(rightConner, {})}
            </Box>
            //  </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ComponentWrapper
