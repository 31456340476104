import { Box, Grid, Paper, Typography, Card } from '@material-ui/core'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import { constants, useServices } from 'cng-web-lib'
import React, { useRef, useState, useEffect } from 'react'
import { components } from 'cng-web-lib'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailSchedule from './AuditTrailSchedule'
import AuditTrailContainer from './AuditTrailContainer'
import AuditTrailContact from './AuditTrailContact'
import AuditTrailShipment from './AuditTrailShipment'
import AuditTrailPayment from './AuditTrailPayment'
import AuditTrailCargo from './AuditTrailCargo'
import AuditTrailDgCargo from './AuditTrailDgCargo'
import Utils from '../shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import AuditTrailDocument from './AuditTrailDocument'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import CarrierApi from 'src/views/common/api/CarrierApi'
const {
  CodeMaintenanceType
} = constants
const {
  CngDialog,
  CngGridItem,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const FIELDS_SHIPMENT = ['moveType', 'placeOfReceiptCode', 'placeOfDeliveryCode', 'cargoReadyDate', 'cargoDeliveryDate', 'placeOfReceipt'
  , 'placeOfDelivery', 'placeOfReceiptEtd', 'placeOfDeliveryEta', 'destCode', 'finalDest',
  'emptyPickupCompName', 'emptyPickupCompAddress', 'emptyPickupContactName', 'emptyPickupContactPhoneNo', 'emptyPickupDatePickup',
  'shipFromCompName', 'shipFromCompAddress', 'shipFromContactName', 'shipFromContactPhoneNo', 'emptyPickupDatePickup',
  'shipToCompName', 'shipToCompAddress', 'shipToContactName', 'shipToContactPhoneNo', 'shipToDateDelivery']

const FIELDS_CONTACT = ['repName', 'repEmail', 'repContactNo', 'contractNo', 'commonRef', 'shpRefNo', 'fwdRefNo', 'preAssBkRefNo',
  'contractPartyNo', 'mainNotifyParty', 'shpName', 'consigneeName', 'forwarderName', 'preshipContact']

const FIELDS_SCHEDULE = ['polEta', 'carrierPartyId', 'vesselName', 'voyageNo', 'intVoyageNo', 'portOfLoadingCode', 'portOfDischargeCode',
  'podEta', 'polEtd', 'portOfLoadingTerminal', 'portOfDischargeTerminal']

const FIELDS_PAYMENT = ['chrgType', 'frtTerm', 'payer', 'pmtLoc']

const FIELDS_REEFER = ['nonActiveReefer', 'equipmentTemp', 'tempUom', 'superFreezerService', 'gensetRequired', 'inTransitColdSterilization',
  'numberOfTempProbes', 'numberOfUSDProbes', 'tempVariance', 'ventSetting', 'equipmentAirFlow',
  'equipmentControlledAtmosphereCO2', 'equipmentControlledAtmosphereN2', 'equipmentControlledAtmosphereO2', 'equipmentHumidity', 'equipmentComments']

function ViewAuditDetailDialog({
  isDialogOpen,
  closeDialog,
  auditCompare,
  currentBooking,
  currentBookStatus,
  ports,
  newDate,
  prevDate
}) {
  const tranObj = FreightBookingTranslationText()
  const tranObjCommon = CalistaUiComponentTranslationText()
  const { fetchRecords } = useServices()
  const [codeMasterList, setCodeMasterList] = useState([])
  const [docTypes, setDocTypes] = useState([])
  const [packageUom, setPackageUom] = useState([])
  const [containerTypes, setContainerTypes] = useState([])
  const [carriers, setCarriers] = useState([])

  const matchBookStatus = {
    field: 'id',
    displayField: 'name',
    items: Utils.BookingStatus
  }

  const booking = auditCompare || {}

  const dgCargos = booking.dangerousGoods || []
  const cargos = booking.cargos || []

  useEffect(() => {
    CodeMasterApi.fetchMultiCodeMaster(fetchRecords,
      [CalistaConstant.CAL_DG_IMO, CalistaConstant.CAL_DG_TEMP_UOM, CalistaConstant.CAL_DG_MARINE,
      CalistaConstant.CAL_DG_PSN_INH_HZD_ZN, CalistaConstant.CAL_DG_AGGRE, CalistaConstant.CAL_DG_MPA_CLASS,
      CalistaConstant.CAL_DG_PKG_TYPE, CalistaConstant.CAL_CONT_STATUS, CalistaConstant.CAL_BK_MOVE_TYPE],
      onLoadCodeMasterListSuccess)

    SharedServiceApi.fetchContainerType(fetchRecords, false, onContainerTypeLoadSuccess)

    CarrierApi.fetchCarrier(fetchRecords, onLoadCarrierSuccess)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.DOCUMENT,
      [], false, onLoadDocTypesSuccess)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PACKAGE_UOM,
      [], false, onLoadPackageUomSuccess)
  }, [])


  function onLoadPackageUomSuccess(data) {
    setPackageUom(data)
  }
  function onLoadCodeMasterListSuccess(data) {
    setCodeMasterList(data)
  }


  function onLoadDocTypesSuccess(data) {
    setDocTypes(data)
  }

  function onContainerTypeLoadSuccess(data) {
    setContainerTypes(data)
  }
  function onLoadCarrierSuccess(data) {
    setCarriers(data)
  }


  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box m={2}>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid container xs={6} sm={6} direction="row" justify="flex-start" spacing={2}>
                  <CngGridItem>
                    <Typography variant="h5" className="font-bolder">
                      {tranObjCommon.prevValues}
                    </Typography>
                  </CngGridItem>
                  <CngGridItem>
                    <Typography className='greyLabel2 font-bolder'>
                      {prevDate}
                    </Typography>
                  </CngGridItem>
                </Grid>

                <Grid container xs={6} sm={6} className='paddingLeft16' direction="row" justify="flex-start" spacing={2}>
                  <CngGridItem>
                    <Typography variant="h5" className="font-bolder">
                      {tranObjCommon.newValues}
                    </Typography>
                  </CngGridItem>
                  <CngGridItem>
                    <Box className='greyLabel2 font-bolder'>
                      {newDate}
                    </Box>
                  </CngGridItem>
                </Grid>
              </Grid>
            </Box>
            {
              CommonUtils.hasFieldsChanged(booking, ['bookStatus']) && (
                <Box>
                  <AuditTrailDualColumn object={booking} field={'bookStatus'} match={matchBookStatus} label={tranObj.status} />
                </Box>
              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, ['shipmentType']) && (
                <Box m={2}>
                  <Typography variant="h5" className="font-bolder">
                    {tranObj.bookingType}
                  </Typography>
                  <AuditTrailDualColumn codeType={CalistaConstant.CAL_CONT_STATUS} codeMasterList={codeMasterList} object={booking} field={'shipmentType'} label={tranObj.shipmentType} />
                </Box>
              )
            }
            {
              (CommonUtils.hasFieldsChanged(booking, ['carRefNo']) || CommonUtils.hasFieldsChanged(booking, ['carRemarks'])) && (
                <Card>
                  <Typography variant="h5" className="font-bolder paddingTop16 paddingLeft16">
                    {tranObj.carrierComments}
                  </Typography>
                  <AuditTrailDualColumn object={booking} field={'carRefNo'} label={tranObj.bookingRefNo} />
                  <AuditTrailDualColumn object={booking} field={'carRemarks'} label={tranObj.carrierComments} />

                </Card>
              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, ['shpRemarks']) && (
                <Card>
                  <Typography variant="h5" className="font-bolder paddingTop16 paddingLeft16">
                    {tranObj.shipperComments}
                  </Typography>
                  <AuditTrailDualColumn object={booking} field={'shpRemarks'} label={tranObj.shipperComments} />
                </Card>
              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, FIELDS_SCHEDULE) && (
                <Box mt={3}>
                  <AuditTrailSchedule booking={booking} currentBooking={currentBooking} ports={ports} carriers={carriers} />
                </Box>

              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, FIELDS_SHIPMENT) && (
                <Box mt={3}>
                  <AuditTrailShipment booking={booking} ports={ports} codeMasterList={codeMasterList} />

                </Box>
              )
            }
            {
              (CommonUtils.arrayHasChanges(booking.containers, ['bookingId', 'reefer'])
                ||
                (booking != undefined && booking.containers != undefined && CommonUtils.reeferArrayHasChanges(booking.containers, FIELDS_REEFER))
              ) &&
              <Box mt={3}>
                <AuditTrailContainer containers={booking.containers}
                  codeMasterList={codeMasterList}
                  containerTypes={containerTypes} />

              </Box>
            }
            {
              CommonUtils.arrayHasChanges(booking.cargos, ['bookingId']) &&
              <Box mt={3}>
                <AuditTrailCargo cargos={booking.cargos} />

              </Box>
            }
            {
              CommonUtils.arrayHasChanges(booking.dangerousGoodsCargo, ['bookingId', 'version', 'updatedDate']) && (
                <Box mt={3}>
                  <AuditTrailDgCargo cargos={booking.dangerousGoodsCargo}
                    codeMasterList={codeMasterList}
                    packageUom={packageUom} />

                </Box>
              )
            }
            {
              CommonUtils.arrayHasChanges(booking.documents, ['bookingId']) && (
                <Box mt={3}>
                  <AuditTrailDocument documents={booking.documents} docTypes={docTypes} />

                </Box>
              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, FIELDS_CONTACT) && (
                <Box mt={3}>
                  <AuditTrailContact booking={booking} />
                </Box>
              )
            }
            {
              CommonUtils.hasFieldsChanged(booking, FIELDS_PAYMENT) && (
                <Box mt={3}>
                  <AuditTrailPayment payment={booking} />
                </Box>
              )}

            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Box>
                <CngPrimaryButton onClick={closeDialog}>
                  OK
                </CngPrimaryButton>
              </Box>
              <Box flexGrow={1}></Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <Typography variant="h5" className="font-bolder">{currentBookStatus ? Utils.getBookingStatusById(currentBookStatus).name : ''}</Typography>
          <CloseDialogIconButton onClick={closeDialog} />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='md'
    />
  )
}

export default ViewAuditDetailDialog
